import { IconButton, styled } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';

const CircleButton = styled(IconButton)({
  backgroundColor: BrandColors.LightGray,
  color: BrandColors.Gray,
  borderRadius: 100,
  '&:hover': {
    color: BrandColors.Gray,
    backgroundColor: BrandColors.White,
  },
});

export default CircleButton;
