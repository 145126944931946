import { Alert, Box, Tooltip } from '@mui/material';
import React from 'react';
import {
  useLocation,
  Location,
  useNavigate,
  useParams,
} from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { BrandColors } from 'Common/common-ui/common/theme';
import CircleButton from 'Common/components/CircleButton';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import LoginIcon from '@mui/icons-material/Login';
import TealLink from 'Common/components/TealLink';

export default function IndividualCourse(props: {
  scrollableSectionHeight: { [key: string]: string };
}) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location: Location = useLocation();
  const signIn = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    const options: RedirectLoginOptions = {
      appState: { returnTo: location.pathname },
    };
    await loginWithRedirect(options);
  };
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: props.scrollableSectionHeight,
      }}
    >
      <Tooltip title="Back to course catalog">
        <Box
          sx={{
            position: isAuthenticated ? 'fixed' : 'relative',
            width: '100%',

            top: 70,
            pl: 3,
          }}
        >
          <CircleButton onClick={() => navigate('/training')}>
            <ArrowBackOutlinedIcon />
          </CircleButton>
        </Box>
      </Tooltip>
      {isAuthenticated ? (
        <iframe
          title="sample-course-frame"
          src={`/toolkit/training/${id}/index.html`}
          width="100%"
          style={{ height: '100% !important' }}
          frameBorder="0"
        ></iframe>
      ) : (
        <Box my={3}>
          <Alert
            sx={{
              backgroundColor: BrandColors.LightGray,
              color: BrandColors.Primary,
              padding: 3,
            }}
            icon={<LoginIcon sx={{ color: BrandColors.Primary }} />}
          >
            Please{' '}
            <TealLink
              sx={{ fontWeight: 'bolder', cursor: 'pointer' }}
              onClick={signIn}
            >
              sign in
            </TealLink>{' '}
            to view the sample course.
          </Alert>
        </Box>
      )}
    </Box>
  );
}
