import axios from 'axios';
import { getAnonymousId } from 'Common/analytics';
import { robotsTag } from '../common/util';

export const axiosInstance = axios.create({
  headers: {
    'X-Robots-Tag': robotsTag.type,
    'Anonymous-Id': `${getAnonymousId()}`,
  },
  withCredentials: false,
});
export const updateAxiosHeader = (config: any, token: string) => {
  if (
    config.url.startsWith('/toolkit/docs') ||
    config.url.startsWith('/toolkit/tools') ||
    config.url.startsWith('/toolkit/updates')
  ) {
    config.headers['Content-Type'] = 'text/html';
  } else {
    config.headers['Accept'] = 'application/json';
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};
