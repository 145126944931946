import React from 'react';
import { IError } from 'Common/interfaces';

interface Props {
  error: IError | null;
  data: any;
}

const Error = ({ data, error }: Props) => {
  return (
    <div style={{ overflow: `scroll` }}>
      {!data && 'No data'}
      <br />
      {!!error && error.message}
      <br />
      <pre>{!!error && JSON.stringify(error.response, null, 4)}</pre>
    </div>
  );
};

export default Error;
