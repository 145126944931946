import * as Paths from '@toolkit/router/paths';
import { BrandColors } from 'Common/common-ui/common/theme';
import { READABLENAMES } from './constant';
import { ExceptionCategory as EC, FatalType } from './enums';
import { Fatal, HighlightContent, MessageLevel, Response } from './interfaces';
import * as sqlmorphPaths from './paths';

export function errorHandler(res: {
  error: any;
  message: string;
  status: number;
}) {
  const result = { content: '-- ', errorCount: 0, warningCount: 0 };
  if (res.error && res.message) {
    if (res.status !== 422) {
      result.content += `${res.error} :`;
    }
    result.content += res.message;
    result.errorCount += 1;
  }
  return result;
}

export function generateContent(res: Response[]) {
  const result = {
    content: '',
  };
  res.forEach((data: Response) => {
    result.content += data.targetStmt;
    data.fatals.forEach(
      ({ fatalType, lineNumber, characterNumber, message }: Fatal) => {
        result.content += '-- EXCEPTION: ';
        switch (fatalType) {
          case FatalType.PARSE:
            result.content += `Statement cannot be parsed: line ${lineNumber} character ${characterNumber}, ${message}`;
            break;
          case FatalType.RENDER:
            result.content += `Failed to render statement: ${message}`;
            break;
          case FatalType.VISIT:
            result.content += `Failed to visit statement: ${message}`;
            break;
          case FatalType.REWRITE:
            result.content += `Failed to rewrite statement: ${message}`;
            break;
          case FatalType.RUNTIME:
            result.content += `${message}`;
            break;
        }
        result.content += '\n';
      }
    );
    result.content += '\n';
  });
  result.content = result.content.trim();
  return result;
}

export function segregateTextColor() {
  const elmts = document.getElementsByClassName(
    'ace_comment'
  ) as any as HTMLElement[];

  for (const elmt of Array.from(elmts)) {
    if (elmt.innerText && elmt.innerText.search(/error/i) > -1) {
      const str = elmt.innerText;
      const subStr = str
        .substring(str.indexOf('--') + 2, str.indexOf(':'))
        .trim();
      const regExSubStr = new RegExp(subStr, 'i');
      elmt.innerHTML = elmt.innerText.replace(
        regExSubStr,
        `<i style='color: #ff0000'>${subStr}</i>`
      );
    }
    if (elmt.innerText && elmt.innerText.search(/warning/i) > -1) {
      elmt.innerHTML = elmt.innerText.replace(
        /warning/gi,
        "<i style='color: #ffaa1d'>WARNING</i>"
      );
    }
  }
}

export function sortArrObj(property: string) {
  return (a, b) => {
    if (a[property] > b[property]) {
      return 1;
    }
    if (a[property] < b[property]) {
      return -1;
    }
    return 0;
  };
}

export function getInternalErrorLineNumber(
  responses: Response[]
): HighlightContent[] {
  const errorWarns: HighlightContent[] = [];
  responses.forEach((res) => {
    res.errors.forEach((err) => {
      if (err.lineNumber && err.characterNumber >= 0)
        errorWarns.push({
          type: EC.ERROR,
          line: err.lineNumber,
          character: err.characterNumber,
          message: err.message,
        });
    });
    res.warnings.forEach((warn) => {
      if (warn.lineNumber && warn.characterNumber >= 0)
        errorWarns.push({
          type: EC.WARN,
          line: warn.lineNumber,
          character: warn.characterNumber,
          message: warn.message,
        });
    });
    res.fatals.forEach((fatal) => {
      if (fatal.lineNumber && fatal.characterNumber >= 0)
        errorWarns.push({
          type: EC.FATAL,
          line: fatal.lineNumber,
          character: fatal.characterNumber,
          message: fatal.message,
        });
    });
  });
  return errorWarns;
}

export function firstSingleQuotedWord(sentence: string) {
  const singleQuotedWordList = sentence.match(/'(.*?)'/gim);
  let singleQuotedWord = null;
  if (singleQuotedWordList && singleQuotedWordList.length) {
    const firstExistence = singleQuotedWordList[0].replace(/'/g, '');
    singleQuotedWord = '<EOF>' !== firstExistence ? firstExistence : null;
  }
  return singleQuotedWord;
}

export function getReadableName(dialect: string) {
  return READABLENAMES.sqlDialect[dialect]
    ? READABLENAMES.sqlDialect[dialect]
    : dialect;
}

const highlightedNodeSvgShape = {
  shapeProps: {
    fill: BrandColors.Primary,
    r: 10,
  },
};

const normalNodeSvgShape = {
  shapeProps: {
    fill: BrandColors.White,
    r: 10,
  },
};

export const generateTreeData = (parseResponse: any): any => {
  const treeData = [];
  if (Array.isArray(parseResponse)) {
    parseResponse.forEach((res) => {
      if (res.text) {
        treeData.push({
          name: res.text,
          nodeSvgShape: highlightedNodeSvgShape,
        });
      } else {
        treeData.push(...generateTreeData(res));
      }
    });
  } else {
    Object.keys(parseResponse).forEach((key: string) => {
      const subTreeData = generateTreeData(parseResponse[key]);
      if (subTreeData.length > 0) {
        treeData.push({
          name: key,
          children: subTreeData,
          nodeSvgShape: normalNodeSvgShape,
        });
      } else
        treeData.push({ name: key, nodeSvgShape: highlightedNodeSvgShape });
    });
  }
  return treeData;
};

/**
 * For generate translate url - SQL Translation
 *
 * @param flag to identify is user authnticated or seeing demo
 * @param source source dialect name
 * @param target target dialect name
 * @param action `push` or `replace` into browser history
 *
 * @returns with url and action
 * url: /tools/sql-translation/translate/teradata-to-impala --> flag = false
 * url: /tools/sql-translation/demo/translate/teradata-to-impala --> flag = true (will add extra pathas `demo`)
 * action -> { replace: true | false }
 */
export function getNavigation(
  flag: boolean,
  source: string,
  target: string,
  action?: string
) {
  const navigation = {
    url: `${Paths.SqlTranslation}/${sqlmorphPaths.translate}/${source}-to-${target}`,
    action: { replace: action === 'replace' },
  };
  if (flag) {
    navigation.url = `${Paths.SqlTranslation}/${sqlmorphPaths.demo}/${sqlmorphPaths.translate}/${source}-to-${target}`;
  }
  return navigation;
}

export function translationStatusDesc(status: MessageLevel) {
  switch (status) {
    case MessageLevel.Warning:
      return 'Code has been converted. However, the user should review the code for correctness.';
    case MessageLevel.Error:
      return 'Code has not been fully converted. See the message for more details or context.';
    case MessageLevel.Info:
      return 'Code has been correctly converted and a message was shared to document the translation.';
    case MessageLevel.Fatal:
      return 'Code failed to parse. This may be due to invalid SQL or a bug in our tool.';
    default:
      return status;
  }
}
