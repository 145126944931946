import { Experience, FormField, RequestType } from '../demo/domain';

export interface Action<T = any> {
  type: string;
  payload: T;
}

export const Actions = {
  SET_EXPERIENCE: 'SET_EXPERIENCE',
  SET_SQL: 'SET_SQL',
  SET_REQUEST_TYPE: 'SET_REQUEST_TYPE',
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_GROUP: 'SET_GROUP',
  SET_FORM_FIELDS: 'SET_FORM_FIELDS',
  SET_FIELD_VALUES: 'SET_FIELD_VALUES',
};

export const setExperience = (payload: Experience) => ({
  type: Actions.SET_EXPERIENCE,
  payload,
});

export const setSql = (payload: string) => ({
  type: Actions.SET_SQL,
  payload,
});

export const setRequestType = (payload: RequestType) => ({
  type: Actions.SET_REQUEST_TYPE,
  payload,
});

export const setTemplate = (payload: string) => ({
  type: Actions.SET_TEMPLATE,
  payload,
});

export const setGroup = (payload: string) => ({
  type: Actions.SET_GROUP,
  payload,
});

export const setFieldValues = (payload: { [key: string]: string }) => ({
  type: Actions.SET_FIELD_VALUES,
  payload,
});

export const setFormFields = (payload: FormField[]) => ({
  type: Actions.SET_FORM_FIELDS,
  payload,
});
