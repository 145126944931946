import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { CardListProps } from 'Common/interfaces';
import React from 'react';
import { AnalyticsLibraryProps as ALProps } from 'Common/interfaces';

type Props = {
  page: string;
  cards: CardListProps[] | ALProps[];
  imgPadding?: number;
  handleCardClick: (args: any) => void;
  getImageUrl?: (args: any) => void;
};

export function CardList(props: Props) {
  return (
    <Grid container alignItems="stretch" justifyContent="center">
      {props.cards.map((card: any, ind: number) => (
        // Max out at 3 cards per row on lg/xl screens (4/12)
        // Bump down to 2 cards per row on md screens (6/12)
        // Use only 1 card pre row on sm/xs screens (12/12)
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={4}
          key={ind}
          sx={{ display: 'flex !important', mb: 2, p: 2 }}
        >
          <Card
            data-testid={`card-item-${ind}`}
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'space-between',
              flexDirection: 'column',
              borderRadius: '10px',
              boxShadow: '0 12px 32px -12px rgba(0, 0, 0, 0.15)',
            }}
          >
            <CardActionArea
              onClick={() => props.handleCardClick(card)}
              sx={{
                height: '100%',
              }}
            >
              <CardMedia
                component={'img'}
                alt={card.title}
                sx={{
                  objectFit: 'cover !important',
                  height: '250px',
                  p: props.imgPadding ? props.imgPadding : 0,
                }}
                image={props.getImageUrl ? props.getImageUrl(card) : card.image}
                title={card.title}
              />
              <Divider variant="middle" />
              <CardContent sx={{ py: 3, px: 2 }}>
                <Grid sx={{ pb: 2 }} container>
                  <Typography variant="h2" fontWeight="500">
                    {card.title}
                  </Typography>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p">
                  {card.desc}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
