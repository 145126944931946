import Box from '@mui/material/Box';
import { BrandColors } from 'Common/common-ui/common/theme';
import React from 'react';

export function RateLimitExceededMessage() {
  return (
    <Box component="div">
      <Box>You are over your limit for translating queries.</Box>
      <Box component="div">
        Please contact
        <a
          style={{ paddingLeft: 5, paddingRight: 5, color: BrandColors.Teal }}
          href="mailto:sales@phdata.io"
        >
          sales@phdata.io
        </a>
        to enable further translations.
      </Box>
    </Box>
  );
}

export function SupportErrorMessage() {
  return (
    <Box component="div">
      <Box mr={'5px'}>There was a problem submitting feedback,</Box>
      <Box component="div">
        please contact
        <a
          style={{ paddingLeft: 5, paddingRight: 5 }}
          href="mailto:toolkit-support@phdata.io"
        >
          toolkit-support@phdata.io
        </a>
      </Box>
    </Box>
  );
}
