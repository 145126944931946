import AutorenewIcon from '@mui/icons-material/Autorenew';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CommonContext, NotificationStatus } from 'Common/common-ui';
import { generateAuthToken } from 'Common/services/api';
import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import PasswordField from './PasswordField';

export default function AuthTokenGenerator() {
  const { addNotification } = useContext(CommonContext);
  const [token, setToken] = React.useState<string>('');
  const [expiration, setExpiration] = React.useState<string>('');

  const { mutateAsync: generateTokenAsync, isLoading } = useMutation(
    (e: { days: number }) => generateAuthToken(e.days),
    {
      onSuccess: (data) => {
        setToken(data.jws);
        setExpiration(new Date(data.expiration).toLocaleString());
      },
      onError: () => {
        addNotification({
          message: 'Failed to generate token',
          status: NotificationStatus.ERROR,
        });
      },
    }
  );

  return (
    <Box sx={{ my: 2 }} data-testid="auth-token-generator">
      <Stack
        spacing={2}
        direction={{ sm: 'column', md: 'row' }}
        alignItems={{ sm: 'left', md: 'center' }}
      >
        <LoadingButton
          data-testid="generate-token-button"
          aria-label="Generate Auth Token"
          variant="outlined"
          onClick={() => generateTokenAsync({ days: 365 })}
          loading={isLoading}
          sx={{ minWidth: 45, minHeight: 45, maxHeight: 45, maxWidth: 45 }}
        >
          <AutorenewIcon />
        </LoadingButton>
        <PasswordField
          value={token}
          message={'Copied token'}
          dataTestId={'auth-token-text'}
          label={'Auth Token'}
          sx={{ maxWidth: 350 }}
        />
        {expiration && (
          <Stack>
            <Typography variant="body2">Token Expiration:</Typography>
            <Typography variant="body2">{expiration}</Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
