import { Auth0ContextInterface, User, useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import {
  AuthError,
  axiosInstance as axios,
  CommonContext,
  Footer,
  ModalState,
  updateAxiosHeader,
} from 'Common/common-ui';
import { LoadingIndicator } from '@toolkit/common';
import {
  openModal,
  setIsInterceptorReady,
  setUserDetails,
} from 'Common/common-ui/app-state/actions';
import { redirectURLs } from 'Common/util';
import React, { Suspense } from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Header from './layout/header/Header';
import LeftNav from './layout/left-nav/LeftNav';
import ActivateUser from './layout/other/ActivateUser';
import DevSiteBanner from './layout/other/DevSiteBanner';
import VerifyEmailRequired from './layout/other/VerifyEmailRequired';
import UpdateMetaDesciption from './layout/other/UpdateMetaDesciption';

const GlobalModal = React.lazy(() => import('Common/components/modal'));

const Auth0Messages = {
  VERIFY_EMAIL: 'Please verify your email before logging in',
  INVALID_STATE: 'Invalid state',
};
const UserActivation = 'user-activation';

const App: React.FC = () => {
  const location: Location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const auth0: Auth0ContextInterface = useAuth0();
  const [verifyEmail, setVerifyEmail] = React.useState<boolean>(false);
  const [latestLocation, setLatestLocation] = React.useState<string>(null);
  const isPageParams: boolean = searchParams.has('page');
  const pageName: string = isPageParams && searchParams.get('page');
  const tokenDetailsRef = React.useRef({ token: null, expire: 0 });
  const {
    state: { isDevEnv },
    dispatch,
  } = React.useContext(CommonContext);

  React.useEffect(() => {
    /* To remove the `iss` search param from the URL after the application loads */
    if (searchParams.get('iss')) {
      searchParams.delete('iss');
      setSearchParams(searchParams);
    }
    const path: string = redirectURLs(location);
    if (path) {
      navigate(path, { replace: true });
    }
    if (latestLocation !== location.pathname) {
      setLatestLocation(location.pathname);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (auth0.isAuthenticated) {
      setAxiosAuthorizationHeader();
      getUserDetails();
    }
  }, [auth0.isAuthenticated]);

  async function getTokenDetails() {
    try {
      const token = await auth0.getAccessTokenSilently({
        cacheMode: 'off',
        authorizationParams: {
          redirect_uri: window.location.origin,
          scope: 'openid profile offline_access email',
        },
        detailedResponse: true,
      });
      if (token) {
        const _tokenExpiresIn: number = (await auth0.getIdTokenClaims()).exp;
        const _tokenDetails = {
          token: token?.id_token,
          expire: _tokenExpiresIn * 1000,
        };
        tokenDetailsRef.current = _tokenDetails;
        return _tokenDetails;
      }
    } catch (e) {
      console.error(e);
      auth0.logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  function setAxiosAuthorizationHeader() {
    dispatch(setIsInterceptorReady(true));
    axios.interceptors.request.use(
      async (config) => {
        try {
          const expireIn = tokenDetailsRef.current?.expire;
          if (expireIn < Date.now() && auth0.isAuthenticated) {
            const _tokenDetails = await getTokenDetails();
            return updateAxiosHeader(config, _tokenDetails?.token);
          } else {
            return updateAxiosHeader(config, tokenDetailsRef.current?.token);
          }
        } catch (error) {
          console.error('Error fetching token:', error);
          return config;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  function getUserDetails(): void {
    try {
      const userDetails: User = {
        ...auth0.user,
        firstName: auth0.user.given_name,
        lastName: auth0.user.family_name,
        email: auth0.user.email,
      };
      dispatch(setUserDetails(userDetails));
    } catch (err) {
      console.log(`User Info Error: ${err}`);
    }
  }

  if (isPageParams && pageName === UserActivation) {
    const userActivationModal: ModalState = {
      body: <ActivateUser />,
    };
    dispatch(openModal(userActivationModal));
  }

  if (auth0.error) {
    if (auth0.error.message === Auth0Messages.INVALID_STATE) {
      auth0.loginWithRedirect();
      return (
        <LoadingIndicator fullScreen={true} title="Loading application..." />
      );
    } else if (auth0.error.message === Auth0Messages.VERIFY_EMAIL) {
      const onCloseModalPopup = (e: React.MouseEvent) => {
        e.preventDefault();
        auth0.logout({ logoutParams: { returnTo: window.location.origin } });
      };
      const verifyEmailModal: ModalState = {
        title: 'Email verification is required before logging in',
        body: <VerifyEmailRequired />,
        onClose: onCloseModalPopup,
      };
      if (!verifyEmail) {
        dispatch(openModal(verifyEmailModal));
        setVerifyEmail(true);
      }
    } else {
      return (
        <Suspense
          fallback={
            <LoadingIndicator fullScreen={true} title="Loading error..." />
          }
        >
          <AuthError
            reason={auth0.error.name}
            message={auth0.error.message}
            logout={auth0.logout as () => void}
          />
        </Suspense>
      );
    }
  }

  return (
    <Suspense
      fallback={
        <LoadingIndicator fullScreen={true} title="Loading application..." />
      }
    >
      {latestLocation && <UpdateMetaDesciption />}
      {!auth0.isLoading && (
        <Container
          id={'global-container'}
          sx={{ mx: 0, px: 0 }}
          maxWidth={false}
          disableGutters={true}
        >
          <GlobalModal />
          {isDevEnv && <DevSiteBanner />}
          <Header />
          <LeftNav />
          <Footer />
        </Container>
      )}
    </Suspense>
  );
};

export default App;
