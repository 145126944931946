import { ImageItem } from 'Common/interfaces';
import ProfileRelationImg from 'src/resources/ui-screenshots/data-source-tool/profile-relation-album.png';
import ScanImg from 'src/resources/ui-screenshots/data-source-tool/scan-table-view.png';
import DiffTableImg from 'src/resources/ui-screenshots/data-source-tool/diff-table-view.png';
import DiffDiagramImg from 'src/resources/ui-screenshots/data-source-tool/diff-diagram.png';
import DiffRelationImg from 'src/resources/ui-screenshots/data-source-tool/diff-relation-artist.png';

export const ImageArray: Array<ImageItem> = [
  {
    img: DiffTableImg,
    title: 'Diff Relation',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/data-source/diff-example.html#/?tutorialStatus=running',
  },
  {
    img: DiffDiagramImg,
    title: 'Diff Changes',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/data-source/diff-example.html#/diagram?tutorialStatus=running',
  },
  {
    img: DiffRelationImg,
    title: 'Diff Column view',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/data-source/diff-example.html#/relation/Artist?tutorialStatus=running',
  },
  {
    img: ProfileRelationImg,
    title: 'Profile',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/data-source/profile-example.html#/relation/Album?tutorialStatus=running',
  },
  {
    img: ScanImg,
    title: 'Scan',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/data-source/scan-example.html#/?tutorialStatus=running',
  },
];
