import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, Stack, SxProps, TextField, Theme } from '@mui/material';
import { CommonContext, NotificationStatus } from 'Common/common-ui';
import CircularProgress from '@mui/material/CircularProgress';
import CopyToClipboard from 'copy-to-clipboard';
import React, { useContext } from 'react';

interface Props {
  value: string;
  message: string;
  dataTestId: string;
  label: string;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

export default function PasswordField(props: Props) {
  const { addNotification } = useContext(CommonContext);
  const [showToken, setShowToken] = React.useState<boolean>(false);

  const copyToken = () => {
    CopyToClipboard(props.value);
    addNotification({
      message: props.message,
      status: NotificationStatus.SUCCESS,
    });
  };

  const toggleVisibility = () => {
    setShowToken(!showToken);
  };

  const handleMouseDownToken = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      fullWidth
      data-testid={props.dataTestId}
      label={props.label}
      title={props.value}
      value={props.value}
      variant="outlined"
      type={showToken ? 'text' : 'password'}
      sx={{ ...props?.sx }}
      disabled={!props.value}
      InputProps={{
        readOnly: true,
        startAdornment: props.isLoading && <CircularProgress size={30} />,
        endAdornment: (
          <Stack direction="row">
            <IconButton
              id={`show-${props.label.replace(/\s/g, '-')}`}
              title="Toggle Visibility"
              disabled={!props.value}
              onClick={toggleVisibility}
              onMouseDown={handleMouseDownToken}
            >
              {showToken ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
            <IconButton
              id={`copy-${props.label.replace(/\s/g, '-')}`}
              title={`Copy ${props.label}`}
              disabled={!props.value}
              onClick={copyToken}
            >
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Stack>
        ),
      }}
    />
  );
}
