import * as React from 'react';
import Box from '@mui/material/Box';
import { LoadingIndicator } from '@toolkit/common';
import { BrandColors } from 'Common/common-ui/common/theme';

type Props = {
  isLoading: boolean;
  template: React.ReactNode | null;
};

const RenderAsciidoc: React.FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        width: '100% !important',
        '& p': {
          fontSize: '16px',
        },
        '& hr': {
          margin: '0.5em 0 1em',
        },
        position: 'relative',
        '& a': {
          color: BrandColors.Teal,
        },
        '& a:hover': {
          color: BrandColors.TealHover,
        },
      }}
    >
      {props.isLoading && (
        <LoadingIndicator fullScreen={true} title="Loading documentation..." />
      )}
      {props.template}
    </Box>
  );
};

export default RenderAsciidoc;
