import { getBaseOutletContext } from '@toolkit/router/AppRouter';
import React, { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';
import IndividualCourse from './IndividualCourse';
import CourseCatalog from './CourseCatalog';
const TrainingPage = lazy(() => import('./Training'));

const scrollableSectionHeightObj: { [key: string]: string } = {
  xs: 'calc(100vh - 180px)',
  sm: 'calc(100vh - 100px)',
};

const Train: React.FC = () => {
  const { featureFlags } = getBaseOutletContext();
  return <Outlet context={{ featureFlags }} />;
};

export default Train;

export const TrainingRouter = (
  <Route>
    <Route
      index
      element={
        <TrainingPage
          children={
            <CourseCatalog
              scrollableSectionHeight={scrollableSectionHeightObj}
            />
          }
        />
      }
    ></Route>
    <Route
      path={':id/*'}
      element={
        <TrainingPage
          children={
            <IndividualCourse
              scrollableSectionHeight={scrollableSectionHeightObj}
            />
          }
        />
      }
    />
  </Route>
);
