import { getBaseOutletContext } from '@toolkit/router/AppRouter';
import * as Paths from '@toolkit/router/paths';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Header, HeaderNavItem } from 'Common/common-ui';

import {
  ProvisionDemoContext,
  useProvisionDemoReducer,
} from '../contexts/reducer';

const ProvisionHeader: React.FC = () => {
  const [state, dispatch] = useProvisionDemoReducer();
  const { featureFlags } = getBaseOutletContext();

  const navOptions: HeaderNavItem[] = [
    {
      title: 'About',
      to: `${Paths.ProvisionTool}/about`,
    },
    {
      title: 'Demo',
      to: `${Paths.ProvisionTool}/demo`,
    },
  ];

  return (
    <ProvisionDemoContext.Provider value={{ state, dispatch }}>
      <Header navOptions={navOptions} />
      <Outlet context={{ featureFlags }} />
    </ProvisionDemoContext.Provider>
  );
};

export default ProvisionHeader;
