import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { CommonContext, openModal } from '../common-ui';

export type AppInfoProp = {
  tooltip: string;
  title: string;
  content: React.ReactElement | string;
};

export default function AppInfo(props: AppInfoProp) {
  const { dispatch } = React.useContext(CommonContext);

  function handleOpenInfo(): void {
    dispatch(
      openModal({
        title: props.title,
        body: <Box maxWidth={600}>{props.content}</Box>,
      })
    );
  }

  return (
    <Box>
      <Tooltip title={props.tooltip} placement="left" arrow>
        <IconButton
          sx={{ height: 40, mt: 2, ml: 1 }}
          onClick={handleOpenInfo}
          color={'primary'}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
