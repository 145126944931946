import {
  ORSFCreateTableVColType,
  ORSFDatatypeForDate,
  RequestOptions,
} from './interfaces';

export const BASE_URL =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080';

export const READABLENAMES = {
  sqlDialect: {
    databricks: 'Databricks SparkSQL',
    hana: 'Hana',
    hive: 'Hive',
    hivefamily: 'HiveFamily (Hive, Impala, Databricks SparkSQL and SparkSQL)',
    impala: 'Impala',
    mssql: 'Microsoft SQL Server',
    oracle: 'Oracle',
    snowflake: 'Snowflake',
    sparksql: 'Spark SQL',
    teradata: 'Teradata',
    netezza: 'Netezza',
    redshift: 'Redshift',
  },
};

export const initialRequestOptions: RequestOptions = {
  format: true,
  createTableSettings: {
    orsfCreateTableVColType: ORSFCreateTableVColType.view,
  },
  commonSettings: {
    orsfDatatypeForDate: ORSFDatatypeForDate.DATE,
  },
};
