import { BrandColors } from 'Common/common-ui/common/theme';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import FilterDrawer from 'Common/components/FilterDrawer';
import LeftDrawer from 'Common/components/LeftDrawer';
import { FilterSections } from 'Common/interfaces';
import FilterText from 'Common/components/FilterText';
import AppInfo from 'Common/components/AppInfo';
import { courses } from './courseCopy';
import CourseCard from './CourseCard';
import MoreCoursesCard from './MoreCoursesCard';
import { Course } from './courseCopy';
import { useNavigate } from 'react-router-dom';
import TealLink from 'Common/components/TealLink';

export default function CourseCatalog(props: {
  scrollableSectionHeight: { [key: string]: string };
}) {
  const navigate = useNavigate();
  const topSectionHeight = 70;
  const [courseArr, setCourseArr] = useState<Course[]>(courses);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [sections, setSections] = useState<FilterSections>({
    Topics: {
      SQL: { on: true, count: 1 },
    },
  });

  const selectedFiltersSet = useMemo(() => {
    return new Set(
      Object.keys(sections['Topics'])
        .filter((topic) => sections['Topics'][topic].on)
        .map((topic) => topic.toLocaleLowerCase())
    );
  }, [sections]);

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
    reason: string
  ) {
    if (reason !== 'reset') {
      setSearchText(value);
    }
  }

  useEffect(() => {
    const newCourseArr = courses.filter((course: Course) =>
      selectedFiltersSet.has(course.topic)
    );
    if (searchText && searchText.trim()) {
      setCourseArr(
        newCourseArr.filter((course: Course) => {
          const courseTxt =
            course.title?.toLocaleLowerCase() +
            course.description?.toLocaleLowerCase() +
            ' ' +
            course.durationMinutesFast?.toString() +
            ' ' +
            course.durationMinutesSlow?.toString();
          return courseTxt.includes(searchText);
        })
      );
    } else {
      setCourseArr(newCourseArr);
    }
  }, [searchText, sections]);

  return (
    <>
      <Grid
        sx={{
          border: 1,
          borderColor: BrandColors.LightGray,

          display: 'flex',
          position: 'relative',
          borderRadius: 0,

          m: 0,
        }}
        item
        lg={showFilters ? 2.5 : 0.2}
        md={showFilters ? 3 : 0.2}
        sm={showFilters ? 4 : 0.2}
        xs={12}
      >
        <Collapse
          sx={{
            width: '100% !important',
            display: 'flex',
            '.MuiCollapse-wrapper': {
              width: '100%',
              '.MuiCollapse-wrapperInner': {
                width: '100%',
              },
            },
          }}
          orientation="horizontal"
          in={showFilters}
        >
          <FilterDrawer
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            title="Course"
            sections={sections}
            setSections={setSections}
            topSectionHeight={topSectionHeight}
            scrollableSectionHeight={props.scrollableSectionHeight}
          />
        </Collapse>
        <LeftDrawer showFilters={showFilters} setShowFilters={setShowFilters} />
      </Grid>
      <Grid
        sx={{
          borderTop: 1,
          borderRadius: 0,
          borderColor: BrandColors.LightGray,
        }}
        item
        lg={showFilters ? 9.5 : 11.8}
        md={showFilters ? 9 : 11.8}
        sm={showFilters ? 8 : 11.8}
        xs={12}
        pl={1}
      >
        <Box
          display="flex"
          pl={1}
          justifyContent="space-between"
          height={topSectionHeight}
        >
          <FilterText
            searchText={searchText}
            placeHolder={'Course search...'}
            onInputChange={onInputChange}
          />
          <AppInfo
            title={'Training & Enablement\n'}
            tooltip={'Training & Enablement'}
            content={
              <Typography>
                This page displays our sample courses only. Reach out to a
                member of the phData team{' '}
                <TealLink
                  rel="noopener noreferrer"
                  target="_blank"
                  to="https://www.phdata.io/training-and-enablement/"
                >
                  here
                </TealLink>{' '}
                to learn more about our data coach courses and all of our
                training and enablement services.{' '}
              </Typography>
            }
          />
        </Box>
        <Box display={'flex'}>
          <Grid sx={{ pb: 5 }} spacing={1} container>
            <Grid display="flex" item sm={4}>
              <MoreCoursesCard />
            </Grid>
            {courseArr.map((course) => (
              <Grid key={course.id} item sm={4}>
                <CourseCard
                  onClickCourse={(course: Course) =>
                    navigate(`../training/${course.id}`)
                  }
                  course={course}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
