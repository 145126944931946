import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface LoadingIndicatorProps {
  title: string;
  justifyContent?: string;
}

export default function InlineLoadingIndicator(props: LoadingIndicatorProps) {
  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: props.justifyContent || 'left',
        height: 'inherit',
        flexDirection: 'row',
      }}
      aria-busy="true"
      role="alert"
    >
      <CircularProgress color="primary" />
      <Typography variant="subtitle1">{props.title}</Typography>
    </Stack>
  );
}
