import { createTheme } from '@mui/material';

export enum BrandColors {
  Primary = '#264369',
  FadePrimary = '#5F7591',
  Secondary = '#7ba7bc',
  Gray = '#949494',
  DarkGray = '#333332',
  LightGray = '#e9edee',
  White = '#ffffff',
  Red = '#ed304f',
  Yellow = '#ffd65c',
  Green = '#44A92A',
  DarkGreen = '#0D9D58',
  Teal = '#1ABC9C',
  TealHover = '#0DA498',
  Mint = '#AAF5D2',
  BlueGray = '#DBE6EE',
}

export enum BrandFonts {
  Primary = 'Poppins',
  Title = 'Oswald',
}

// See the defaults here: https://mui.com/customization/default-theme/
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: BrandColors.Primary,
    },
    secondary: {
      main: BrandColors.Secondary,
    },
    error: {
      main: BrandColors.Red,
    },
    warning: {
      main: BrandColors.Yellow,
    },
    info: {
      main: BrandColors.DarkGray,
    },
    success: {
      main: BrandColors.Green,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: BrandFonts.Primary,
    h1: {
      fontFamily: BrandFonts.Primary,
      color: BrandColors.Primary,
      fontSize: 24,
    },
    h2: {
      fontFamily: BrandFonts.Primary,
      color: BrandColors.Primary,
      fontSize: 22,
    },
    h3: {
      fontFamily: BrandFonts.Primary,
      color: BrandColors.Primary,
      fontSize: 20,
    },
    h4: {
      fontFamily: BrandFonts.Primary,
      color: BrandColors.Primary,
      fontSize: 19,
    },
    h5: {
      fontFamily: BrandFonts.Primary,
      color: BrandColors.Primary,
      fontSize: 18,
    },
    h6: {
      fontFamily: BrandFonts.Primary,
      color: BrandColors.Primary,
      fontSize: 17,
    },
    subtitle1: {
      fontFamily: BrandFonts.Primary,
      color: BrandColors.Primary,
      fontWeight: '500',
      fontSize: 16,
    },
    button: {
      textTransform: 'none',
    },
  },
  zIndex: {
    appBar: 1251, // Position the appbar above the left nav drawer.
  },
  components: {
    // Recolor the App Bar component to use light gray and primary colors.
    MuiAppBar: {
      defaultProps: {
        position: 'fixed',
      },
      styleOverrides: {
        colorPrimary: {
          backgroundColor: BrandColors.LightGray,
          color: BrandColors.Primary,
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: BrandColors.Primary,
          color: BrandColors.White,
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        variant: 'persistent',
        anchor: 'left',
        sx: {
          width: 250,
          flexShrink: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: BrandColors.DarkGray,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          margin: '10px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: BrandColors.Primary,
        },
      },
    },
  },
});

export default theme;
