import { ImageItem } from 'Common/interfaces';
import SummaryViewImg from 'src/resources/ui-screenshots/provision-tool/provision-summary-view.png';
import ResourcesImg from 'src/resources/ui-screenshots/provision-tool/provision-resource-list.png';

export const ImageArray: Array<ImageItem> = [
  {
    img: SummaryViewImg,
    title: 'Summary View',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/provision/plan-report.html#/summary?tutorialStatus=running',
  },
  {
    img: ResourcesImg,
    title: 'Resources List',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/provision/plan-report.html#/stack%2Fgroups%2Fproject.yaml%3A0?tutorialStatus=running',
  },
];
