import { User } from '@auth0/auth0-react';
import { AnalyticsLibraryProps } from 'Common/interfaces';
import { AlgoliaConfig } from '../common/interfaces';
import { ModalState } from './reducer';

export const Actions = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  TOGGLE_LEFT_NAV: 'TOGGLE_LEFT_NAV',
  DASHBOARD_LIST: 'DASHBOARD_LIST',
  DATE_RANGE: 'DATE_RANGE',
  APP_LIST: 'APP_LIST',
  TAG_LIST: 'TAG_LIST',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  DETAIL_FLAGS: 'DETAIL_FLAGS',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_RUN_TUTORIAL: 'SET_RUN_TUTORIAL',
  SET_IS_DEV_ENV: 'SET_IS_DEV_ENV',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_ALGOLIA_CREDENTIALS: 'SET_ALGOLIA_CREDENTIALS',
  SET_META_TAG_TITLE: 'SET_META_TAG_TITLE',
  SET_IS_INTERCEPTOR_READY: 'SET_IS_INTERCEPTOR_READY',
};

export const openModal = (payload: ModalState) => ({
  type: Actions.OPEN_MODAL,
  payload: {
    ...payload,
    open: true,
  },
});

export const closeModal = () => ({
  type: Actions.CLOSE_MODAL,
  payload: undefined,
});

export const setDashboards = (dashboards: AnalyticsLibraryProps[]) => ({
  type: Actions.DASHBOARD_LIST,
  payload: dashboards,
});

export const setDateRange = (dateRange: string[]) => ({
  type: Actions.DATE_RANGE,
  payload: dateRange,
});

export const setAppList = (apps: string[]) => ({
  type: Actions.APP_LIST,
  payload: apps,
});

export const setTagList = (tags: string[]) => ({
  type: Actions.TAG_LIST,
  payload: tags,
});

export const setDetailFlags = (details: { [key: string]: boolean }) => ({
  type: Actions.DETAIL_FLAGS,
  payload: details,
});

export const setUserDetails = (user: User) => ({
  type: Actions.SET_USER_DETAILS,
  payload: user,
});

export const setIsDevEnv = (isDev: boolean) => ({
  type: Actions.SET_IS_DEV_ENV,
  payload: isDev,
});

export const setSearchText = (text: string) => ({
  type: Actions.SET_SEARCH_TEXT,
  payload: text,
});

export const setAlgoliaCredentials = (credentials: AlgoliaConfig) => ({
  type: Actions.SET_ALGOLIA_CREDENTIALS,
  payload: credentials,
});

export const setMetaTagTitle = (title: string) => ({
  type: Actions.SET_META_TAG_TITLE,
  payload: title,
});

export const setIsInterceptorReady = (interceptor: boolean) => ({
  type: Actions.SET_IS_INTERCEPTOR_READY,
  payload: interceptor,
});
