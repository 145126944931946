import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BrandColors } from 'Common/common-ui/common/theme';
import {
  AppConfig,
  CommonContext,
  getAppConfig,
  ModalState,
  openModal,
} from 'Common/common-ui';
import SearchModal from './SearchModal';
import SearchEndIcon from './SearchEndIcon';
import { setAlgoliaCredentials } from 'Common/common-ui/app-state/actions';

const GlobalSearch: React.FC = () => {
  const { dispatch } = React.useContext(CommonContext);

  React.useEffect(() => {
    (async () => {
      const { algolia }: AppConfig = await getAppConfig();
      dispatch(setAlgoliaCredentials(algolia));
    })();
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function handleKeyDown(event): void {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      event.preventDefault();
      handleSearchModal();
    }
  }

  function handleSearchModal(): void {
    const userActivationModal: ModalState = {
      body: <SearchModal />,
    };
    dispatch(openModal(userActivationModal));
  }

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        '& span': {
          mr: 0,
          '& svg:nth-of-type(1)': {
            fontSize: 32,
            color: BrandColors.Primary,
          },
        },
        '&:hover': {
          bgcolor: BrandColors.White,
          '& span': { color: BrandColors.Primary },
          '& svg:nth-of-type(1)': { color: BrandColors.Primary },
        },
        m: 'auto',
        mt: 2,
        borderRadius: 25,
        borderColor: BrandColors.White,
        bgcolor: BrandColors.Primary,
      }}
      startIcon={<SearchIcon sx={{ color: BrandColors.White }} />}
      onClick={handleSearchModal}
      endIcon={<SearchEndIcon />}
      disableFocusRipple
    >
      <Typography component={'span'} color={BrandColors.White}>
        Search
      </Typography>
    </Button>
  );
};

export default GlobalSearch;
