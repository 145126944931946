import React from 'react';
import { ArrowRightAltRounded } from '@mui/icons-material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Paths from '@toolkit/router/paths';
import { CommonContext, InlineLoadingIndicator } from 'Common/common-ui';
import { BrandColors } from 'Common/common-ui/common/theme';
import TealLink from 'Common/components/TealLink';
import { LicenseType } from 'Common/enums';
import { getLicense } from 'Common/services/api';
import { useQuery } from 'react-query';

type LicenseInfo = {
  type: LicenseType;
  desc: React.ReactNode;
};

const licenses: LicenseInfo[] = [
  {
    type: LicenseType.ALL,
    desc: 'Access to all tools without restriction. Some tools may be experimental.',
  },
  {
    type: LicenseType.PRO,
    desc: 'Access to all tools without restrictions.',
  },
  {
    type: LicenseType.FREE,
    desc: (
      <>
        <TealLink to={`${Paths.ToolkitUserGuide}#licensing`}>
          Limited access
        </TealLink>{' '}
        to the tools.
      </>
    ),
  },
];

export default function Licenses() {
  const {
    state: { isInterceptorReady },
  } = React.useContext(CommonContext);
  const [licenseType, setLicenseType] = React.useState<LicenseType>(null);

  const {
    isLoading,
    data: licenseData,
    error: licenseError,
    isSuccess,
    refetch,
  } = useQuery('getLicense', getLicense, { enabled: false });

  React.useEffect(() => {
    if (isInterceptorReady) {
      refetch();
    }
  }, [refetch, isInterceptorReady]);

  React.useEffect(() => {
    if (isSuccess) {
      setLicenseType(LicenseType[licenseData.licenseType]);
    }
  }, [licenseData, isSuccess]);

  function generateBadge(licenseType: LicenseType): React.ReactNode {
    return (
      <Typography
        maxHeight={25}
        sx={{
          background: BrandColors.Primary,
          color: BrandColors.White,
          pl: 1,
          pr: 1,
          borderRadius: 1,
        }}
        justifyItems="center"
      >
        {licenseType}
      </Typography>
    );
  }

  function userLicense() {
    if (isLoading) {
      return (
        <Box ml={2}>
          <InlineLoadingIndicator title="Fetching license..." />
        </Box>
      );
    } else if (licenseError) {
      return (
        <Box ml={2}>
          <Typography color="red">Failed to load license details.</Typography>
        </Box>
      );
    } else {
      return (
        <>
          <Box ml={1}>{generateBadge(licenseType)}</Box>
        </>
      );
    }
  }

  return (
    <>
      <Typography variant="h3" pb={3} fontWeight="500">
        License Information
      </Typography>
      <Stack spacing={1} data-testid="license">
        <Stack direction="row" display={'flex'} alignItems="center">
          <Typography variant="body1" color={'primary'} fontWeight="500">
            Current License
          </Typography>
          : {userLicense()}
        </Stack>
        <List>
          {licenses
            .filter((info) => {
              if (licenseType === LicenseType.ALL) {
                return true;
              } else {
                // hide ALL license from normal users unless they have ALL license
                return info.type !== LicenseType.ALL;
              }
            })
            .map((l) => (
              <ListItem key={l.type} disableGutters>
                <ListItemIcon>{generateBadge(l.type)}</ListItemIcon>
                <ArrowRightAltRounded sx={{ mr: 1 }} />
                <ListItemText>
                  <Typography>{l.desc}</Typography>
                </ListItemText>
              </ListItem>
            ))}
        </List>
        <Typography>
          For license questions, such as how to upgrade your license, please
          reach out to the <TealLink to={Paths.Support}>support</TealLink> team.
        </Typography>
      </Stack>
    </>
  );
}
