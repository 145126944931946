import { styled } from '@mui/material/styles';
import { BrandColors } from 'Common/common-ui/common/theme';
import { LoadingButton } from '@mui/lab';

const TealButton = styled(LoadingButton)(() => ({
  color: BrandColors.White,
  backgroundColor: BrandColors.Teal,
  '&:hover': {
    backgroundColor: '#119C81',
    border: 'none',
  },
  '&.Mui-disabled': {
    background: BrandColors.LightGray,
    color: BrandColors.Gray,
    border: 'none',
  },
}));

export default TealButton;
