import { Box, Button, Container, styled } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';
import {
  AnalyticsLibraryProps as ALProps,
  FilterSection,
  FilterSections,
} from 'Common/interfaces';
import { getRgba } from 'Common/util';
import React, { useMemo } from 'react';
import { CardList } from './CardList';
import * as Paths from '@toolkit/router/paths';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AnalyticsTechnology } from 'Common/enums';

export interface CardGridProps {
  filteredDashboards: ALProps[];
  sections: FilterSections;
  setSections: (args: FilterSections) => void;
}

const StyledButton = styled(Button)({
  boxShadow: 'none',
  backgroundColor: BrandColors.White,
  color: BrandColors.DarkGray,
  fontWeight: 500,
  fontSize: '0.9375em',
  border: 0,
  '&:hover': {
    border: 'none',
  },
});

export default function CardGrid(props: CardGridProps) {
  const navigate: NavigateFunction = useNavigate();
  const filterOnStyles = {
    backgroundColor: BrandColors.Teal,
    color: BrandColors.White,
    '&:hover': {
      border: 'none',
      backgroundColor: getRgba(BrandColors.Teal, 0.9),
      color: BrandColors.White,
    },
  };

  const filterOffStyles = {
    backgroundColor: BrandColors.White,
    color: BrandColors.DarkGray,
    '&:hover': {
      border: 'none',
      backgroundColor: getRgba(BrandColors.White, 0.7),
    },
  };

  const allOn: boolean = useMemo(() => {
    if (props.sections) {
      let shouldSetAllOn = true;
      Object.keys(props.sections).forEach((section) => {
        Object.keys(props.sections[section]).forEach((tag) => {
          if (!props.sections[section][tag].on) {
            shouldSetAllOn = false;
          }
        });
      });
      return shouldSetAllOn;
    }
    return false;
  }, [props.sections]);

  function getColors(
    sectionName: string,
    filterName: string
  ): { [key: string]: any } {
    const styleObj = props.sections[sectionName][filterName].on
      ? filterOnStyles
      : filterOffStyles;
    return styleObj;
  }

  function loadPage(data: ALProps): void {
    navigate(`${Paths.AnalyticsLibrary}/${data.id}`);
  }

  function loadCardImage(dashboard: ALProps) {
    let imgUrl = dashboard.image;
    if (dashboard.technology === AnalyticsTechnology.POWER_BI) {
      imgUrl = generateURL(dashboard, 'power_bi');
    }
    if (dashboard.technology === AnalyticsTechnology.SIGMA) {
      imgUrl = generateURL(dashboard, 'sigma');
    }
    return imgUrl;
  }

  function generateURL(dashboard: ALProps, subDir: string): string {
    return dashboard.image.includes('https://')
      ? dashboard.image
      : `/${subDir}/${dashboard.image}`;
  }

  function handleClickFilter(sectionName: string, filterName: string) {
    const sectionsCopy: { [key: string]: FilterSection } = JSON.parse(
      JSON.stringify(props.sections)
    );
    if (sectionsCopy[sectionName][filterName]) {
      sectionsCopy[sectionName][filterName].on =
        !sectionsCopy[sectionName][filterName].on;
    }
    props.setSections(sectionsCopy);
  }

  function handleClickAllOn(allCurrentlyOn: boolean) {
    const sectionsCopy: { [key: string]: FilterSection } = JSON.parse(
      JSON.stringify(props.sections)
    );
    Object.keys(props.sections).forEach((section) => {
      Object.keys(props.sections[section]).forEach((tag) => {
        sectionsCopy[section][tag].on = !allCurrentlyOn;
      });
    });
    props.setSections(sectionsCopy);
  }

  // TODO: Add filter text component
  return (
    <Container
      sx={{
        backgroundColor: BrandColors.LightGray,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: '0px !important',
      }}
      maxWidth={false}
    >
      {' '}
      <Box
        sx={{
          width: '80%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',

          py: 4,
          m: 2,
        }}
      >
        <StyledButton
          onClick={() => handleClickAllOn(allOn)}
          sx={
            allOn ? { m: 1, ...filterOnStyles } : { m: 1, ...filterOffStyles }
          }
        >
          All
        </StyledButton>
        {props.sections &&
          Object.keys(props.sections).map((section, j) => {
            return (
              <>
                {Object.keys(props.sections[section]).map((tag, i) => {
                  return (
                    <StyledButton
                      onClick={() => handleClickFilter(section, tag)}
                      sx={{ m: 0.5, ...getColors(section, tag) }}
                      key={`${tag}-${i}`}
                    >
                      {tag}
                    </StyledButton>
                  );
                })}
              </>
            );
          })}
      </Box>
      <Box sx={{ width: '90%' }}>
        <CardList
          page={Paths.AnalyticsLibrary}
          cards={props.filteredDashboards}
          handleCardClick={(card) => loadPage(card)}
          getImageUrl={loadCardImage}
        />
      </Box>
    </Container>
  );
}
