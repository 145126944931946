export interface Action<T = any> {
  type: string;
  payload: T;
}

export enum NotificationStatus {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum NotificationLevel {
  MAJOR,
  MINOR,
}

export interface Notification {
  message: React.ReactNode;
  status?: NotificationStatus;
  level?: NotificationLevel;
}

export interface HeaderNavItem {
  icon?: any;
  title: string;
  to?: string;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
}

export interface HeaderProps {
  navOptions: HeaderNavItem[];
  logoutOnClick?: (e: React.MouseEvent) => void;
}

export interface AppBaseProps {
  beforeSignIn?: () => void;
}

export interface Auth0Config {
  domain: string;
  clientId: string;
  env?: string;
}

export interface AlgoliaConfig {
  applicationId: string;
  searchAPIKey: string;
  index: string;
}

export interface AppConfig {
  auth0: Auth0Config;
  algolia: AlgoliaConfig;
}
