import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { capitalize, Container } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as Paths from '@toolkit/router/paths';
import { LoadingIndicator } from '@toolkit/common';
import { BrandColors } from 'Common/common-ui/common/theme';
import SyntaxHighlighter from 'Common/components/SyntaxHighlighter';
import { getSQLMorphOutletContext } from 'Sqlmorph/scripts/SQLMorphRouting';
import { cloneDeep } from 'lodash';
import React from 'react';
import HighlightWord from 'react-highlight-words';
import { NavigateFunction, useLoaderData, useNavigate } from 'react-router-dom';
import {
  setDemoQuery,
  setEditorContent,
  setExecuteDemoQuery,
  setFileName,
  setSelectedSource,
  setSelectedTarget,
} from '../../app/actions';
import { SqlMorphContext } from '../../app/reducer';
import { DemoQuery, QueryItem } from '../../constants/interfaces';
import { translate } from '../../constants/paths';
import { getReadableName } from '../../constants/utilities';
import { getDemoQueries } from '../../services/api';
import './styles.scss';

export default function DemoQueries() {
  const { isDemoMode } = getSQLMorphOutletContext();
  const navigate: NavigateFunction = useNavigate();
  const [loading, startTransition] = React.useTransition();
  const data = useLoaderData() as { demoQueries: DemoQuery[] };
  const { dispatch: sqlMorphDispatch } = React.useContext(SqlMorphContext);
  const [actualDemoQueries, setActualDemoQueries] =
    React.useState<DemoQuery[]>(null);
  const [filteredDemoQueries, setFilteredDemoQueries] =
    React.useState<DemoQuery[]>(null);
  const [enteredSearchText, setEnteredSearchText] = React.useState<string>('');
  const [expandCollapseBtn, setExpandCollapseBtn] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    startTransition(() => {
      setActualDemoQueries(data?.demoQueries);
      setFilteredDemoQueries(data?.demoQueries);
    });
  }, []);

  function executeQuery(
    actualQuery: string,
    query: string,
    source: string,
    target: string
  ) {
    sqlMorphDispatch(setExecuteDemoQuery(true));
    sqlMorphDispatch(setFileName(null));
    sqlMorphDispatch(setDemoQuery(actualQuery));
    sqlMorphDispatch(setEditorContent([query, '']));
    sqlMorphDispatch(
      setSelectedSource({
        label: getReadableName(source),
        value: source,
      })
    );
    sqlMorphDispatch(
      setSelectedTarget({
        label: getReadableName(target),
        value: target,
      })
    );
    if (isDemoMode) {
      navigate(
        `${Paths.SqlTranslation}/demo/${translate}/${source}-to-${target}`
      );
    } else {
      navigate(`${Paths.SqlTranslation}/${translate}/${source}-to-${target}`);
    }
  }

  function handleSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const enteredText: string = event.target['value'].toLowerCase();
    const demoQueries = handleDemoQueries(enteredText);
    startTransition(() => {
      setFilteredDemoQueries(demoQueries);
    });
    setEnteredSearchText(enteredText);
  }

  function handleDemoQueries(enteredText: string): DemoQuery[] {
    const demoQueries: DemoQuery[] = cloneDeep([...actualDemoQueries]);
    const filteredDemoQueries: DemoQuery[] = [];
    if (enteredText && enteredText.trim().length) {
      demoQueries.forEach((demo: DemoQuery) => {
        demo.queries = demo.queries.filter((q: QueryItem) => {
          return (
            q.description?.toLowerCase().includes(enteredText) ||
            q.sourceStmt?.toLowerCase().includes(enteredText)
          );
        });
        filteredDemoQueries.push(demo);
      });
      return filteredDemoQueries;
    } else {
      return demoQueries;
    }
  }

  if (loading) {
    return (
      <LoadingIndicator fullScreen={true} title="Loading demo queries..." />
    );
  }

  return (
    <Container
      sx={{ display: 'flex', justifyContent: 'center' }}
      maxWidth={false}
    >
      <Grid
        sx={{
          pt: 3,
          width: '80%',
          maxWidth: 2000,
        }}
        container
        justifyContent="space-evenly"
        id="demo-grid-container"
      >
        <Grid size={{ xs: 12 }} data-testid="demo-queries">
          <Grid container justifyContent="center">
            <Typography variant="h1" fontWeight="500" mt={1}>
              Demo Queries
            </Typography>
          </Grid>
          <Grid
            container
            sx={{ mb: 1 }}
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Button
              color="primary"
              onClick={() => setExpandCollapseBtn(!expandCollapseBtn)}
              role="toggle-button"
              sx={{ textTransform: 'capitalize', m: 1 }}
            >
              {expandCollapseBtn ? 'collapse' : 'expand'} all
            </Button>
            <TextField
              data-testid="search-field"
              id="standard-search"
              label="Search here"
              type="search"
              value={enteredSearchText}
              onChange={handleSearchText}
              size={'small'}
            />
          </Grid>
          <Box pt={3} height="calc(100vh - 250px)">
            {filteredDemoQueries &&
              filteredDemoQueries.map((demoData: DemoQuery, ind: number) => (
                <Accordion
                  key={`${ind}${expandCollapseBtn}`}
                  defaultExpanded={expandCollapseBtn}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel1bh-content${ind}`}
                    role={`accordion-panel${ind}`}
                  >
                    <Typography
                      data-testid={`accordion-summary-${ind}`}
                      variant={'subtitle1'}
                      color={BrandColors.Primary}
                    >
                      {capitalize(demoData.source)} to{' '}
                      {capitalize(demoData.target)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    key={`${demoData.source}-${demoData.target}-${ind}`}
                    style={{ flexDirection: 'column' }}
                  >
                    <Box>
                      {demoData.queries.length ? (
                        demoData.queries.map(
                          (query: QueryItem, ind2: number) => (
                            <Box position="relative" key={`content${ind2}`}>
                              <Typography
                                data-testid={`accordion-query-${ind}${ind2}`}
                                variant={'subtitle2'}
                                color={BrandColors.Primary}
                                pr={15}
                              >
                                <HighlightWord
                                  searchWords={[
                                    enteredSearchText.toLowerCase(),
                                  ]}
                                  autoEscape={true}
                                  textToHighlight={query?.description}
                                />
                              </Typography>
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: query?.description ? 30 : 0,
                                  right: 0,
                                  zIndex: 100,
                                }}
                              >
                                <Button
                                  data-testid={`execute-button-${ind}${ind2}`}
                                  variant="contained"
                                  size="small"
                                  startIcon={<ArrowRightIcon />}
                                  onClick={() =>
                                    executeQuery(
                                      query.sourceStmt,
                                      query.sourceStmt,
                                      demoData.source,
                                      demoData.target
                                    )
                                  }
                                >
                                  Execute
                                </Button>
                              </Box>
                              <Box>
                                <pre className="demo-pre-tag">
                                  <SyntaxHighlighter
                                    code={query?.sourceStmt}
                                    searchText={enteredSearchText.toLowerCase()}
                                    sx={{ maxHeight: 380 }}
                                  />
                                </pre>
                              </Box>
                            </Box>
                          )
                        )
                      ) : (
                        <Box
                          sx={{ color: BrandColors.Gray, textAlign: 'center' }}
                        >
                          No query found
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export function demoQueryLoader(): Promise<{ demoQueries: DemoQuery[] }> {
  const queries = getDemoQueries();
  return queries;
}
