import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { handlePageAnalytics } from 'Common/analytics';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { AppBaseProps, AppConfig } from 'Common/common-ui';
import theme from '../common/theme';
import { getAppConfig, skipRedirectUrls } from '../common/util';
import { LoadingIndicator } from '@toolkit/common';
import AppBaseInternal from './appBaseInternal';
import './styles.scss';

export const AppBase: React.FC<AppBaseProps & { children: any }> = (
  props: AppBaseProps & { children: any }
) => {
  const [toRender, setToRender] = React.useState<JSX.Element>(
    <LoadingIndicator fullScreen={true} title={'Loading application...'} />
  );
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const useAuth = (path: string) => !['/demo'].includes(path);
  handlePageAnalytics();

  React.useEffect(() => {
    (async () => {
      if (useAuth(location.pathname)) {
        try {
          const {
            auth0: { domain, clientId },
          }: AppConfig = await getAppConfig();
          const onRedirectCallback = (appState?: AppState): void => {
            navigate(appState?.returnTo || window.location.pathname, {
              replace: true,
            });
          };
          const authOptions: Auth0ProviderOptions = {
            domain: domain,
            clientId: clientId,
            authorizationParams: {
              redirect_uri: window.location.origin,
            },
            skipRedirectCallback: skipRedirectUrls.includes(
              window.location.pathname
            ),
            onRedirectCallback: onRedirectCallback,
            cacheLocation: 'localstorage',
            useRefreshTokens: true,
          };

          setToRender(
            <Auth0Provider {...authOptions}>{props.children}</Auth0Provider>
          );
        } catch (e) {
          console.error(e);
          setToRender(
            <Typography variant="h5">
              Failed to load application configuration
            </Typography>
          );
        }
      } else {
        setToRender(<>{props.children}</>);
      }
    })();
  }, [props.children, location]);

  return (
    <StyledEngineProvider injectFirst>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          classes={{
            anchorOriginBottomCenter: 'snackbar-bottom-center',
            anchorOriginTopRight: 'snackbar-top-right',
            root: 'snackbar-root',
          }}
        >
          <AppBaseInternal>{toRender}</AppBaseInternal>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
