import { ImageItem } from 'Common/interfaces';
import GraphImg from 'src/resources/ui-screenshots/advisor-tool/graph-cropped.png';
import DonutImg from 'src/resources/ui-screenshots/advisor-tool/both-donuts.png';
import PassFailImg from 'src/resources/ui-screenshots/advisor-tool/pass-fail-cropped.png';
import ReturnedRowsImg from 'src/resources/ui-screenshots/advisor-tool/rows-returned-cropped.png';

export const ImageArray: Array<ImageItem> = [
  {
    img: DonutImg,
    title: 'Check results breakdown',
    rows: 1,
    cols: 4,
    reportPath:
      '/toolkit/docs/advisor/advisor-report.html#/?tutorialStatus=running',
  },
  {
    img: PassFailImg,
    title: 'Pass fail table',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/advisor/advisor-report.html#/?tutorialStatus=running',
  },
  {
    img: GraphImg,
    title: 'Graph',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/advisor/advisor-report.html#/check-details/Warehouse%20credits%20by%20day#/?tutorialStatus=running',
  },
  {
    img: ReturnedRowsImg,
    title: 'Rows returned by query',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/advisor/advisor-report.html#/check-details/Warehouse%20credits%20by%20day#/?tutorialStatus=running',
  },
];
