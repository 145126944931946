import { Box } from '@mui/material';
import { AnalyticsLibraryProps } from 'Common/interfaces';
import React from 'react';

interface PowerBIProps {
  dashboard: AnalyticsLibraryProps;
}

const PowerBI: React.FC<PowerBIProps> = (props: PowerBIProps) => {
  const powerBIURL = 'https://app.powerbi.com/view' as string;
  return (
    <Box
      data-testid="power-bi-dashboard"
      sx={{
        m: '0 auto',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <iframe
        title="power_bi_vizualization"
        src={`${powerBIURL}?r=${props.dashboard.vizUrl}`}
        height="600"
        width="70%"
      />
    </Box>
  );
};

export default PowerBI;
