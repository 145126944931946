import * as Paths from '@toolkit/router/paths';
import { AppConfig } from './interfaces';

export const robotsTag: { type: string } = {
  get type() {
    return this._type;
  },
  set type(value: string) {
    this._type = value;
  },
};

const appConfig: { settings: AppConfig } = {
  get settings() {
    return this._settings;
  },
  set settings(value: AppConfig) {
    this._settings = value;
  },
};

export const getAppConfig = async <
  T extends AppConfig | AppConfig,
>(): Promise<T> => {
  if (appConfig.settings) return appConfig.settings as T;

  const response = await fetch('/config.json', {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  appConfig.settings = (await response.json()) as T;
  robotsTag.type =
    appConfig.settings.auth0.env !== 'production' ? 'noindex' : 'index';

  return appConfig.settings as T;
};

export const skipRedirectUrls: string[] = [Paths.SqlTranslation];
