import React from 'react';
import {
  AccountTreeOutlined,
  AutoFixHighOutlined,
  CodeOutlined,
  DoubleArrowOutlined,
  LockOutlined,
  MenuBookOutlined,
  PsychologyOutlined,
  SchoolOutlined,
  TrackChangesOutlined,
  UpdateOutlined,
} from '@mui/icons-material';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import * as Paths from '@toolkit/router/paths';
import { BrandColors } from 'Common/common-ui/common/theme';
import PageHeaderLayout from './PageHeaderLayout';
import TealLink from './TealLink';

const iconColor = BrandColors.Primary;
const iconFontSize = '60px';

export type PageHeaders = {
  type: string;
  avatar: any;
  header: string;
  subHeader?: string;
  description?: string | React.ReactNode;
};

const pageHeaders: PageHeaders[] = [
  {
    type: Paths.AdvisorTool,
    avatar: (
      <PsychologyOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Advisor Tool',
    subHeader: 'Optimize & Secure Snowflake',
    description:
      'Quickly and easily identify opportunities to improve the configuration, security, performance, and efficiency of your Snowflake environment.',
  },
  {
    type: Paths.AccessTool,
    avatar: <LockOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />,
    header: 'Access Tool',
    subHeader: 'Visualize Roles & Privileges ',
    description:
      'Powerful visualization and reporting that enables Snowflake data access, auditing, and troubleshooting.',
  },
  {
    type: Paths.DataGenerationTool,
    avatar: (
      <AutoFixHighOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Data Generation Tool',
    subHeader: 'Ultra-Realistic Relational Synthetic Data Generator',
    description:
      'Generate realistic synthetic data for testing, development, and demonstrations.',
  },
  {
    type: Paths.SqlTranslation,
    avatar: <CodeOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />,
    header: 'SQL Translation - SQLMorph',
    subHeader: 'Translate SQL Instantly',
    description:
      'Instantly translate SQL from one language to another, eliminating a usually time-consuming, error-prone, and highly manual process.',
  },
  {
    type: Paths.PlatformMonitoring,
    avatar: (
      <TrackChangesOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Platform Monitoring - CDOpsMon',
    subHeader: 'Simple Monitoring for your Modern Data Stack',
    description:
      'Easily collect, evaluate, and alert on the state of your data platform to ensure that everything continues to run as it should.',
  },
  {
    type: Paths.DataSourceTool,
    avatar: (
      <DoubleArrowOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Data Source Tool',
    subHeader: 'Analyze Data Source Metadata',
    description:
      'A multipurpose tool that collects, compares, analyzes, and acts on data source metadata and profile metrics.',
  },
  {
    type: Paths.ProvisionTool,
    avatar: (
      <AccountTreeOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Provision Tool',
    subHeader: 'Manage Snowflake Resources',
    description:
      'Manage Snowflake resource lifecycles - creating, updating, and destroying - with a templated approach to provide a well-structured information architecture.',
  },
  {
    type: Paths.SampleTraining,
    avatar: (
      <SchoolOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Training',
    subHeader: 'Empower your Team',
    description: (
      <>
        Empower your team to build successful data solutions with our data coach
        courses. Explore our free sample courses on this page or learn more
        about our training and enablement services{' '}
        <TealLink rel="noopener" target="_blank" to={Paths.TrainingEnablement}>
          here
        </TealLink>
        .
      </>
    ),
  },
  {
    type: Paths.Support,
    avatar: (
      <QuestionMarkOutlinedIcon
        sx={{ color: iconColor, fontSize: iconFontSize }}
      />
    ),
    header: 'Support',
    subHeader: 'We Are Always Here to Help',
  },
  {
    type: Paths.About,
    avatar: (
      <BookOutlinedIcon sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'About phData',
    subHeader: 'The Client-first Modern Data Stack Approach',
  },
  {
    type: Paths.ToolAccess,
    avatar: (
      <DownloadOutlinedIcon sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Tool Access',
    subHeader: 'Download Tools and Generate Auth Tokens',
  },
  {
    type: Paths.Updates,
    avatar: (
      <UpdateOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Updates',
    subHeader: 'Updates and release notes',
  },
  {
    type: Paths.Documentation,
    avatar: (
      <MenuBookOutlined sx={{ color: iconColor, fontSize: iconFontSize }} />
    ),
    header: 'Documentation',
    subHeader: 'Learn more about our tools',
  },
];

export const PageHeader: React.FC<{ type: any }> = (props: {
  type: string;
}) => {
  return (
    <>
      {pageHeaders
        .filter((f: PageHeaders) => f.type === props.type)
        .map((t: PageHeaders) => (
          <PageHeaderLayout key={`page-header-${t.header}`} header={t} />
        ))}
    </>
  );
};
