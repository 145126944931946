import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Hits, useInstantSearch, Configure } from 'react-instantsearch';
import { NoResultsBoundary, NoResults } from './NoSearchResult';
import { LoadingIndicator } from '@toolkit/common';
import SearchContent from './SearchContent';
import 'instantsearch.css/themes/satellite.css';

interface Props {
  handleClose: () => void;
}

export default function SearchLayout(props: Props): React.ReactElement {
  const { status } = useInstantSearch();

  function Hit({ hit, sendEvent }: any): React.ReactElement {
    return (
      <SearchContent
        hit={hit}
        sendEvent={sendEvent}
        handleClose={props.handleClose}
      />
    );
  }

  return (
    <Box
      overflow={'auto'}
      height={600}
      p={0.5}
      sx={{ '& li': { display: 'flex', my: 1, p: 0 } }}
    >
      {(status === 'loading' || status === 'stalled') && (
        <Box zIndex={100} position="absolute" left="45%" top="45%">
          <LoadingIndicator />
          <Typography mt={1} variant="h1">
            Loading ...
          </Typography>
        </Box>
      )}
      <NoResultsBoundary fallback={<NoResults />}>
        <>
          <Hits hitComponent={Hit} />
          <Configure
            attributesToSnippet={['content:20']}
            hitsPerPage={20}
            ignorePlurals
          />
        </>
      </NoResultsBoundary>
    </Box>
  );
}
