import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Tooltip, Typography } from '@mui/material';
import SyntaxHighlighter from 'Common/components/SyntaxHighlighter';
import { CopyText } from 'Common/enums';
import CopyToClipboard from 'copy-to-clipboard';
import { Language } from 'prism-react-renderer';
import * as React from 'react';
import { useProvisionDemoContext } from '../contexts/reducer';

interface Props {
  language: Language;
  code: string;
  sectionTitle: string;
  copyText: CopyText;
  setCopyText: (CopyText) => void;
}

export default function CodeBlock(props: Props) {
  const {
    state: { requestType },
  } = useProvisionDemoContext();

  React.useEffect(() => {
    props.setCopyText(CopyText.CopyToClipBoard);
  }, [requestType]);

  function handleCopy(text: string): void {
    CopyToClipboard(text);
    props.setCopyText(CopyText.Copied);
  }

  return (
    <Box height={'90%'} component={'div'} position={'relative'} ml={1}>
      <Typography variant="h3">{props.sectionTitle}</Typography>
      <SyntaxHighlighter
        code={props.code}
        language={props.language}
        formatCode={true}
        sx={{
          marginTop: 8,
          height: '100%',
          overflowY: 'auto',
        }}
      />
      <Box
        position={'absolute'}
        top={50}
        right={10}
        sx={{ cursor: 'pointer' }}
        onClick={() => handleCopy(props.code)}
      >
        <Tooltip title={props.copyText} placement="left-end" arrow>
          <ContentCopyIcon
            color={props.copyText === CopyText.Copied ? 'success' : 'primary'}
          />
        </Tooltip>
      </Box>
    </Box>
  );
}
