import { ProvisionForm, RequestType } from './domain';

export function generateGroupFileContent(fields, groupType) {
  const group = {
    template: {},
    defaults: {},
    entries: [],
  };

  group.template['path'] = `${groupType}.yaml`;
  switch (groupType) {
    case 'project':
      group.defaults = {
        warehouse_size: fields['warehouseSize'],
        auto_suspend: parseInt(fields['autoSuspend']) || 600,
      };
      const projectMember = {
        project_name: fields['projectName'],
        env: fields['environmentName'],
      };
      group.entries.push(projectMember);
      break;
    case 'user':
      group.defaults = {
        rsa_public_key: fields['rsaPublicKey'],
      };
      const userMember = {
        username: fields['userName'],
        email: fields['email'],
        warehouse_name: fields['warehouseName'],
      };
      group.entries.push(userMember);
      break;
    case 'warehouse':
      group.defaults = {
        warehouse_size: fields['warehouseSize'],
        auto_suspend: parseInt(fields['autoSuspend']) || 600,
      };
      const warehouseMember = {
        name: fields['warehouseName'],
        size: fields['warehouseSize'],
      };
      group.entries.push(warehouseMember);
      break;
    case 'project-member':
      const projectAccessMember = {
        project_name: fields['projectName'],
        env: fields['environmentName'],
        username: fields['userName'],
        access: fields['access'],
      };
      group.entries.push(projectAccessMember);
      break;
    default:
      const warehouseAccessMember = {
        warehouse_name: fields['warehouseName'],
        username: fields['userName'],
      };
      group.entries.push(warehouseAccessMember);
      break;
  }

  if (group.entries['env'] === 'None') {
    delete group.entries['env'];
  }

  return group;
}

export const PROVISION_FORMS: { [key in RequestType]: ProvisionForm } = {
  'Create Project': {
    name: 'Create Project',
    group: 'project',
    fields: [
      {
        label: 'Project Name',
        id: 'project_name',
        name: 'projectName',
        type: 'text',
        required: true,
      },
      {
        label: 'Environment Name',
        id: 'environment_name',
        name: 'environmentName',
        value: ['Dev', 'Test', 'Prod'],
        type: 'picker',
        required: true,
      },
      {
        label: 'Warehouse Size',
        id: 'warehouse_size',
        name: 'warehouseSize',
        value: ['X-Small', 'Small', 'Medium', 'Large', 'X-Large'],
        type: 'picker',
      },
      {
        label: 'Auto Suspend',
        id: 'auto_suspend',
        name: 'autoSuspend',
        value: 600,
        type: 'number',
      },
    ],
  },
  'Create User': {
    name: 'Create User',
    group: 'user',
    fields: [
      {
        label: 'User Name',
        id: 'username',
        name: 'userName',
        type: 'text',
        required: true,
      },
      {
        label: 'Email',
        id: 'email',
        name: 'email',
        type: 'email',
        required: true,
      },
      {
        label: 'Warehouse Name',
        id: 'warehouse_name',
        name: 'warehouseName',
        type: 'text',
      },
    ],
  },
  'Project Access': {
    name: 'Project Access',
    group: 'project-member',
    fields: [
      {
        label: 'User Name',
        id: 'username',
        name: 'userName',
        type: 'text',
        required: true,
      },
      {
        label: 'Project Name',
        id: 'project_name',
        name: 'projectName',
        type: 'text',
        required: true,
      },
      {
        label: 'Environment Name',
        id: 'environment_name',
        name: 'environmentName',
        value: ['Dev', 'Test', 'Prod'],
        type: 'picker',
        required: true,
      },
      {
        label: 'Access',
        id: 'access',
        name: 'access',
        value: ['RW', 'RO'],
        type: 'picker',
        required: true,
      },
    ],
  },
  'Create Warehouse': {
    name: 'Create Warehouse',
    group: 'warehouse',
    fields: [
      {
        label: 'Warehouse Name',
        id: 'warehouse_name',
        name: 'warehouseName',
        type: 'text',
        required: true,
      },
      {
        label: 'Warehouse Size',
        id: 'warehouse_size',
        name: 'warehouseSize',
        value: ['X-Small', 'Small', 'Medium', 'Large', 'X-Large'],
        type: 'picker',
      },
      {
        label: 'Auto Suspend',
        id: 'auto_suspend',
        name: 'autoSuspend',
        value: 600,
        type: 'number',
      },
    ],
  },
  'Warehouse Access': {
    name: 'Warehouse Access',
    group: 'warehouse-member',
    fields: [
      {
        label: 'Warehouse Name',
        id: 'warehouse_name',
        name: 'warehouseName',
        type: 'text',
        required: true,
      },
      {
        label: 'User Name',
        id: 'username',
        name: 'userName',
        type: 'text',
        required: true,
      },
    ],
  },
};
