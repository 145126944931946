import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { BrandColors } from 'Common/common-ui/common/theme';
import React from 'react';

export const Footer = () => {
  return (
    <Box
      component={'footer'}
      sx={{
        zIndex: 9000,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: { xs: 50, sm: 30 },
        backgroundColor: `${BrandColors.Primary}`,
        color: `${BrandColors.Gray}`,
      }}
    >
      <Typography
        display="flex"
        justifyContent="center"
        sx={{ flexFlow: 'row wrap' }}
        data-testid="footer-content"
        component={'div'}
      >
        <Box sx={{ color: BrandColors.Secondary }} display={'inline-flex'}>
          ©&nbsp;{new Date().getFullYear()}&nbsp;
        </Box>
        <Link
          href="https://phdata.io"
          target="_blank"
          rel="noopener noreferrer"
          color={BrandColors.Secondary}
        >
          phData
        </Link>
        <Box sx={{ color: BrandColors.Secondary }} display={'inline-flex'}>
          &nbsp;|&nbsp;
        </Box>
        <Link
          href="https://www.phdata.io/eula/"
          target="_blank"
          rel="noopener noreferrer"
          color={BrandColors.Secondary}
        >
          EULA
        </Link>
        <Box sx={{ color: BrandColors.Secondary }} display={'inline-flex'}>
          &nbsp;|&nbsp;
        </Box>
        <Link
          href="https://www.phdata.io/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          color={BrandColors.Secondary}
        >
          Privacy Policy
        </Link>
        <Box sx={{ color: BrandColors.Secondary }} component={'span'}>
          &nbsp;|&nbsp;
        </Box>
        <Link
          href={'https://www.phdata.io/legal/aup/'}
          target="_blank"
          rel="noopener noreferrer"
          color={BrandColors.Secondary}
        >
          Acceptable Use Policy
        </Link>
        <Box component={'span'}>&nbsp;|&nbsp;</Box>
        <Link
          href={
            'https://www.phdata.io/legal/security-compliance-certifications/'
          }
          target="_blank"
          rel="noopener noreferrer"
          color={BrandColors.Secondary}
        >
          Security
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
