import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { CSSProperties } from '@mui/styled-engine';
import React from 'react';

export interface LoadingIndicatorProps {
  fullScreen?: boolean;
  title?: string;
  sx?: CSSProperties;
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  return (
    <Box
      sx={
        props.sx
          ? props.sx
          : {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: props.fullScreen ? '80vh' : 'inherit',
              flexDirection: 'column',
              width: '100% !important',
            }
      }
      aria-busy="true"
      role="alert"
    >
      {props.fullScreen && props.title?.length && (
        <>
          <Typography variant="h6">{props.title}</Typography>
          <br />
        </>
      )}
      <CircularProgress
        sx={props.sx?.position ? { position: 'absolute', left: '45%' } : {}}
        color="primary"
        disableShrink
      />
    </Box>
  );
}
