import * as React from 'react';
import { MenuBookOutlined } from '@mui/icons-material';
import Container from '@mui/material/Container';
import * as Paths from '@toolkit/router/paths';
import PageNotFound from 'Common/components/pageNotFound';
import { getAsciidoc } from 'Common/services/api';
import { delay } from 'lodash';
import { Location, useLocation } from 'react-router-dom';
import { RELEASE_NOTES } from './ReleaseNotesStyles';
import ParseAsciidoc from 'Common/components/ParseAsciidoc';
import RenderAsciidoc from 'Common/components/RenderAsciidoc';

const ReleaseNoteWrapper: React.FC = () => {
  const tocRefs = React.useRef({});
  const location: Location = useLocation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [template, setTemplate] = React.useState<React.ReactNode | null>(null);
  const query = new URLSearchParams(location.search);
  const year = query.get('year');

  React.useEffect(() => {
    setIsLoading(true);
    const docYear = RELEASE_NOTES.includes(year) ? `-${year}` : '';
    const docName = `${location.pathname}/release-notes${docYear}`;
    handleAsciidoc(docName);
  }, [location.pathname, year]);

  React.useEffect(() => {
    let selectedTOC = null;
    const route: string = location.hash.replace('#', '');
    if (template) {
      if (route && tocRefs[route]) {
        selectedTOC = route;
      } else {
        selectedTOC = Object.keys(tocRefs?.current)[0];
      }
      if (selectedTOC) {
        delay(() => {
          tocRefs[selectedTOC]?.scrollIntoView({
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [location.hash, template]);

  async function handleAsciidoc(docName: string) {
    setTemplate(null);
    const response = await getAsciidoc(`${docName}.html`);
    if (response.status === 404) {
      setTemplate(pageNotFound());
    } else {
      setTemplate(<ParseAsciidoc text={response} ref={tocRefs} />);
    }
    setIsLoading(false);
  }

  function pageNotFound(): React.ReactElement {
    return (
      <PageNotFound
        page="updates"
        path={Paths.Updates}
        icon={<MenuBookOutlined />}
      />
    );
  }

  return (
    <Container maxWidth={false}>
      <RenderAsciidoc isLoading={isLoading} template={template} />
    </Container>
  );
};

export default ReleaseNoteWrapper;
