import { Action } from 'Common/common-ui';
import { createContext, useReducer } from 'react';
import {
  DropdownOption,
  HighlightContent,
  Response,
} from '../constants/interfaces';
import { getReadableName, sortArrObj } from '../constants/utilities';
import { localStorageService } from '../services/localStorage';
import { Actions } from './actions';

export interface SqlMorphState {
  responses: Response[];
  selectedSource: DropdownOption;
  selectedTarget: DropdownOption;
  sourceTargetList: { [key: string]: string[] };
  showRateLimitAlert: boolean;
  editorContents: string[];
  loadSpinner: boolean;
  sources: DropdownOption[];
  targets: DropdownOption[];
  highlightContents: HighlightContent[];
  fileName: string;
  executeDemoQuery: boolean;
  demoQuery: string;
  allowCommadExc: boolean;
}

export const HIDE_WARNING_PROPERTY = 'size_warning_hide';

export const initialState: SqlMorphState = {
  responses: [],
  selectedSource: null,
  selectedTarget: null,
  sourceTargetList: localStorageService.sourceTargetList,
  showRateLimitAlert: false,
  editorContents: [],
  loadSpinner: false,
  sources: [],
  targets: [],
  highlightContents: [],
  fileName: null,
  executeDemoQuery: false,
  demoQuery: null,
  allowCommadExc: false,
};

const sqlMorphReducer = (
  state: SqlMorphState,
  action: Action
): SqlMorphState => {
  switch (action.type) {
    case Actions.ALLOW_CMD_EXEC:
      return { ...state, allowCommadExc: action.payload };
    case Actions.EXECUTE_DEMO_QUERY:
      return { ...state, executeDemoQuery: action.payload };
    case Actions.SOURCE_TARGET_LIST:
      localStorageService.sourceTargetList = action.payload;
      return { ...state, sourceTargetList: action.payload };
    case Actions.SELECTED_SOURCE:
      localStorageService.source = action.payload;
      const sourceTargetMapping = localStorageService.sourceTargetList;
      const targetArr = sourceTargetMapping[action.payload.value];
      const targets =
        targetArr &&
        targetArr.map((val: string) => {
          return { label: getReadableName(val), value: val };
        });
      const sortTargets = (targets || []) && targets.sort(sortArrObj('value'));
      const _state = { ...state };
      const oldTarget = _state.selectedTarget;
      const selectedTarget =
        sortTargets.find(
          (x: DropdownOption) => x.value === (oldTarget && oldTarget.value)
        ) ||
        sortTargets.find(
          (x: DropdownOption) =>
            x.value ===
            (localStorageService.target && localStorageService.target.value)
        ) ||
        // snowflake should be the top of the list in targets if there are multiple
        sortTargets.find(
          (x: DropdownOption) => x.value.toLowerCase() === 'snowflake'
        ) ||
        sortTargets[0];
      localStorageService.target = selectedTarget;
      return {
        ...state,
        selectedSource: action.payload,
        targets: sortTargets,
        selectedTarget,
      };
    case Actions.SELECTED_TARGET:
      localStorageService.target = action.payload;
      return {
        ...state,
        selectedTarget: action.payload,
      };
    case Actions.EDITOR_RESPONSES:
      return {
        ...state,
        responses: action.payload,
      };
    case Actions.RATE_LIMIT_ALERT:
      return {
        ...state,
        showRateLimitAlert: action.payload,
      };
    case Actions.EDITOR_CONTENTS:
      return {
        ...state,
        editorContents: [...action.payload],
      };
    case Actions.LOAD_SPINNER:
      return {
        ...state,
        loadSpinner: action.payload,
      };
    case Actions.SOURCES:
      const sources = action.payload;
      // oracle should be the top of the list in sources since it's the
      // most full featured source we have
      const oracle = sources.find((source) => source.value === 'oracle');
      if (oracle) {
        const oracleIdx = sources.indexOf(oracle);
        sources.splice(oracleIdx, 1);
        sources.push({});
        for (let i = sources.length - 1; i > 0; i--) {
          sources[i] = sources[i - 1];
        }
        sources[0] = oracle;
      }
      return { ...state, sources: sources };
    case Actions.TARGETS:
      localStorageService.target = action.payload;
      return {
        ...state,
        targets: action.payload,
      };
    case Actions.HIGHLIGHT_CONTENTS:
      return {
        ...state,
        highlightContents: action.payload,
      };
    case Actions.FILE_NAME:
      return {
        ...state,
        fileName: action.payload,
      };
    case Actions.DEMO_QUERY:
      return {
        ...state,
        demoQuery: action.payload,
      };
    default:
      return initialState;
  }
};

type SqlMorphContextDefinition = {
  state: SqlMorphState;
  dispatch: React.Dispatch<Action>;
};

export const SqlMorphContext = createContext<SqlMorphContextDefinition>({
  state: initialState,
  dispatch: () => null,
});

export const useSqlMorphReducer = () =>
  useReducer(sqlMorphReducer, initialState);
