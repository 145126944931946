import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import * as React from 'react';
import { PageHeader } from 'Common/components/PageHeader';
import { useLocation } from 'react-router-dom';
import * as Paths from '@toolkit/router/paths';

export interface ResourcePageWrapperProps {
  pageHeaderPath: string;
  child: JSX.Element;
  dataTestId: string;
}

export default function ResourcePageWrapper(props: ResourcePageWrapperProps) {
  const { pageHeaderPath, child, dataTestId } = props;
  const location = useLocation();
  // TODO: once we have more courses we will need to create better condition to
  // show or hide page header
  return (
    <Container maxWidth={false} data-testid={dataTestId} disableGutters={true}>
      <Grid sx={{ height: '100%', width: '100%' }} container mb={3}>
        {location.pathname !== `${Paths.SampleTraining}/sql-basics` && (
          <Grid size={{ xs: 12 }}>
            <PageHeader type={pageHeaderPath} />
          </Grid>
        )}
        <Grid
          sx={{
            px:
              location.pathname !== `${Paths.SampleTraining}/sql-basics`
                ? 5
                : 0,
            py:
              location.pathname !== `${Paths.SampleTraining}/sql-basics`
                ? 6
                : 0,
          }}
          size={{ xs: 12 }}
        >
          {child}
        </Grid>
      </Grid>
    </Container>
  );
}
