import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as Paths from '@toolkit/router/paths';
import { BrandColors } from 'Common/common-ui/common/theme';
import { CardList } from 'Common/components/CardList';
import { CardListProps } from 'Common/interfaces';
import { appList as appData } from 'Common/util';
import * as React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import TealButton from 'Common/components/TealButton';
import TealLink from 'Common/components/TealLink';
import Header from 'src/resources/images/toolkit-home-header.jpg';

const Home: React.FC = () => {
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [appList] = React.useState<CardListProps[]>(appData);
  const [email, setEmail] = React.useState<string>('');
  const signUpOptions = {
    authorizationParams: { mode: 'signUp', login_hint: email },
  } as RedirectLoginOptions;

  React.useEffect(() => {
    const currentPath: string = window.location.pathname;
    if (currentPath !== Paths.Home) {
      navigate(currentPath);
    }
  }, [location.pathname]);

  async function handleSignIn(e: React.MouseEvent) {
    e.preventDefault();
    const options: RedirectLoginOptions = {
      appState: { returnTo: location.pathname },
    };
    await loginWithRedirect(options);
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.currentTarget.value);
  }

  function handleOnKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.code === 'Enter' && email.trim().length) {
      loginWithRedirect(signUpOptions);
    }
  }

  function handleCardClick(card: CardListProps): void {
    navigate(card.link);
  }

  return (
    <Container
      sx={{
        padding: '0px !important',
        overflow: 'scroll',
        height: '100%',
        width: '100% !important',
        margin: '0px !important',
      }}
      id={'homepage-header-container'}
      maxWidth={false}
    >
      <div
        style={{
          backgroundImage: `url(${Header})`,
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          alignContent: 'center',
          height: '400px',
          width: '100% !important',
          margin: '0px !important',
        }}
      >
        <Box sx={{ pl: 7 }}>
          <Typography
            fontWeight={700}
            color={BrandColors.Mint}
            fontSize={'2.25em'}
          >
            phData Toolkit
          </Typography>
          <Typography
            fontWeight={'bold'}
            color={BrandColors.White}
            fontSize={'30px'}
          >
            Accelerate Your Data Journey
          </Typography>
          {!isAuthenticated && (
            <Stack sx={{ pt: 2 }} spacing={2}>
              <Typography color={BrandColors.White} fontSize={18}>
                Create a free Toolkit account
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size="medium"
                  inputProps={{ 'data-testid': 'signup-input-field' }}
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    width: { xs: 180, md: 300 },
                    mr: { xs: 1, md: 2 },
                    borderRadius: '4px 4px 0px 0px',
                  }}
                  label="Email Address"
                  variant="filled"
                  value={email}
                  onChange={handleOnChange}
                  onKeyPress={handleOnKeyPress}
                />
                <TealButton
                  variant="contained"
                  data-testid="signup-btn"
                  sx={{
                    height: 55,
                    mr: 1,
                  }}
                  size="large"
                  onClick={() => loginWithRedirect(signUpOptions)}
                >
                  Sign Up
                </TealButton>
              </Box>

              <Typography color={BrandColors.White} fontSize={18}>
                Already have an account?{' '}
                <TealLink
                  onClick={handleSignIn}
                  sx={{ color: BrandColors.Mint }}
                >
                  Sign In
                </TealLink>
              </Typography>
            </Stack>
          )}
        </Box>
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 7,
        }}
      >
        <Typography sx={{ width: '70%', textAlign: 'center', fontSize: 16 }}>
          The phData Toolkit is a collection of high-quality data applications
          to help you{' '}
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bolder',
              color: BrandColors.Teal,
              display: 'inline',
            }}
          >
            migrate
          </Typography>
          ,{' '}
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bolder',
              color: BrandColors.Teal,
              display: 'inline',
            }}
          >
            validate
          </Typography>
          ,{' '}
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bolder',
              color: BrandColors.Teal,
              display: 'inline',
            }}
          >
            optimize
          </Typography>
          , and{' '}
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bolder',
              color: BrandColors.Teal,
              display: 'inline',
            }}
          >
            secure
          </Typography>{' '}
          your data.
        </Typography>
      </Box>
      <Box sx={{ p: 5, backgroundColor: BrandColors.LightGray }}>
        <CardList
          handleCardClick={handleCardClick}
          page={Paths.Home}
          cards={appList}
        />
      </Box>
    </Container>
  );
};

export default Home;
