import * as React from 'react';
import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useInstantSearch, Snippet } from 'react-instantsearch';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { BrandColors } from 'Common/common-ui/common/theme';
import { startCase } from 'lodash';
import TooltipContent from './TooltipContent';
import 'instantsearch.css/themes/satellite.css';

interface Props {
  hit: any;
  sendEvent: any;
  handleClose: () => void;
}

export default function SearchContent({
  hit,
  sendEvent,
  handleClose,
}: Props): React.ReactElement {
  const navigate: NavigateFunction = useNavigate();
  const { status } = useInstantSearch();
  const isLoading = status === 'loading' || status === 'stalled';
  const headers: { [key: string]: string } = removeEmptyAttr({
    h1: hit.h1,
    h2: hit.h2,
    h3: hit.h3,
    h4: hit.h4,
    h5: hit.h5,
    h6: hit.h6,
  });
  const headerKeys: string[] = Object.keys(headers);

  function handleLink(to: string): void {
    handleClose();
    navigate(to);
  }

  function createBreadCrumb(url: string) {
    return url
      .slice(1)
      .split('#')[0]
      .replace(/-/, ' ')
      .split('/')
      .map((s) => startCase(s))
      .join(' / ');
  }

  function removeEmptyAttr(obj: { [key: string]: string }) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  }

  return (
    <Link
      onClick={() => {
        handleLink(hit.url);
        sendEvent('click', hit, 'Hit Clicked');
      }}
      underline="none"
      sx={{
        width: '100%',
        cursor: 'pointer',
        '&:hover': { bgcolor: BrandColors.BlueGray },
        color: isLoading ? BrandColors.Gray : 'inherit',
      }}
    >
      <Box
        p={3}
        sx={{
          '& .ais-Highlight-highlighted, .ais-Snippet-highlighted': {
            color: BrandColors.Teal,
            backgroundColor: BrandColors.LightGray,
          },
        }}
      >
        <Typography variant="subtitle2" color="GrayText">
          {createBreadCrumb(hit.url)}
        </Typography>
        <Typography variant="subtitle1" fontSize={18} my={1}>
          <TooltipContent hit={hit} headers={[...headerKeys]} />
        </Typography>
        <Typography variant="body1">
          <Snippet hit={hit} attribute="content" />
        </Typography>
      </Box>
    </Link>
  );
}
