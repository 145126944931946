import * as Paths from '@toolkit/router/paths';
import parse from 'html-react-parser';
import * as React from 'react';
import { Location, useLocation } from 'react-router-dom';
import ToolStyles from 'src/scripts/content/tools/ToolStyles';
import ReleaseNotesStyles from 'src/scripts/content/general/updates/ReleaseNotesStyles';
import DocumentStyles from 'src/scripts/content/resources/documentation/DocumentStyles';

type Props = {
  text: string;
  openImageInDialog?: (
    link: { src: string; alt: string },
    bool: boolean
  ) => void;
  ref: any;
};

const ParseAsciidoc: React.FC<Props> = React.forwardRef((props: Props, ref) => {
  const location: Location = useLocation();

  const options = {
    //TODO - Simplify styles by moving all common styles out.
    replace: (domNode) => {
      if (location.pathname.includes(Paths.Tools)) {
        return ToolStyles(domNode, options, ref);
      } else if (location.pathname === Paths.Updates) {
        return ReleaseNotesStyles(domNode, options, ref);
      } else {
        return DocumentStyles(domNode, ref, props.openImageInDialog);
      }
    },
  };

  return <>{parse(props.text, options)}</>;
});

export default ParseAsciidoc;
