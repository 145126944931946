import { Alert, Box, Button, Checkbox, Grid } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';
import { getRgba } from 'Common/util';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TimePicker from 'react-time-picker';
import logo from '../../assets/phData_white_rgb.png';
import '../synchronized-refresh.scss';

export interface ConfigDialogProps {
  saveInProgress: boolean;
  getHoursMinutesSeconds: (params: number) => { hours; minutes; seconds };
}

const ConfigDialog: React.FC<ConfigDialogProps> = (
  props: ConfigDialogProps
) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [selectedStartTime, setSelectedStartTime] = useState<string>(
    moment().format('HH:mm')
  );
  const [startFromSelectedTime, setStartFromSelectedTime] =
    useState<boolean>(false);
  const [timeInput, setTimeInput] = useState<{
    hours: string;
    minutes: string;
    seconds: string;
  }>({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });
  const tableau = window.tableau;
  const minRefreshIntervalSeconds = 300;

  useEffect(() => {
    window.tableau?.extensions?.initializeDialogAsync().then(() => {
      // tableau.extensions.settings.erase('prevRefresh');
      // tableau.extensions.settings.erase('nextRefresh');
      // tableau.extensions.settings.erase('refreshInterval');
      // tableau.extensions.settings.saveAsync();

      // if we have already selected a start time populate state value
      const startTime = tableau?.extensions?.settings?.get('startTime');
      if (startTime) {
        setSelectedStartTime(startTime);
      }

      //   see if value is already set for the next refresh
      //   const prev = tableau.extensions.settings.get('prevRefresh');
      //   const next = tableau.extensions.settings.get('nextRefresh');
      const interval = tableau?.extensions?.settings?.get('refreshInterval')
        ? tableau?.extensions?.settings?.get('refreshInterval')
        : '0';
      let { hours, minutes, seconds } = props.getHoursMinutesSeconds(
        parseInt(interval)
      );
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      setTimeInput({ hours, minutes, seconds });
    });
  }, []);

  function handleCloseDialog(): void {
    tableau?.extensions?.ui?.closeDialog();
  }

  useEffect(() => {
    const close = (e) => {
      // handle press escape key
      if (e.keyCode === 27) {
        handleCloseDialog();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [timeInput, selectedStartTime]);

  const handleTimeChange = (unit, event) => {
    const input = event.target.value;

    if (isNaN(input)) {
      return;
    }

    // handle backspace
    if (!input && timeInput[unit].charAt(0) === '0') {
      setTimeInput((prevTime) => {
        const newTime = JSON.parse(JSON.stringify(prevTime));
        newTime[unit] = '00';
        return newTime;
      });
      return;
    }

    setTimeInput((prevTime) => {
      const newTime = JSON.parse(JSON.stringify(prevTime));
      newTime[unit] = input.length < 2 ? '0' + input : input;
      newTime[unit] =
        newTime[unit].length > 2 ? newTime[unit].substring(1) : newTime[unit];
      // if they set the minutes or seconds too high set it to the max value which is 60
      newTime[unit] =
        (unit === 'minutes' || unit === 'seconds') && newTime[unit] > 59
          ? 59
          : newTime[unit];
      return newTime;
    });
  };

  // if the input refresh interval is 60 seconds or more, save settings and close dialog
  function handleClickSave(): void {
    const refreshIntervalInSeconds = getSecondsFromTimeInput();
    const secondsUntilNextRefresh = startFromSelectedTime
      ? getSecondsFromSelectedTime()
      : refreshIntervalInSeconds;
    if (refreshIntervalInSeconds < minRefreshIntervalSeconds) {
      setShowWarning(true);
    } else {
      updateSettingsAndCloseDialog(
        secondsUntilNextRefresh,
        refreshIntervalInSeconds
      );
    }
  }

  // returns the total number of seconds from now until the selected start time
  function getSecondsFromSelectedTime(): number {
    const [startHour, startMinute] = selectedStartTime.split(':');
    const [currHour, currMinute] = moment().format('HH:mm').split(':');
    let hoursUntil =
      parseInt(startHour) - parseInt(currHour) < 0
        ? 24 + (parseInt(startHour) - parseInt(currHour))
        : parseInt(startHour) - parseInt(currHour);
    const minutesUntil =
      parseInt(startMinute) - parseInt(currMinute) < 0
        ? 60 + (parseInt(startMinute) - parseInt(currMinute))
        : parseInt(startMinute) - parseInt(currMinute);
    hoursUntil =
      parseInt(startMinute) - parseInt(currMinute) < 0
        ? hoursUntil - 1
        : hoursUntil;
    hoursUntil =
      parseInt(startMinute) - parseInt(currMinute) < 0 &&
      parseInt(startHour) - parseInt(currHour) === 0
        ? 24 + hoursUntil
        : hoursUntil;
    let totalSeconds = hoursUntil * 3600;
    totalSeconds += minutesUntil * 60;
    return totalSeconds;
  }

  // returns total number of seconds from hours minutes and seconds input
  function getSecondsFromTimeInput(): number {
    let totalSeconds = parseInt(timeInput.hours) * 3600;
    totalSeconds += parseInt(timeInput.minutes) * 60;
    totalSeconds += parseInt(timeInput.seconds);
    return totalSeconds;
  }

  // save settings to tableau dashboard and close dialog
  function updateSettingsAndCloseDialog(
    secondsUntilNextRefresh: number,
    refreshInterval: number
  ): void {
    if (startFromSelectedTime) {
      tableau?.extensions?.settings?.set('startTime', selectedStartTime);
    }
    const n = Date.now() + secondsUntilNextRefresh * 1000;
    tableau?.extensions?.settings?.set('nextRefresh', n.toString());
    tableau?.extensions?.settings?.set(
      'refreshInterval',
      refreshInterval.toString()
    );
    tableau?.extensions?.settings?.saveAsync().then(() => {
      handleCloseDialog();
    });
  }

  return (
    <Box className="config-wrapper">
      <Grid container direction="column" sx={{ marginBottom: '30px' }}>
        <Grid
          item
          sx={{ borderRadius: '5px' }}
          className="config-logo-header-wrapper"
        >
          <Box sx={{ paddingLeft: 1, w: 1 }} className="config-logo-header">
            <img alt="phData logo" src={logo} style={{ height: '100%' }} />
          </Box>
        </Grid>
        <Grid item>
          <div className="config-title config-title-main">
            Synchronized Refresh Configuration
          </div>
        </Grid>
        <Grid item className="config-body-text">
          Choose a time interval and (optionally) a starting time and all of the
          data sources associated with this dashboard will be refreshed at the
          given interval. This extension will only refresh data sources with
          live connections.{' '}
        </Grid>
      </Grid>
      <Grid container direction="row" className="config-section-container">
        <Grid item xs={3}>
          <div className="config-title">Interval</div>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignContent: 'center',
            }}
          >
            <div>
              <Box sx={{ display: 'flex', alignContent: 'center' }}>
                <input
                  className="time-input"
                  type="text"
                  placeholder={timeInput.hours}
                  value={timeInput.hours}
                  onFocus={() => setShowWarning(false)}
                  onChange={(e) => handleTimeChange('hours', e)}
                ></input>
                <span className="colon-span">:</span>
              </Box>
              <Box sx={{ marginY: 1 }} className="config-body-text">
                hours
              </Box>
            </div>
            <div>
              <Box sx={{ display: 'flex', alignContent: 'center' }}>
                <input
                  className="time-input"
                  type="text"
                  onFocus={() => setShowWarning(false)}
                  placeholder={timeInput.minutes}
                  value={timeInput.minutes}
                  onChange={(e) => handleTimeChange('minutes', e)}
                ></input>
                <span className="colon-span">:</span>
              </Box>
              <Box sx={{ marginY: 1 }} className="config-body-text">
                minutes
              </Box>
            </div>
            <div className="">
              <input
                className="time-input"
                type="text"
                placeholder={timeInput.seconds}
                value={timeInput.seconds}
                onFocus={() => setShowWarning(false)}
                onChange={(e) => handleTimeChange('seconds', e)}
              ></input>
              <Box sx={{ marginY: 1 }} className="config-body-text">
                seconds
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        sx={{ display: 'flex', alignContent: 'start' }}
        className="config-section-container"
      >
        <Grid item xs={3}>
          <div className="config-title">Start Time</div>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
        >
          <Box sx={{ display: 'flex', alignContent: 'center' }}>
            <TimePicker
              disabled={!startFromSelectedTime}
              onChange={setSelectedStartTime}
              value={selectedStartTime}
              format={'hh:mm a'}
              disableClock={true}
              clearIcon={null}
            />
          </Box>
          <Checkbox
            sx={{
              color: BrandColors.Gray,
              '&.Mui-checked': {
                color: BrandColors.Yellow,
              },
            }}
            checked={startFromSelectedTime}
            onChange={(e) => setStartFromSelectedTime(e.target.checked)}
          />
          <label className="config-body-text">start at selected time</label>
        </Grid>
      </Grid>
      <Grid container columns={1}>
        <Grid item sx={{ width: '100%' }}>
          {showWarning ? (
            <Alert
              severity="warning"
              className="config-body-text"
              sx={{
                marginBottom: 2,
                backgroundColor: getRgba(BrandColors.Yellow, 0.2),
                '& .MuiAlert-icon': {
                  color: BrandColors.Yellow,
                },
              }}
            >
              Interval must be 5 minutes or longer.
            </Alert>
          ) : (
            <Alert
              severity="warning"
              className="config-body-text"
              sx={{ visibility: 'hidden', marginBottom: 2 }}
            >
              hidden
            </Alert>
          )}
        </Grid>
      </Grid>
      <Grid
        direction="row"
        sx={{ justifyContent: 'flex-end' }}
        columns={1}
        container
      >
        <Grid item sx={{ display: 'flex' }}>
          <Button
            className="config-button config-button-secondary"
            onClick={() => handleCloseDialog()}
          >
            Exit
          </Button>
          <Button
            className="config-button config-button-primary"
            disabled={showWarning || props.saveInProgress}
            onClick={() => handleClickSave()}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfigDialog;
