import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Home } from '@toolkit/router/paths';
import { identifyUser } from 'Common/analytics';
import { Actions, CommonContext } from 'Common/common-ui';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import phDataLogo from 'src/resources/phdata-book.png';
import GlobalSearch from './GlobalSearchField';
import UserAvatar from './UserAvatar';
import UserMenu from './UserMenu';
import { BrandColors } from 'Common/common-ui/common/theme';

const AppHeader: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const {
    dispatch,
    state: { userDetails },
  } = React.useContext(CommonContext);
  const matches: boolean = useMediaQuery(useTheme().breakpoints.down('sm'));

  React.useEffect(() => {
    if (isAuthenticated && userDetails) {
      identifyUser(userDetails);
    }
  }, [isAuthenticated, userDetails]);

  const toggleLogoHandler = (): void => {
    dispatch({ type: Actions.TOGGLE_LEFT_NAV, payload: !matches });
  };

  return (
    <AppBar
      position="relative"
      sx={{ zIndex: 1001, py: 0.5, backgroundColor: BrandColors.Primary }}
      data-testid="header-content"
    >
      <Toolbar>
        <Link
          component={RouterLink}
          to={Home}
          onClick={toggleLogoHandler}
          underline={'none'}
        >
          <CardHeader
            avatar={
              <Tooltip title={'phData Toolkit'} arrow>
                <CardMedia
                  id="phdata-logo"
                  src={phDataLogo}
                  component="img"
                  alt="phData Logo"
                  sx={{
                    width: 40,
                    background: BrandColors.White,
                    borderRadius: '50%',
                    p: 0.5,
                  }}
                />
              </Tooltip>
            }
            title={
              <Typography
                variant="h2"
                fontWeight="500"
                color={BrandColors.White}
                noWrap
                // Hide the text on xs screens.
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                phData Toolkit
              </Typography>
            }
            sx={{ p: 0, minWidth: 50 }}
          />
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={1}>
          <GlobalSearch />
          <UserAvatar />
          <UserMenu userInfo={userDetails} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
