import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ConfigDialog from './components/ConfigDialog';
import Refresh from './components/Refresh';

const SynchronizedRefresh: React.FC = () => {
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const extensionPath = 'synchronized-refresh';

  useEffect(() => {
    let newKey = renderKey;
    while (newKey === renderKey) {
      newKey = Math.random();
    }
    setRenderKey(newKey);
  }, [saveInProgress]);

  function getHoursMinutesSeconds(inputSeconds: number): {
    hours: number;
    minutes: number;
    seconds: number;
  } {
    const hours = Math.floor(inputSeconds / 3600); // get hours
    const minutes = Math.floor((inputSeconds - hours * 3600) / 60); // get minutes
    const seconds = inputSeconds - hours * 3600 - minutes * 60; //  get seconds
    return { hours, minutes, seconds };
  }

  return (
    <Routes>
      <Route
        path={`/${extensionPath}`}
        element={
          <Refresh
            getHoursMinutesSeconds={getHoursMinutesSeconds}
            saveInProgress={saveInProgress}
            setSaveInProgress={(bool) => setSaveInProgress(bool)}
            extensionPath={extensionPath}
          />
        }
      />
      <Route
        path={`/${extensionPath}/dialog`}
        element={
          <ConfigDialog
            getHoursMinutesSeconds={getHoursMinutesSeconds}
            key={renderKey}
            saveInProgress={saveInProgress}
          />
        }
      />
    </Routes>
  );
};

export default SynchronizedRefresh;
