import React from 'react';
import { Experience, FormField, RequestType } from '../demo/domain';
import { PROVISION_FORMS } from '../demo/forms';
import { Action, Actions } from './actions';

export interface ProvisionDemoState {
  experience: Experience;
  sql: string;
  requestType: RequestType;
  template: string;
  group: string;
  formFields: FormField[];
  fieldValues: { [key: string]: string };
}

const initialState: ProvisionDemoState = {
  experience: Experience.USER,
  sql: '',
  template: '',
  group: '',
  requestType: RequestType.CREATE_PROJECT,
  formFields: PROVISION_FORMS[RequestType.CREATE_PROJECT].fields.map((f) => {
    return { ...f };
  }),
  fieldValues: null,
};

const ProvisionDemoReducer = (
  state: ProvisionDemoState,
  action: Action
): ProvisionDemoState => {
  switch (action.type) {
    case Actions.SET_EXPERIENCE:
      return { ...state, experience: action.payload };
    case Actions.SET_SQL:
      return { ...state, sql: action.payload };
    case Actions.SET_REQUEST_TYPE:
      return { ...state, requestType: action.payload };
    case Actions.SET_TEMPLATE:
      return { ...state, template: action.payload };
    case Actions.SET_GROUP:
      return { ...state, group: action.payload };
    case Actions.SET_FORM_FIELDS:
      return { ...state, formFields: action.payload };
    case Actions.SET_FIELD_VALUES:
      return { ...state, fieldValues: action.payload };
    default:
      return initialState;
  }
};

type ProvisionDemoDefinition = {
  state: ProvisionDemoState;
  dispatch: React.Dispatch<Action>;
};

export const ProvisionDemoContext =
  React.createContext<ProvisionDemoDefinition>({
    state: initialState,
    dispatch: () => null,
  });

export const useProvisionDemoReducer = () =>
  React.useReducer(ProvisionDemoReducer, initialState);

export const useProvisionDemoContext = () =>
  React.useContext(ProvisionDemoContext);
