import React from 'react';
import Box from '@mui/material/Box';
import FilterToggle from './FilterToggle';
import { IconButton, ListSubheader, Tooltip, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { BrandColors } from 'Common/common-ui/common/theme';
import Popover from '@mui/material/Popover';
import { FilterSections, FilterSection, FilterTag } from 'Common/interfaces';

export default function FilterDrawer(props: {
  sections: FilterSections;
  setSections: (FilterSections) => void;
  title: string;
  showFilters: boolean;
  setShowFilters: (boolean) => void;
  topSectionHeight: number;
  scrollableSectionHeight: { [key: string]: string };
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClickHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const helpPopoverOpen = Boolean(anchorEl);
  const helpId = helpPopoverOpen ? 'simple-popover' : undefined;

  const allSelected = React.useMemo(() => {
    let allChecked = true;
    Object.values(props.sections).forEach((section) => {
      Object.values(section).forEach((filter: any) => {
        if (!filter.on) {
          allChecked = false;
        }
      });
    });
    return allChecked;
  }, [props.sections]);

  function handleClickFilter(sectionName: string, filterName: string) {
    const sectionsCopy: { [key: string]: FilterSection } = JSON.parse(
      JSON.stringify(props.sections)
    );
    if (sectionsCopy[sectionName][filterName]) {
      sectionsCopy[sectionName][filterName].on =
        !sectionsCopy[sectionName][filterName].on;
    }
    props.setSections(sectionsCopy);
  }

  function handleSelectIndividual(sectionName: string, filterName: string) {
    const sectionsCopy: { [key: string]: FilterSection } = JSON.parse(
      JSON.stringify(props.sections)
    );
    Object.values(sectionsCopy).forEach((section) => {
      Object.values(section).forEach((filter: any) => {
        filter.on = false;
      });
    });
    if (sectionsCopy[sectionName][filterName]) {
      sectionsCopy[sectionName][filterName].on =
        !sectionsCopy[sectionName][filterName].on;
    }
    props.setSections(sectionsCopy);
  }

  function handleSelectAll() {
    const sectionsCopy: { [key: string]: FilterSection } = JSON.parse(
      JSON.stringify(props.sections)
    );
    Object.values(sectionsCopy).forEach((section: FilterSection) => {
      Object.values(section).forEach((filter: FilterTag) => {
        filter.on = !allSelected;
      });
    });
    props.setSections(sectionsCopy);
  }

  return (
    <Box
      sx={{ display: 'flex', position: 'relative', left: 0 }}
      height={'100%'}
      width={'100%'}
    >
      <Box sx={{ p: 0, m: 0 }} height={'100%'} width={'100%'}>
        <ListSubheader
          sx={{
            pl: 1.4,
            height: props.topSectionHeight,
            borderBottom: 1,
            borderColor: BrandColors.LightGray,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: BrandColors.DarkGray }}
              display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
            >{`${props.title} Filters`}</Typography>
            <Tooltip title="Select All" placement="bottom" arrow>
              <IconButton
                data-testid={`tag-${allSelected ? 'hide' : 'show'}-all`}
                sx={{ ml: 1 }}
                onClick={() => handleSelectAll()}
              >
                {allSelected ? (
                  <CheckBoxIcon fontSize="small" />
                ) : (
                  <SelectAllIcon />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Help">
              <IconButton onClick={handleClickHelp} aria-label="delete">
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Popover
              id={helpId}
              open={helpPopoverOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2 }}>
                Select items to filter by using the checkboxes. Double click an
                item to individually select it.
              </Typography>
            </Popover>
          </Box>
        </ListSubheader>
        <Box
          pt={2}
          sx={{
            height: props.scrollableSectionHeight,
            overflowY: 'scroll',
            msOverflowStyle: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {Object.keys(props.sections).map((section) => {
            return (
              <Box
                sx={{ width: '100%' }}
                pb={2}
                key={`filter-section-${section}`}
              >
                <Typography pl={2} pb={1} variant="subtitle2">
                  {section.charAt(0).toUpperCase() + section.slice(1)}:
                </Typography>
                {Object.keys(props.sections[section]).map((filter) => {
                  return (
                    <React.Fragment key={`filter-${filter}`}>
                      <FilterToggle
                        testId={`${section}-${filter}`}
                        filter={filter}
                        onDoubleClick={() =>
                          handleSelectIndividual(section, filter)
                        }
                        onClick={() => handleClickFilter(section, filter)}
                        checked={props.sections[section][filter].on}
                        count={props.sections[section][filter].count}
                      />
                    </React.Fragment>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
