import CreateIcon from '@mui/icons-material/Create';
import HelpIcon from '@mui/icons-material/Help';
import NotesIcon from '@mui/icons-material/Notes';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import * as Paths from '@toolkit/router/paths';
import { ErrorBoundary, Header, HeaderNavItem } from 'Common/common-ui';
import { logFrontendError } from 'Common/services/api';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { getSQLMorphOutletContext } from '../SQLMorphRouting';
import * as SqlPaths from '../constants/paths';
import { Box } from '@mui/material';

const AppHeader: React.FC = () => {
  const { isDemoMode } = getSQLMorphOutletContext();
  const navOptions: HeaderNavItem[] = [
    {
      icon: CreateIcon,
      title: 'Translate',
      to: SqlPaths.translate,
    },
    {
      icon: HelpIcon,
      title: 'Supported Statements',
      to: SqlPaths.supportStatements,
    },
    {
      icon: WebAssetIcon,
      title: 'Demo',
      to: SqlPaths.demoQueries,
    },
    {
      icon: NotesIcon,
      title: 'About',
      to: SqlPaths.about,
    },
  ];

  function filterNavOptions(): HeaderNavItem[] {
    let options: HeaderNavItem[] = [];
    if (isDemoMode) {
      options = navOptions.map((nav: HeaderNavItem) => {
        nav.to = `${Paths.SqlTranslation}/${SqlPaths.demo}/${nav.to}`;
        return nav;
      });
    } else {
      options = navOptions.map((nav: HeaderNavItem) => {
        nav.to = `${Paths.SqlTranslation}/${nav.to}`;
        return nav;
      });
    }
    return options;
  }

  return (
    <Box id="sql-header-container">
      <Header navOptions={filterNavOptions()} />
      <ErrorBoundary onError={logFrontendError}>
        <Outlet context={{ isDemoMode }} />
      </ErrorBoundary>
    </Box>
  );
};

export default AppHeader;
