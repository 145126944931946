import * as Paths from '@toolkit/router/paths';
import { CommonContext } from 'Common/common-ui';
import { LoadingIndicator } from '@toolkit/common';
import { setDashboards } from 'Common/common-ui/app-state/actions';
import { FilterTagsQueryParams } from 'Common/components/FilterTags';
import {
  AnalyticsLibraryProps as ALProps,
  FilterSections,
} from 'Common/interfaces';
import { localStorageService } from 'Common/localStorage';
import { getDashboards } from 'Common/services/api';
import { debounce } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { NavigateFunction, useLoaderData, useNavigate } from 'react-router-dom';
import CardGrid from 'Common/components/CardGrid';

const Dashboards: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { dispatch } = React.useContext(CommonContext);
  const data = useLoaderData() as { dashboards: ALProps[] };
  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [allDashboards, setAllDashboards] = React.useState<ALProps[]>([]);
  const [filteredDashboards, setFilteredDashboards] = React.useState<ALProps[]>(
    []
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [sections, setSections] = React.useState<FilterSections>(null);
  // eslint-disable-next-line
  const [showFilters, setShowFilters] = React.useState<boolean>(true);
  // eslint-disable-next-line
  const scrollableSectionHeightObj: { [key: string]: string } = {
    xs: 'calc(100vh - 280px)',
    sm: 'calc(100vh - 150px)',
  };
  // eslint-disable-next-line
  const topSectionHeight = 70;

  // eslint-disable-next-line
  const allTags: string[] = React.useMemo(() => {
    if (sections) {
      Object.keys(sections.tags);
    }
    return [];
  }, []);

  // eslint-disable-next-line
  const allTechnologies: string[] = React.useMemo(() => {
    if (sections) {
      Object.keys(sections.technologies);
    }
    return [];
  }, []);

  const selectedTechnologies: string[] = React.useMemo(() => {
    if (sections) {
      return Object.keys(sections.technologies).filter(
        (tech: string) => sections.technologies[tech].on
      );
    }
    return [];
  }, [sections]);

  const selectedTags: string[] = React.useMemo(() => {
    if (sections) {
      return Object.keys(sections.tags).filter(
        (tag: string) => sections.tags[tag].on
      );
    }
    return [];
  }, [sections]);

  function updateHistory(
    tags: string[],
    techs: string[],
    searchText: string
  ): void {
    navigate(
      `${Paths.AnalyticsLibrary}?${queryString.stringify(
        {
          category: [...tags],
          technology: [...techs],
          name: searchText,
        } as FilterTagsQueryParams,
        {
          arrayFormat: 'comma',
          skipEmptyString: true,
        }
      )}`,
      {
        replace: true,
      }
    );
  }

  React.useEffect(() => {
    setIsLoading(true);
    const loadDatas = async () => {
      const sectionsObj: FilterSections = {
        technologies: {},
        tags: {},
      };

      const dashDatas: ALProps[] = data.dashboards;

      if (dashDatas?.length) {
        dispatch(setDashboards(dashDatas));
        dashDatas.forEach((dashboard: ALProps) => {
          if (sectionsObj.technologies[dashboard.technology]) {
            sectionsObj.technologies[dashboard.technology].count += 1;
          } else {
            sectionsObj.technologies[dashboard.technology] = {
              on: false,
              count: 1,
            };
          }
          dashboard.labels.forEach((label) => {
            if (sectionsObj.tags[label]) {
              sectionsObj.tags[label].count += 1;
            } else {
              sectionsObj.tags[label] = { on: false, count: 1 };
            }
          });
        });
        setSections(sectionsObj);
        setAllDashboards([...dashDatas] as ALProps[]);
        const searchStr: string =
          await localStorageService.getAnalyticsSearchText();
        setSearchText(searchStr as string);
        setIsLoading(false);
      }
    };
    loadDatas();
  }, []);

  React.useEffect(() => {
    let dashboardList: ALProps[] = allDashboards;
    if (selectedTechnologies.length) {
      dashboardList = dashboardList.filter((dashboard) => {
        return sections.technologies[dashboard.technology].on;
      });
    }
    if (selectedTags.length) {
      dashboardList = dashboardList.filter((dashboard) => {
        let shouldInclude = false;
        dashboard.labels.forEach((label) => {
          if (sections.tags[label].on) {
            shouldInclude = true;
          }
        });
        return shouldInclude;
      });
    }
    if (searchText && searchText.trim()) {
      dashboardList =
        dashboardList &&
        dashboardList.filter(
          (dashboard: ALProps) =>
            searchText &&
            (
              dashboard.title?.toLowerCase() +
              dashboard.labels?.join(' ')?.toLowerCase() +
              dashboard.description?.toLowerCase() +
              dashboard.author?.toLowerCase() +
              dashboard.vizUrl?.toLowerCase()
            ).includes(searchText?.toLowerCase())
        );
    }
    setFilteredDashboards([...dashboardList] as ALProps[]);
  }, [allDashboards, sections, searchText]);

  if (isLoading) {
    return (
      <LoadingIndicator
        fullScreen={true}
        title="Loading analytics library..."
      />
    );
  }

  // eslint-disable-next-line
  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
    reason: string
  ) {
    if (reason !== 'reset') {
      setSearchText(value);
      debounce(async () => {
        await localStorageService.setAnalyticsSearchText(value);
        if (sections) {
          updateHistory([...selectedTags], [...selectedTechnologies], value);
        }
      }, 500);
    }
  }

  return (
    <CardGrid
      filteredDashboards={filteredDashboards}
      sections={sections}
      setSections={setSections}
    />
  );
};
export default Dashboards;

export function dashboardLoader(): Promise<{ dashboards: ALProps[] }> {
  const dashboards = getDashboards();
  return dashboards;
}
