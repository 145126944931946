import { Box } from '@mui/material';
import React from 'react';
import '../synchronized-refresh.scss';

export interface RenderTimeProps {
  getHoursMinutesSeconds: (params: number) => { hours; minutes; seconds };
  remainingTime: number;
}
const RenderTime: React.FC<RenderTimeProps> = (props: RenderTimeProps) => {
  const { hours, minutes, seconds } = props.getHoursMinutesSeconds(
    props.remainingTime
  );

  return (
    <div className="timer">
      <Box sx={{ textAlign: 'center', overflow: 'hidden' }}>
        <span className="countdown-text-number">{hours}</span>
        <span className="countdown-text">
          {hours === 1 ? ' hour, ' : ' hours, '}
        </span>
        <span className="countdown-text countdown-text-short">{'h '}</span>
        <span className="countdown-text-number">{minutes}</span>
        <span className="countdown-text">
          {minutes === 1 ? ' minute, and ' : ' minutes, and '}
        </span>
        <span className="countdown-text countdown-text-short">{'m '}</span>
        <span className="countdown-text-number">{seconds}</span>
        <span className="countdown-text">
          {seconds === 1 ? ' second ' : ' seconds '}
        </span>
        <span className="countdown-text countdown-text-short">{'s '}</span>
        <span className="countdown-text"> until next refresh.</span>
        <span className="countdown-text countdown-text-short"> left.</span>
      </Box>
    </div>
  );
};
export default RenderTime;
