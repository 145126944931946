import HomeOutlined from '@mui/icons-material/HomeOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as Paths from '@toolkit/router/paths';
import { capitalize } from 'lodash';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface Props {
  page?: string;
  path?: string;
  icon?: React.ReactElement;
}

const PageNotFound = (props: Props) => {
  const navigate: NavigateFunction = useNavigate();

  function handleRoute() {
    if (props.path) {
      navigate(props.path, { replace: true });
    } else {
      navigate(Paths.Home, { replace: true });
    }
  }

  return (
    <Box
      sx={{ width: '100%' }}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      mt={6}
    >
      <Typography mt={3} textAlign="center" variant="h4">
        404
      </Typography>
      <Typography mt={1} textAlign="center" variant="h5" gutterBottom>
        Page Not Found
      </Typography>
      <Box m={'0 auto'} mt={1} justifyContent="center">
        <Typography
          maxWidth={500}
          textAlign="center"
          variant="body1"
          gutterBottom
        >
          The page you are looking for does not exist. But you can click the
          button below to go back to the landing page.
        </Typography>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          color="primary"
          sx={{ minWidth: 100 }}
          startIcon={props.icon || <HomeOutlined />}
          onClick={handleRoute}
        >
          {props.page ? capitalize(props.page) : 'Home'}
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
