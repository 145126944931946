import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ImageItem } from 'Common/interfaces';
import { Fade } from '@mui/material';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import TealButton from './TealButton';

interface Props {
  imageItems: Array<ImageItem>;
  rowHeight: number;
  cols: number;
  width: number | string;
  height: number | string;
}

export default function ImageGrid(props: Props) {
  const [activeCellHover, setActiveCellHover] =
    React.useState<number>(undefined);

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  function handleClickImage(path: string): void {
    window.open(`${window?.location?.origin}${path}`, '_blank');
  }

  return (
    <ImageList
      sx={{ width: props.width, height: props.height }}
      variant="quilted"
      cols={props.cols}
      rowHeight={props.rowHeight}
      gap={4}
    >
      {props.imageItems.map((item, idx) => (
        <ImageListItem
          onMouseEnter={() => {
            if (props.imageItems[idx].reportPath) {
              setActiveCellHover(idx);
            }
          }}
          onMouseLeave={() => {
            if (props.imageItems[idx].reportPath) {
              setActiveCellHover(undefined);
            }
          }}
          sx={{
            m: 1,
            overflow: 'hidden !important',
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 1px',
            borderRadius: 5,
          }}
          key={item.img}
          cols={item.cols}
          rows={item.rows}
        >
          <Box
            sx={{
              transition: 'transform .2s',
              width: '100%',
              height: '100%',
              '&:hover': { transform: 'scale(1.01) !important' },
            }}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                objectFit: 'contain',
              }}
              {...srcset(item.img, 100, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
            />
            <Fade timeout={250} in={activeCellHover === idx}>
              <Box
                id={`overlay-box-${idx}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  background: 'rgba(57, 57, 57, 0.2)',
                  right: 0,
                  top: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TealButton
                  onClick={() => handleClickImage(item.reportPath)}
                  variant="contained"
                  endIcon={<CallMadeOutlinedIcon fontSize="small" />}
                >
                  View Example Report
                </TealButton>
              </Box>
            </Fade>
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
  );
}
