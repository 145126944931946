import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { useContext } from 'react';
import { CommonContext } from '../app-state/reducer';

export interface ModalProps {
  onClose: (e: React.MouseEvent) => void;
}

const MyModal = (props: ModalProps) => {
  const {
    state: { modal },
  } = useContext(CommonContext);

  const onClose = (e: React.MouseEvent) => {
    if (modal.onClose) {
      modal.onClose(e);
    }
    props.onClose(e);
  };

  if (!modal.body) {
    return null;
  }

  return (
    <Dialog
      open={!!modal.open}
      aria-labelledby="customized-dialog-title"
      maxWidth={false}
      data-testid="modal-popup"
      onClose={onClose}
    >
      {modal.title && (
        <DialogTitle
          id="customized-dialog-title"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 20px',
          }}
        >
          {modal.title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            size="large"
            sx={{ ml: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent dividers>{modal.body}</DialogContent>
    </Dialog>
  );
};

export default MyModal;
