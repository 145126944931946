import {
  CardActionArea,
  CardContent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Card, CardActions, CardMedia } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';
import React from 'react';
import sampleSgv from './assets/sample-data-coach-card-bg.svg';
import { Course } from './courseCopy';
import TealButton from 'Common/components/TealButton';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

type Props = {
  course: Course;
  onClickCourse: (Course) => void;
};

function getTimeString(minutes: number): string {
  const timeString =
    minutes < 60
      ? `${minutes}m`
      : minutes % 60 === 0
        ? '1h'
        : `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
  return timeString;
}

export default function CourseCard(props: Props) {
  return (
    <Card sx={{ height: 380, position: 'relative' }}>
      <CardActionArea
        sx={{ height: '100%', position: 'relative' }}
        id="course-card-action-area"
        onClick={() => props.onClickCourse(props.course)}
      >
        <CardMedia
          sx={{
            height: '45%',
            display: 'flex',
            alignItems: 'center',
            pl: 2,
            position: 'relative',
            top: -20,
          }}
          image={sampleSgv}
        >
          {' '}
          <TealButton
            sx={{
              borderRadius: '4px 0px 0px 4px !important',
              position: 'absolute',
              top: 40,
              right: 0,
            }}
            variant="contained"
            endIcon={<LaunchOutlinedIcon />}
          >
            Learn More
          </TealButton>
          <Typography
            sx={{ pt: 3, color: BrandColors.White, fontWeight: 700 }}
            gutterBottom
            variant="h5"
            component="div"
          >
            {props.course.title}
            <Typography sx={{ color: BrandColors.Mint }} gutterBottom>
              Sample Course
            </Typography>
          </Typography>
        </CardMedia>
        <CardContent sx={{ pt: 0 }}>
          <Typography gutterBottom variant="h5" component="div">
            {props.course.title}
          </Typography>
          <Tooltip title={props.course.description}>
            <Typography
              sx={{
                height: '120px',
                overflowY: 'clip',
                textOverflow: 'ellipsis !important',
              }}
              variant="body2"
              color="text.secondary"
            >
              {props.course.description}
            </Typography>
          </Tooltip>
        </CardContent>
        <CardActions>
          <Typography
            sx={{ color: BrandColors.Gray }}
            fontSize="small"
          >{`${getTimeString(
            props.course.durationMinutesFast
          )} | ${getTimeString(props.course.durationMinutesSlow)}`}</Typography>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
