import * as localForage from 'localforage';

const prefix = 'toolkit.';
const analyticSearchText = `${prefix}analyticSearchText`;
const analyticTags = `${prefix}analyticTags`;
const analyticTechs = `${prefix}analyticTechs`;

export const localStorageService = {
  async setAnalyticsSearchText(text: string) {
    await localForage.setItem(analyticSearchText, text);
  },
  async getAnalyticsSearchText(): Promise<string> {
    return await localForage.getItem(analyticSearchText);
  },
  async setAnalyticsTags(tags: string[]) {
    await localForage.setItem(analyticTags, JSON.stringify(tags));
  },
  async getAnalyticsTags() {
    const stored = await localForage.getItem(analyticTags);
    return this.safeJsonParse(stored as any as string);
  },
  async setAnalyticsTechs(techs: string[]) {
    await localForage.setItem(analyticTechs, JSON.stringify(techs));
  },
  async getAnalyticsTechs() {
    const stored = await localForage.getItem(analyticTechs);
    return this.safeJsonParse(stored as any as string);
  },
  safeJsonParse<T>(string: string | null): T | undefined {
    try {
      if (string) {
        return JSON.parse(string) as T;
      }
    } catch {
      return undefined;
    }
  },
};
