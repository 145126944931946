import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { BrandColors } from 'Common/common-ui/common/theme';
import CopyToClipboard from 'copy-to-clipboard';
import { domToReact } from 'html-react-parser';
import * as React from 'react';

export function generateCopyButton(domNode) {
  const [copyText, setCopyText] = React.useState<string | null>('Copy');
  const nodeName = domNode.name;

  if (nodeName === 'div' && domNode?.attribs?.class === 'content') {
    if (
      domNode.childNodes[1]?.name === 'pre' &&
      domNode.childNodes[1]?.attribs?.class === 'rouge highlight'
    ) {
      if (domNode.childNodes[1]?.childNodes[0].name === 'code') {
        return (
          <Box
            className="content"
            sx={{
              '& pre code': { pt: 2 },
              position: 'relative',
              '&:hover button': { opacity: 1 },
              '&:hover pre code:before': {
                top: 8,
                right: 40,
                fontSize: 16,
                borderRight: '1px solid #ece6e6',
                paddingRight: 1,
              },
            }}
            onMouseLeave={() => {
              setCopyText('Copy');
            }}
          >
            <Tooltip
              title={
                <Typography
                  variant="subtitle2"
                  sx={{ color: BrandColors.White }}
                >
                  {copyText}
                </Typography>
              }
              placement="top"
              arrow
            >
              <Button
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 10,
                  opacity: 0,
                  '&.MuiButton-root': {
                    color: '#a2a2a2',
                    padding: 0,
                    cursor: 'pointer',
                    minWidth: 25,
                  },
                  '&:hover': {
                    color: '#ece6e6',
                  },
                }}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  const target = e.target as HTMLElement;
                  const copyBtn = target?.parentNode as HTMLElement;
                  const parent = copyBtn?.parentNode
                    ?.children[1] as HTMLElement;
                  CopyToClipboard(parent?.outerText);
                  setCopyText('Copied!');
                }}
              >
                <ContentCopyIcon sx={{ fontSize: 18 }} fontSize="small" />
              </Button>
            </Tooltip>
            {domToReact(domNode.children)}
          </Box>
        );
      }
    }
  }
}
