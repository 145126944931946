import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import cn from 'classnames';
import * as React from 'react';

enum DrawerType {
  EXPAND,
  COLLAPSE,
}

interface CollapseExpandProps {
  handleToggle: (toggle: boolean) => void;
  type: DrawerType;
  style?: React.CSSProperties;
}

const CollapseExpand: React.FC<CollapseExpandProps> = (
  props: CollapseExpandProps
): React.ReactElement => {
  const isExpanded = props.type === DrawerType.EXPAND;

  return (
    <Box
      style={props.style}
      component="div"
      className={cn(isExpanded ? 'expand' : 'collapse', 'icon-main-section')}
      onClick={() => props.handleToggle(isExpanded)}
    >
      <Box className="icon-section" component="div">
        {isExpanded ? (
          <ArrowForwardIosIcon className="btn-arrow" fontSize="small" />
        ) : (
          <ArrowBackIosIcon className="btn-arrow" fontSize="small" />
        )}
      </Box>
    </Box>
  );
};

export default CollapseExpand;
