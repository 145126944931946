import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function LeftDrawer(props: {
  showFilters: boolean;
  setShowFilters: (boolean) => void;
}) {
  function handleExpandCollapse() {
    props.setShowFilters(!props.showFilters);
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 10,
        top: 80,
        right: -12,
        background: '#FFFFFF',
      }}
    >
      <Tooltip
        title={props.showFilters ? 'Hide filters' : 'Show filters'}
        placement="right-end"
        arrow
      >
        <IconButton
          sx={{ border: '1px solid #C4C4C4', p: 0.2 }}
          color="primary"
          onClick={handleExpandCollapse}
        >
          {props.showFilters ? (
            <KeyboardArrowLeftIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
