import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BrandColors } from 'Common/common-ui/common/theme';

const SearchEndIcon: React.FC = () => {
  function getKeySymbol() {
    if (window.navigator.userAgent.indexOf('Mac OS') !== -1) {
      return '⌘';
    } else {
      return 'ctrl';
    }
  }

  return (
    <Box display={'flex'} component={'span'} fontSize="18">
      <Button sx={{ p: 0, minWidth: 28, bgcolor: BrandColors.LightGray }}>
        {getKeySymbol()}
      </Button>
      <Typography variant="button">+</Typography>
      <Button sx={{ p: 0, minWidth: 28, bgcolor: BrandColors.LightGray }}>
        K
      </Button>
    </Box>
  );
};

export default SearchEndIcon;
