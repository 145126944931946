import { Box, Popover } from '@mui/material';
import React from 'react';
import '../synchronized-refresh.scss';

export interface RefreshIntervalPopOverProps {
  interval: number;
  anchorEl: HTMLElement;
  handlePopoverClose: (params: void) => void;
  getHoursMinutesSeconds: (params: number) => { hours; minutes; seconds };
  open: boolean;
}

const RefreshIntervalPopOver: React.FC<RefreshIntervalPopOverProps> = (
  props: RefreshIntervalPopOverProps
) => {
  function generateReadableTime(): string {
    // returns a human readable time string from the refresh interval
    let string = '';
    const time = props.getHoursMinutesSeconds(props.interval);
    for (const unit in time) {
      if (
        unit === 'seconds' &&
        time[unit] &&
        (time['minute'] > 0 || time['hour'] > 0)
      ) {
        string += ' and';
      }
      if (time[unit] && time[unit] !== 1) {
        string += ` ${time[unit]} ${unit}`;
      } else if (time[unit] && time[unit] === 1) {
        string += ` ${time[unit]} ${unit.slice(0, -1)}`;
      }
    }

    if (string.length) {
      return string;
    } else {
      return 'No interval set';
    }
  }

  return (
    <Popover
      sx={{ top: '-15px !important' }}
      id="mouse-over-popover"
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className="refresh-tooltip" sx={{ paddingX: 1 }}>
        <strong>Every:</strong>
        <span>{generateReadableTime()}</span>
      </Box>
    </Popover>
  );
};

export default RefreshIntervalPopOver;
