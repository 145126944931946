import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Highlight } from 'react-instantsearch';
import { BrandColors } from 'Common/common-ui/common/theme';
import { styled } from '@mui/material/styles';

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const ChildNode = (props: { children: string }): React.ReactElement => (
  <Box component="span" mx={0.5} fontSize={14}>
    {props.children}
  </Box>
);

interface Props {
  hit: any;
  headers: string[];
}

export default function TooltipContent({
  hit,
  headers,
}: Props): React.ReactElement {
  const maxHeaderCharLen = 80;
  const headersLen: number = headers.length;
  const firstLastHeaderCharLen: number =
    hit[headers[0]].length + hit[headers[headersLen - 1]].length;
  const splicedHeaders: string[] = [...headers].splice(1, headersLen - 2);
  const ellipsedHeaders: string[] =
    [...splicedHeaders].filter((_header: string, ind: number) => {
      const charLen: number =
        firstLastHeaderCharLen + findHeaderCharLen(ind + 1);
      return charLen < maxHeaderCharLen ? true : false;
    }) || [];

  function findHeaderCharLen(index: number): number {
    let charLen = 0;
    [...splicedHeaders].slice(0, index).forEach((header: string) => {
      charLen += hit[header].length;
    });
    return charLen;
  }

  return (
    <NoMaxWidthTooltip
      title={
        <Typography
          color={BrandColors.White}
          sx={{
            '& .ais-Highlight-highlighted': {
              color: BrandColors.Teal,
            },
          }}
        >
          {[...headers].map((header: string, index: number) => (
            <>
              <Highlight key={header} hit={hit} attribute={header} />
              {index !== headersLen - 1 && <ChildNode children="/" />}
            </>
          ))}
        </Typography>
      }
      placement="top-start"
    >
      <Box>
        <Highlight hit={hit} attribute={headers[0]} />
        {headersLen > 1 && (
          <>
            {ellipsedHeaders?.map((header: string) => (
              <>
                <ChildNode children="/" />
                <Highlight key={header} hit={hit} attribute={header} />
              </>
            ))}
            {ellipsedHeaders.length !== 0 &&
            ellipsedHeaders.length < splicedHeaders.length ? (
              <ChildNode children="/ ... /" />
            ) : (
              <ChildNode children="/" />
            )}
            <Highlight hit={hit} attribute={headers[headersLen - 1]} />
          </>
        )}
      </Box>
    </NoMaxWidthTooltip>
  );
}
