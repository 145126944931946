import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import * as React from 'react';

export default function UserAvatar(): React.ReactElement {
  const { isAuthenticated, user } = useAuth0();
  if (!isAuthenticated) {
    return <></>;
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'user-icon-popover' : undefined;

  function handleMouseEnter(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function handleMouseLeave(): void {
    setAnchorEl(null);
  }

  return (
    <Box sx={{ height: 42 }}>
      <IconButton sx={{ p: 0 }} color="inherit" onMouseEnter={handleMouseEnter}>
        <Avatar
          sx={{ ml: 1 }}
          data-testid="signin-avatar-icon"
          alt={user.name}
          src={user.picture}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Avatar
              sx={{ width: 100, height: 100 }}
              alt={user.name}
              src={user.picture}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" textAlign={'center'}>
              {user.name}
            </Typography>
            <Typography variant="subtitle1">{user.email}</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
