import React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BrandColors } from 'Common/common-ui/common/theme';

interface Props {
  filter: string;
  onClick: () => void;
  onDoubleClick?: () => void;
  checked: boolean;
  tooltipTitle?: string;
  count: number;
  disabled?: boolean;
  leftPadding?: number;
  testId?: string;
}

export default function ChangeTypeToggle(props: Props) {
  const { filter, onClick, onDoubleClick, checked, tooltipTitle, count } =
    props;

  return (
    <ListItem
      sx={{
        width: '100%',
      }}
      disablePadding
      data-testid={props.testId}
    >
      <ListItemButton
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        role={undefined}
        dense
        disabled={props.disabled}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <Stack
          sx={{
            paddingLeft: props.leftPadding ? props.leftPadding : 1,
            alignItems: 'center',
            display: 'flex',
          }}
          spacing={0}
          direction="row"
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <Checkbox
              edge="start"
              checked={checked}
              tabIndex={-1}
              disableRipple
              disabled={props.disabled}
              inputProps={{
                'aria-labelledby': `${filter}-checkbox-label`,
              }}
            />
          </ListItemIcon>
          <Box id="ttp-box">
            <Tooltip title={tooltipTitle} enterDelay={500} placement="right">
              <Box
                id="chip-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Chip
                  id={`${filter}-checkbox-chip`}
                  label={filter}
                  className={`${filter}-checkbox-chip`}
                  sx={{
                    backgroundColor: BrandColors.Primary,
                    color: BrandColors.White,
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Stack>
        <Typography>{count}</Typography>
      </ListItemButton>
    </ListItem>
  );
}
