import { ImageItem } from 'Common/interfaces';
import DataGenDiagram from 'src/resources/ui-screenshots/data-generation-tool/data-gen-diagram.png';
import DataGenColumn from 'src/resources/ui-screenshots/data-generation-tool/data-gen-column.png';
import ProductReport from 'src/resources/ui-screenshots/data-generation-tool/data-gen-table-details.png';

export const ImageArray: Array<ImageItem> = [
  {
    img: DataGenDiagram,
    title: 'Data Generation report',
    rows: 2,
    cols: 4,
    reportPath: '/toolkit/docs/data-generation/advanced-snowflake-spec.html',
  },
  {
    img: DataGenColumn,
    title: 'Price Column',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/data-generation/advanced-snowflake-spec.html#/product',
  },
  {
    img: ProductReport,
    title: 'Product Report',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/data-generation/advanced-snowflake-spec.html#/product',
  },
];
