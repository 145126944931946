/* Main Paths */
export const Tools = 'tools';
export const Documentation = '/docs';

/* General Sub Paths */
export const Home = '/';
export const Updates = '/updates';

/* Apps Sub Paths */
export const AccessTool = `/${Tools}/access`;
export const AccessToolDocs = `${Documentation}/access`;

export const SqlTranslation = `/${Tools}/sql-translation`;
export const SqlTranslationDocs = `${Documentation}/sql-translation`;
export const SqlToolDocs = `${SqlTranslationDocs}#sql-tool`;
export const AnalyticsLibrary = `/${Tools}/analytics-library`;

export const ToolkitUserGuide = `${Documentation}/toolkit-user-guide`;

/* Tools Sub Paths */
export const AdvisorTool = `/${Tools}/advisor`;
export const AdvisorDocs = `${Documentation}/advisor`;
export const ProvisionTool = `/${Tools}/provision`;
export const ProvisionDocs = `${Documentation}/provision`;
export const DataSourceTool = `/${Tools}/data-source`;
export const DataSourceDocs = `${Documentation}/data-source`;
export const PlatformMonitoring = `/${Tools}/platform-monitoring`;
export const PlatformMonitoringDocs = `${Documentation}/platform-monitoring`;
export const DataGenerationTool = `/${Tools}/data-generation`;
export const DataGenerationDocs = `${Documentation}/data-generation`;

/* Resources Sub Paths */
export const Blog = 'https://www.phdata.io/blog/';
export const Training = 'https://www.phdata.io/data-coach/';
export const TrainingEnablement =
  'https://www.phdata.io/training-and-enablement/';
export const SampleTraining = '/training';
export const Support = `/support`;
export const About = 'https://www.phdata.io/about-us/';
export const ToolAccess = `/tool-access`;

export const ToolkitCliDocs = `${Documentation}/toolkit-cli`;

/* Analytics Library Sub Paths */
export const TableauExtensions = `${AnalyticsLibrary}/tableau/extensions`;

/* Other Paths */
export const AllPaths = '*';
export const SignUp = '/signup';
export const Health = '/health';
