import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import * as Paths from '@toolkit/router/paths';
import TealLink from 'Common/components/TealLink';
import * as React from 'react';
import { useProvisionDemoContext } from '../contexts/reducer';
import { Experience } from './domain';

export default function OverviewText() {
  const {
    state: { experience },
  } = useProvisionDemoContext();
  return (
    <Grid container>
      <Grid item md={6} px={1}>
        <Typography>
          The
          <TealLink sx={{ ml: 0.5 }} to={Paths.ProvisionTool}>
            Provision tool
          </TealLink>{' '}
          integrates with ITSM systems like Jira or Service Now to provide an
          experience like you see above.
        </Typography>
        {Experience[experience] === Experience.USER ? (
          <List>
            <ListItem>
              <ListItemText>
                1. A user makes a request to create a new project, gain access
                to an existing project, or perhaps create a new warehouse for
                their analytics work, etc.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. An approver reviews the request and approves or denies it.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. If approved, the request is ready to be provisioned by the
                Provision tool. Your ITSM system can trigger a CICD workflow, or
                a CICD workflow can run on a schedule to check for approved
                requests.
              </ListItemText>
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem>
              <ListItemText>
                1. A CICD workflow runs the Provision tool, which fetches the
                latest approved requests from your ITSM system.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. For each request, the Provision tool calculates the required
                changes, generates SQL, and executes the SQL. If provisioning is
                successful, the tool commits the updated template and group
                files to your git repository.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. The Provision tool updates the request status in your ITSM
                system.
              </ListItemText>
            </ListItem>
          </List>
        )}
      </Grid>
      <Grid item md={6} px={1}>
        <Typography>
          If you would like more information about the Provision tool,
          <TealLink sx={{ ml: 0.5 }} to={Paths.Support}>
            contact us
          </TealLink>
          , and we can give a detailed demo.
        </Typography>
        <Typography mt={1}>
          For more technical details, check out the
          <TealLink sx={{ ml: 0.5 }} to={Paths.ProvisionDocs}>
            Provision tool documentation
          </TealLink>
          .
        </Typography>
      </Grid>
    </Grid>
  );
}
