import {
  DropdownOption,
  HighlightContent,
  Response,
} from '../constants/interfaces';

export const Actions = {
  EDITOR_RESPONSES: 'EDITOR_RESPONSES',
  SELECTED_TARGET: 'SELECTED_TARGET',
  SELECTED_SOURCE: 'SELECTED_SOURCE',
  RATE_LIMIT_ALERT: 'RATE_LIMIT_ALERT',
  EDITOR_CONTENTS: 'EDITOR_CONTENTS',
  ERROR_CONUNT: 'ERROR_CONUNT',
  LOAD_SPINNER: 'LOAD_SPINNER',
  WARN_COUNT: 'WARN_COUNT',
  SOURCES: 'SOURCES',
  TARGETS: 'TARGETS',
  HIGHLIGHT_CONTENTS: 'HIGHLIGHT_CONTENTS',
  SOURCE_TARGET_LIST: 'SOURCE_TARGET_LIST',
  FILE_NAME: 'FILE_NAME',
  EXECUTE_DEMO_QUERY: 'EXECUTE_DEMO_QUERY',
  DEMO_QUERY: 'DEMO_QUERY',
  ALLOW_CMD_EXEC: 'ALLOW_CMD_EXEC',
};

export const setSelectedSource = (payload: DropdownOption) => ({
  type: Actions.SELECTED_SOURCE,
  payload,
});

export const setSelectedTarget = (payload: DropdownOption) => ({
  type: Actions.SELECTED_TARGET,
  payload,
});

export const setEditorResponses = (payload: Response[]) => ({
  type: Actions.EDITOR_RESPONSES,
  payload,
});

export const setRateLimitAlert = (payload: boolean) => ({
  type: Actions.RATE_LIMIT_ALERT,
  payload,
});

export const setEditorContent = (payload: string[]) => ({
  type: Actions.EDITOR_CONTENTS,
  payload,
});

export const setLoadSpinner = (payload: boolean) => ({
  type: Actions.LOAD_SPINNER,
  payload,
});

export const setSources = (payload: DropdownOption[]) => ({
  type: Actions.SOURCES,
  payload,
});

export const setTargets = (payload: DropdownOption[]) => ({
  type: Actions.TARGETS,
  payload,
});

export const setHighlightContents = (payload: HighlightContent[]) => ({
  type: Actions.HIGHLIGHT_CONTENTS,
  payload,
});

export const setSourceTargetList = (payload: string) => ({
  type: Actions.SOURCE_TARGET_LIST,
  payload,
});

export const setFileName = (payload: string) => ({
  type: Actions.FILE_NAME,
  payload,
});

export const setExecuteDemoQuery = (payload: boolean) => ({
  type: Actions.EXECUTE_DEMO_QUERY,
  payload,
});

export const setDemoQuery = (payload: string) => ({
  type: Actions.DEMO_QUERY,
  payload,
});

export const setAllowCmdExc = (payload: boolean) => ({
  type: Actions.ALLOW_CMD_EXEC,
  payload,
});
