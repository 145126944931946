export enum Experience {
  USER = 'USER',
  OPERATIONS = 'OPERATIONS',
}

export enum RequestType {
  CREATE_USER = 'Create User',
  CREATE_PROJECT = 'Create Project',
  PROJECT_ACCESS = 'Project Access',
  CREATE_WAREHOUSE = 'Create Warehouse',
  WAREHOUSE_ACCESS = 'Warehouse Access',
}

export type FormField = {
  label: string;
  id: string;
  name?: string;
  value?: string[] | number | string;
  type?: string;
  required?: boolean;
  touched?: boolean;
  empty?: boolean;
};

export type ProvisionForm = {
  name: string;
  group: string;
  fields: FormField[];
};
