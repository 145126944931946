import { LoadingIndicator } from '@toolkit/common';
import { SnackbarProvider } from 'notistack';
import React, { lazy, Suspense } from 'react';
import {
  Outlet,
  Route,
  useLoaderData,
  useOutletContext,
} from 'react-router-dom';
import { setSourceTargetList } from './app/actions';
import AppHeader from './app/header';
import { SqlMorphContext } from './app/reducer';
import { demoQueryLoader } from './components/demo/DemoQueries';
import * as Paths from './constants/paths';
import { getSourcesTargets } from './services/api';

const Settings = lazy(() => import('./components/settings/Settings'));
const Editor = lazy(() => import('./components/editor/Editor'));
const SupportStatements = lazy(
  () => import('./components/support-statements/SupportStatements')
);
const Demo = lazy(() => import('./components/demo/DemoQueries'));
const About = lazy(() => import('./components/about/About'));

type ContextType = {
  isDemoMode: boolean;
};

interface Props {
  isDemoMode: boolean;
}

const SQLMorphRouting = ({ isDemoMode = false }: Props) => {
  const { dispatch } = React.useContext(SqlMorphContext);
  const sourceTargetList = useLoaderData() as string;
  const [loading, startTransition] = React.useTransition();

  React.useEffect(() => {
    startTransition(() => {
      dispatch(setSourceTargetList(sourceTargetList));
    });
  }, []);

  if (loading) {
    return (
      <LoadingIndicator fullScreen={true} title="Loading application..." />
    );
  }

  return (
    <Suspense
      fallback={
        <LoadingIndicator fullScreen={true} title="Loading application..." />
      }
    >
      <SnackbarProvider data-testid="notistack-snackbar" maxSnack={5}>
        <Outlet context={{ isDemoMode }} />
      </SnackbarProvider>
    </Suspense>
  );
};

export default SQLMorphRouting;

export const SQLMorphRouter = (
  <Route element={<AppHeader />}>
    <Route path={Paths.demo}>
      <Route path={Paths.translate} element={<Editor />}>
        <Route path={':id'} element={<Editor />} />
      </Route>
      <Route
        path={Paths.supportStatements}
        element={<SupportStatements />}
        shouldRevalidate={() => false}
      />
      <Route
        path={Paths.demoQueries}
        element={<Demo />}
        loader={demoQueryLoader}
        shouldRevalidate={() => false}
      />
      <Route path={Paths.about} element={<About />} />
    </Route>
    <Route index element={<Editor />} />
    <Route path={Paths.translate} element={<Editor />}>
      <Route path={':id'} element={<Editor />} />
    </Route>
    <Route
      path={Paths.supportStatements}
      element={<SupportStatements />}
      shouldRevalidate={() => false}
    />
    <Route
      path={Paths.demoQueries}
      element={<Demo />}
      loader={demoQueryLoader}
      shouldRevalidate={() => false}
    />
    <Route path={Paths.settings} element={<Settings />} />
    <Route path={Paths.about} element={<About />} />
  </Route>
);

export function sqlMorphLoader(): Promise<string> {
  const sourcesTargets = getSourcesTargets();
  return sourcesTargets;
}

export function getSQLMorphOutletContext() {
  return useOutletContext<ContextType>();
}
