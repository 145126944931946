import { User } from '@auth0/auth0-react';
import { AnalyticsLibraryProps } from 'Common/interfaces';
import { createContext, useReducer } from 'react';
import { Action, AlgoliaConfig, Notification } from '../common/interfaces';
import { Actions } from './actions';

export interface ModalState {
  open?: boolean;
  title?: JSX.Element | string;
  body: JSX.Element | string;
  onClose?: (e: React.MouseEvent) => void;
}

const modalInitialState: ModalState = {
  open: false,
  title: '',
  body: '',
  onClose: undefined,
};

interface CommonReducerState {
  modal: ModalState;
  isLeftNavOpen: boolean;
  dashboards: AnalyticsLibraryProps[];
  detailFlags: { [key: string]: boolean };
  userDetails: User;
  isDevEnv: boolean;
  searchText: string;
  algoliaCredentials: AlgoliaConfig;
  metaTagTitle: string;
  isInterceptorReady: boolean;
}

export const initialState: CommonReducerState = {
  modal: modalInitialState,
  isLeftNavOpen: true,
  dashboards: [],
  detailFlags: {},
  userDetails: null,
  isDevEnv: window.location.host === 'toolkit-dev.phdata.io',
  searchText: '',
  algoliaCredentials: null,
  metaTagTitle: null,
  isInterceptorReady: false,
};

const CommonReducer = (
  state: CommonReducerState = initialState,
  action: Action
): CommonReducerState => {
  switch (action.type) {
    case Actions.DASHBOARD_LIST:
      return {
        ...state,
        dashboards: action.payload,
      };
    case Actions.TOGGLE_LEFT_NAV:
      return {
        ...state,
        isLeftNavOpen: action.payload,
      };
    case Actions.OPEN_MODAL:
      return {
        ...state,
        modal: {
          open: true,
          title: action.payload.title,
          body: action.payload.body,
          onClose: action.payload.onClose,
        },
      };
    case Actions.CLOSE_MODAL:
      return {
        ...state,
        modal: modalInitialState,
      };
    case Actions.DETAIL_FLAGS:
      return {
        ...state,
        detailFlags: action.payload,
      };
    case Actions.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case Actions.SET_IS_DEV_ENV:
      return {
        ...state,
        isDevEnv: action.payload,
      };
    case Actions.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case Actions.SET_ALGOLIA_CREDENTIALS:
      return {
        ...state,
        algoliaCredentials: action.payload,
      };
    case Actions.SET_META_TAG_TITLE:
      return {
        ...state,
        metaTagTitle: action.payload,
      };
    case Actions.SET_IS_INTERCEPTOR_READY:
      return {
        ...state,
        isInterceptorReady: action.payload,
      };
    default:
      return state;
  }
};

type CommonContextDefinition = {
  state: CommonReducerState;
  dispatch: React.Dispatch<Action>;
  addNotification: (notification: Notification) => void;
};

export const CommonContext = createContext<CommonContextDefinition>({
  state: initialState,
  dispatch: () => undefined,
  addNotification: () => undefined,
});

export const useCommonReducer = () => useReducer(CommonReducer, initialState);
