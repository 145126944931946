import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { LoadingIndicator } from '@toolkit/common';
import * as React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export default function SignUp() {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    } else {
      loginWithRedirect({
        authorizationParams: { screen_hint: 'signup' },
      } as RedirectLoginOptions);
    }
  }, [location]);

  return (
    <LoadingIndicator fullScreen={true} title="Loading Sign Up page ..." />
  );
}
