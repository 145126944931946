import * as React from 'react';
import { MenuBookOutlined } from '@mui/icons-material';
import * as Paths from '@toolkit/router/paths';
import PageNotFound from 'Common/components/pageNotFound';
import { getAsciidoc } from 'Common/services/api';
import { delay } from 'lodash';
import { Location, useLocation } from 'react-router-dom';
import ParseAsciidoc from 'Common/components/ParseAsciidoc';
import RenderAsciidoc from 'Common/components/RenderAsciidoc';

interface Props {
  pathName?: string;
}

const ToolsWrapper: React.FC<Props> = (props: Props) => {
  const tocRefs = React.useRef({});
  const location: Location = useLocation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [template, setTemplate] = React.useState<React.ReactNode | null>(null);

  React.useEffect(() => {
    setIsLoading(true);
    handleAsciidoc(props.pathName || location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    let selectedTOC = null;
    const route: string = location.hash.replace('#', '');
    if (template) {
      if (route && tocRefs[route]) {
        selectedTOC = route;
      } else {
        selectedTOC = Object.keys(tocRefs?.current)[0];
      }
      if (selectedTOC) {
        delay(() => {
          tocRefs[selectedTOC]?.scrollIntoView({
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [location.hash, template]);

  async function handleAsciidoc(docName: string) {
    setTemplate(null);
    const response = await getAsciidoc(`${docName}.html`);
    if (response.status === 404) {
      setTemplate(pageNotFound());
    } else {
      setTemplate(<ParseAsciidoc text={response} ref={tocRefs} />);
    }
    setIsLoading(false);
  }

  function pageNotFound(): React.ReactElement {
    return (
      <PageNotFound page="/" path={Paths.Home} icon={<MenuBookOutlined />} />
    );
  }

  return <RenderAsciidoc isLoading={isLoading} template={template} />;
};

export default ToolsWrapper;
