import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Paths from '@toolkit/router/paths';
import { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { generateCopyButton } from 'Common/components/AsciidocUtil';
import { CommonContext } from 'Common/common-ui';
import { BrandColors } from 'Common/common-ui/common/theme';

export default function OverrideDocumentStyles(
  domNode,
  ref,
  openImageInDialog
): JSX.Element {
  const {
    state: { isDevEnv },
  } = React.useContext(CommonContext);
  const nodeName = domNode.name;
  const matches: boolean = useMediaQuery(useTheme().breakpoints.down('sm'));

  if (nodeName === 'div' && domNode?.attribs?.id === 'header') {
    if (
      domNode.childNodes[1].name === 'h1' &&
      domNode.childNodes[1].children[0].data === 'Documentation'
    ) {
      return <></>;
    } else {
      return (
        <>
          <Typography variant="h1" fontWeight="bold" ml={4}>
            {domToReact(domNode.childNodes[1].children)}
          </Typography>
          {domNode.childNodes.some((el) => el?.attribs?.id === 'toc') && (
            <Box
              id="toc"
              className="toc2"
              sx={{
                '&#toc.toc2': {
                  top: isDevEnv ? '105px !important' : '55px !important',
                },
              }}
              displayPrint="none"
            >
              {domToReact(domNode.children)}
            </Box>
          )}
        </>
      );
    }
  }

  if (nodeName === 'div' && domNode?.attribs?.id === 'toc') {
    /* To hide toc content in small screen */
    if (matches) {
      return <></>;
    }
  }
  if (generateCopyButton(domNode)) {
    return generateCopyButton(domNode);
  }
  if (nodeName === 'a') {
    const href = domNode.attribs?.href;
    const target = domNode.attribs?.target;
    if (
      !href?.startsWith('/toolkit/docs') &&
      !href?.match(/^(http:|https:)/) &&
      target !== '_blank' &&
      href?.includes('.html')
    ) {
      const routeLink = `${Paths.Documentation}/${href?.replace('.html', '')}`;
      return <Link to={routeLink}>{domToReact(domNode.children)}</Link>;
    }
  }
  if (
    nodeName === 'h1' ||
    nodeName === 'h2' ||
    nodeName === 'h3' ||
    nodeName === 'h4' ||
    nodeName === 'h5' ||
    nodeName === 'h6'
  ) {
    return (
      <Typography
        id={domNode.attribs.id}
        variant={nodeName}
        color={BrandColors.Primary}
        fontWeight="500"
        ref={(htmlRef: HTMLHeadingElement) => {
          ref[domNode.attribs.id] = htmlRef;
        }}
        noWrap
        my={1}
      >
        {domToReact(domNode.children)}
      </Typography>
    );
  }

  if (
    (nodeName === 'div' && domNode.attribs.class === 'imageblock') ||
    (nodeName === 'span' && domNode.attribs.class === 'image')
  ) {
    let imgAttributes: { src: string; alt: string } =
      domNode.childNodes[0]?.attribs;
    if (nodeName === 'div') {
      if (domNode.childNodes[1]?.childNodes[1]?.attribs.href) {
        return (
          <Link to={`${domNode.childNodes[1]?.childNodes[1]?.attribs.href}`}>
            {domToReact(domNode.children)}
          </Link>
        );
      } else {
        imgAttributes = domNode.childNodes[1]?.childNodes[1]?.attribs;
      }
    }
    return (
      <Box
        component={'a'}
        onClick={() => openImageInDialog(imgAttributes)}
        sx={{
          display: 'block',
          cursor: 'pointer',
          boxShadow: !imgAttributes?.src
            ? 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
            : 'none',
          p: 1,
          mb: 2,
        }}
      >
        {domToReact(domNode.children)}
      </Box>
    );
  }
}
