import { styled } from '@mui/material/styles';
import { BrandColors } from 'Common/common-ui/common/theme';
import { Tab, Tabs } from '@mui/material/';

export const TealTabs = styled(Tabs)({
  '& .Mui-selected': {
    color: BrandColors.Teal,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: BrandColors.Teal,
  },
});

export const TealTab = styled(Tab)({
  '&.Mui-selected': {
    color: BrandColors.Teal,
  },
});
