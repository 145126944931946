import { SnackbarOrigin, useSnackbar } from 'notistack';
import React from 'react';
import { CommonContext, useCommonReducer } from '../app-state/reducer';
import {
  Notification,
  NotificationLevel,
  NotificationStatus,
} from '../common/interfaces';
import './styles.scss';

const AppBaseInternal: React.FC<{ children: any }> = (props: {
  children: any;
}) => {
  const [state, dispatch] = useCommonReducer();
  const { enqueueSnackbar } = useSnackbar();
  const addNotification = (notification: Notification) => {
    const status = notification.status || NotificationStatus.DEFAULT;
    const level = notification.level || NotificationLevel.MAJOR;
    const origin: SnackbarOrigin =
      level === NotificationLevel.MAJOR
        ? { horizontal: 'right', vertical: 'top' }
        : { horizontal: 'center', vertical: 'bottom' };
    enqueueSnackbar(notification.message, {
      variant: status,
      anchorOrigin: origin,
    });
  };

  return (
    <CommonContext.Provider value={{ state, dispatch, addNotification }}>
      {props.children}
    </CommonContext.Provider>
  );
};

export default AppBaseInternal;
