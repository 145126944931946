import Info from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, Box, Tooltip } from '@mui/material';
import { CommonContext } from 'Common/common-ui';
import { setIsDevEnv } from 'Common/common-ui/app-state/actions';
import * as React from 'react';

const DevSiteBanner: React.FC = () => {
  const { dispatch } = React.useContext(CommonContext);

  return (
    <Alert
      onClose={() => dispatch(setIsDevEnv(false))}
      variant="filled"
      color="warning"
      icon={<WarningAmberIcon />}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Box>
          This site is intended for development purposes only and should not be
          used in production or by customers. The content and functionality may
          change or break at any time.
        </Box>
        <Box ml={1}>
          <Tooltip
            title={
              'This is a development site and may contain unstable or incomplete features. Use at your own risk.'
            }
          >
            <Info />
          </Tooltip>
        </Box>
      </Box>
    </Alert>
  );
};

export default DevSiteBanner;
