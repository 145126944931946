import { Box, Typography } from '@mui/material';
import { PageHeaders } from './PageHeader';
import { BrandColors } from 'Common/common-ui/common/theme';
import React from 'react';
import ToolHeader from 'src/resources/images/tool-page-header.jpg';

export default function PageHeaderLayout(props: { header: PageHeaders }) {
  return (
    <div
      style={{
        backgroundImage: `url(${ToolHeader})`,
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        alignContent: 'center',
        height: '310px',
        width: '100% !important',
      }}
    >
      <Box sx={{ pl: 5, ml: 3 }}>
        <Typography
          sx={{
            fontSize: '48px !important',
            fontWeight: 600,
            color: BrandColors.White,
            letterSpacing: 1,
            mb: 0.5,
          }}
        >
          {props.header.header}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            mb: 3,
            color: BrandColors.Mint,
            fontWeight: 500,
            fontSize: 30,
          }}
        >
          {props.header.subHeader}
        </Typography>
        <Typography sx={{ color: BrandColors.White, width: '60%' }}>
          {props.header.description}
        </Typography>
      </Box>
    </div>
  );
}
