export interface Course {
  id: string;
  topic: string;
  title: string;
  description: string;
  durationMinutesFast: number;
  durationMinutesSlow: number;
}

export const courses: Array<Course> = [
  {
    title: 'SQL Basics',
    topic: 'sql',
    id: 'sql-basics', // id should match the name of the course directory in the public folder
    description:
      'By the end of this course, you should be able to explain what SQL is and why it is valuable in a data and analytics environment, apply the most common SQL keywords in a simple query and analyze a query for potential performance-hindering issues.',
    durationMinutesFast: 45,
    durationMinutesSlow: 75,
  },
];
