import { styled } from '@mui/material';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import React from 'react';

const Search = styled('div')(({ theme }) => ({
  border: '1px solid #e6e6e6',
  borderRadius: theme.shape.borderRadius,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

type FilterTextProp = {
  searchText: string;
  placeHolder: string;
  onInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
    reason: string
  ) => void;
};

const FilterText: React.FC<FilterTextProp> = (props: FilterTextProp) => {
  return (
    <Box display="flex" width={'100%'} mt={2} mb={2}>
      <Autocomplete
        freeSolo
        fullWidth
        value={props.searchText}
        options={[]}
        onInputChange={props.onInputChange}
        closeText={'Close'}
        sx={{ maxWidth: 550, width: '100%' }}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <Search>
              <StyledInputBase
                autoFocus
                sx={{ width: '100%' }}
                placeholder={props.placeHolder}
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
              />
            </Search>
          );
        }}
      />
    </Box>
  );
};

export default FilterText;
