import PersonIcon from '@mui/icons-material/Person';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { setExperience } from '../contexts/actions';
import { useProvisionDemoContext } from '../contexts/reducer';
import { Experience } from './domain';

export default function ToggleExperience() {
  const {
    state: { experience },
    dispatch,
  } = useProvisionDemoContext();
  function handleChangeExperience(
    event: React.MouseEvent<HTMLElement>,
    newExperience: Experience
  ) {
    dispatch(setExperience(newExperience));
  }

  return (
    <ToggleButtonGroup
      value={experience}
      exclusive
      onChange={handleChangeExperience}
      color="primary"
    >
      <ToggleButton sx={{ paddingY: 1.5, paddingX: 3 }} value={Experience.USER}>
        <Tooltip title="User Experience">
          <Box
            sx={{ padding: 0, margin: 0 }}
            display={'flex'}
            alignItems={'center'}
          >
            <PersonIcon sx={{ marginRight: 1 }} /> User
          </Box>
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        sx={{ padding: 1, paddingX: 3 }}
        value={Experience.OPERATIONS}
      >
        <Tooltip title="Ops Experience">
          <Box
            sx={{ padding: 0, margin: 0 }}
            display={'flex'}
            alignItems={'center'}
          >
            <SettingsSuggestIcon sx={{ marginRight: 1 }} />
            Ops
          </Box>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
