import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { BrandColors } from 'Common/common-ui/common/theme';
import TealLink from 'Common/components/TealLink';
import { UpdateTagType } from 'Common/enums';
import { updateTagColor } from 'Common/util';
import { domToReact } from 'html-react-parser';
import { generateCopyButton } from 'Common/components/AsciidocUtil';

export const RELEASE_NOTES: string[] = ['Latest', '2023', '2022', '2021'];

export default function OverrideReleaseNotesStyles(
  domNode,
  options,
  ref
): JSX.Element {
  const nodeName = domNode.name;
  if (generateCopyButton(domNode)) {
    return generateCopyButton(domNode);
  }
  if (nodeName === 'div' && domNode?.attribs?.class === 'sect1') {
    return (
      <Box sx={{ boxShadow: 1, p: 2, pl: 3, pt: 1, mb: 2 }}>
        {domToReact(domNode.children, options)}
      </Box>
    );
  }
  if (nodeName === 'h2' && domNode?.children[0]?.attribs?.class === 'anchor') {
    return (
      <Typography
        id={domNode.attribs.id}
        variant={'h3'}
        fontWeight="500"
        ref={(htmlRef: HTMLHeadingElement) => {
          ref[domNode.attribs.id] = htmlRef;
        }}
        noWrap
        sx={{
          '& hr': {
            m: 1,
            mx: -2,
          },
        }}
      >
        {domToReact(domNode.children, options)}
      </Typography>
    );
  }
  if (
    (nodeName === 'h3' || nodeName === 'h4') &&
    domNode?.children[0]?.attribs?.class === 'anchor' &&
    Object.values(UpdateTagType).includes(domNode?.children[1]?.data)
  ) {
    return (
      <Chip
        sx={{
          bgcolor: updateTagColor[domNode?.children[1]?.data],
          color:
            domNode?.children[1]?.data === UpdateTagType.ANNOUNCEMENTS ||
            domNode?.children[1]?.data === UpdateTagType.CHANGES
              ? BrandColors.DarkGray
              : 'background.paper',
          fontSize: 16,
          mb: 0.5,
        }}
        label={domNode?.children[1]?.data}
      />
    );
  }
  if (nodeName === 'details' && domNode?.parent?.attribs?.class === 'sect4') {
    return (
      <Box sx={{ ml: 2, mt: -2 }}>
        <details style={{ marginTop: 5, marginBottom: 8 }}>
          {domToReact(domNode.children, options)}
        </details>
      </Box>
    );
  }
  if (nodeName === 'summary' && domNode?.parent?.name === 'details') {
    return (
      <summary style={{ fontSize: 16 }}>
        {domToReact(domNode.children, options)}
      </summary>
    );
  }
  if (
    nodeName === 'h1' ||
    nodeName === 'h2' ||
    nodeName === 'h3' ||
    nodeName === 'h4' ||
    nodeName === 'h5' ||
    nodeName === 'h6'
  ) {
    return (
      <Typography
        id={domNode.attribs.id}
        variant={nodeName}
        color={BrandColors.Primary}
        fontWeight="500"
        ref={(htmlRef: HTMLHeadingElement) => {
          ref[domNode.attribs.id] = htmlRef;
        }}
        noWrap
        my={1}
        mb={domNode.name === 'h5' ? 3 : 1}
      >
        {domToReact(domNode.children)}
      </Typography>
    );
  }
  if (nodeName === 'div' && domNode?.attribs?.id === 'toctitle') {
    return (
      <Typography
        variant="h6"
        color={BrandColors.Primary}
        sx={{
          position: 'fixed',
          height: 65,
          bgcolor: BrandColors.White,
          pt: 2,
          mt: -2,
          width: 280,
        }}
      >
        {domToReact(domNode.children)}
        <Divider />
      </Typography>
    );
  }
  if (nodeName === 'ul' && domNode?.attribs?.class === 'sectlevel1') {
    return (
      <Box
        sx={{
          mt: 6,
          '& li': {
            p: 0.5,
            listStyleType: 'none',
            '&:hover': { textDecoration: 'underline' },
            '&:focus': { textDecoration: 'underline' },
            color: BrandColors.Teal,
          },
        }}
        className="sectlevel1"
      >
        {domToReact(domNode.children)}
        <Divider />
        {RELEASE_NOTES.map((note: string) => (
          <Typography key={note} variant={'body1'} m={0.5} my={1}>
            <TealLink
              to={`/updates${note === 'Latest' ? '' : '?year=' + note}`}
            >
              {note} Updates
            </TealLink>
          </Typography>
        ))}
      </Box>
    );
  }
}
