import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import { CommonContext, NotificationStatus } from 'Common/common-ui';
import { BrandColors } from 'Common/common-ui/common/theme';
import * as React from 'react';
import { useMutation } from 'react-query';
import {
  setFieldValues,
  setFormFields,
  setGroup,
  setSql,
  setTemplate,
} from '../contexts/actions';
import { useProvisionDemoContext } from '../contexts/reducer';
import { PROVISION_FORMS, generateGroupFileContent } from './forms';
import { codeBlockLightGray } from './styles';
import TealButton from 'Common/components/TealButton';
import { getProvisionDemoSQLStatements } from 'Common/services/api';

export type FormField = {
  label: string;
  id: string;
  name?: string;
  value?: string[] | number | string;
  type?: string;
  required?: boolean;
  touched?: boolean;
  empty?: boolean;
};

const DynamicForm: React.FC = () => {
  const {
    state: { requestType, formFields, fieldValues },
    dispatch,
  } = useProvisionDemoContext();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { addNotification } = React.useContext(CommonContext);
  const { mutateAsync: requestProvisionDemoSQLStatements } = useMutation(
    getProvisionDemoSQLStatements,
    {
      onError: () => {
        addNotification({
          message: 'Failed to generate SQL statements',
          status: NotificationStatus.ERROR,
        });
      },
    }
  );

  const disableSubmit: boolean = React.useMemo(() => {
    let shouldDisable = false;
    formFields.forEach((f) => {
      if (f.required && (!fieldValues || !fieldValues[f.name])) {
        shouldDisable = true;
      }
    });
    return shouldDisable;
  }, [formFields, fieldValues]);

  function handleChange(
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent,
    field: FormField
  ) {
    const value: string = event.target.value;
    dispatch(
      setFieldValues(
        fieldValues
          ? { ...fieldValues, [field.name]: value }
          : { [field.name]: value }
      )
    );
    dispatch(
      setFormFields(
        formFields.map((f: FormField) => {
          f.empty = f.name === field.name ? !value.length : f.empty;
          return { ...f };
        })
      )
    );
  }

  function handleClickSubmit(): void {
    let proceedToSubmit = true;
    formFields.forEach((field: FormField) => {
      if (field.required && !(fieldValues && fieldValues[field.name])) {
        proceedToSubmit = false;
      }
    });

    if (proceedToSubmit) {
      setLoading(true);
      submitForm(fieldValues);
    }
  }

  async function submitForm(fields: { [key: string]: string }) {
    for (const f in fields) {
      fields[f] = fields[f].trim();
    }
    const groupType: string = PROVISION_FORMS[requestType].group;
    const groupFileContent = generateGroupFileContent(fields, groupType);

    try {
      const sqlResponse = await requestProvisionDemoSQLStatements({
        ...groupFileContent,
      });
      if (sqlResponse) {
        dispatch(setSql(sqlResponse.statements));
        dispatch(setGroup(sqlResponse.group));
        dispatch(setTemplate(sqlResponse.template));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  function addOptionalText(field: FormField): React.ReactNode {
    return !field.required ? (
      <Typography variant="body2">
        {field.label} <Typography variant="caption">(Optional)</Typography>
      </Typography>
    ) : (
      <Typography variant="body2" component={'span'}>
        {field.label}
      </Typography>
    );
  }

  function validateField(field: FormField): void {
    if (field.required && !(fieldValues && fieldValues[field.name])) {
      dispatch(
        setFormFields(
          formFields.map((f: FormField) => {
            const fToReturn = { ...f };
            if (f.name === field.name) {
              fToReturn['touched'] = true;
              fToReturn['empty'] = true;
            }
            return fToReturn;
          })
        )
      );
    }
  }

  return (
    <Box height={'90%'}>
      <Typography variant="h3">Request Form</Typography>
      <Box
        padding={2}
        marginY={1}
        borderRadius={0.5}
        sx={{
          backgroundColor: codeBlockLightGray,
          height: '100%',
        }}
        component="form"
        noValidate
        autoComplete="off"
      >
        <FormControl
          component="fieldset"
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {formFields.map((field: FormField, index: number) => {
              switch (field.type) {
                case 'picker':
                  return (
                    <FormControl key={index} fullWidth>
                      <FormLabel
                        component="label"
                        required={field.required}
                        error={field.required && field.touched && field.empty}
                        sx={{ mt: 1 }}
                      >
                        {addOptionalText(field)}
                      </FormLabel>
                      <Select
                        labelId="demo-select-label"
                        id="demo-select"
                        name={field.name}
                        variant={'outlined'}
                        value={
                          fieldValues && fieldValues[field.name]
                            ? fieldValues[field.name]
                            : ''
                        }
                        sx={{
                          '& .MuiSelect-select ': {
                            p: '8px 10px',
                            backgroundColor: BrandColors.White,
                          },
                        }}
                        error={field.required && field.touched && field.empty}
                        onChange={(e) => handleChange(e, field)}
                        onBlur={(e) => validateField(field)}
                      >
                        {Array.isArray(field.value) &&
                          field.value?.map((v: string, ind: number) => (
                            <MenuItem key={ind} value={v}>
                              {v}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  );
                case 'email':
                  return (
                    <FormControl key={index} fullWidth>
                      <FormLabel
                        component="label"
                        required={field.required}
                        error={field.required && field.touched && field.empty}
                        sx={{ mt: 1 }}
                      >
                        {addOptionalText(field)}
                      </FormLabel>
                      <TextField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        variant={'outlined'}
                        sx={{
                          '& input': {
                            p: '8px 10px',
                            backgroundColor: BrandColors.White,
                          },
                        }}
                        value={fieldValues ? fieldValues[field.name] : ''}
                        error={field.required && field.touched && field.empty}
                        onChange={(e) => handleChange(e, field)}
                        onBlur={(e) => validateField(field)}
                        required
                      />
                    </FormControl>
                  );
                case 'number':
                  return (
                    <FormControl key={index} fullWidth>
                      <FormLabel
                        component="label"
                        required={field.required}
                        error={field.required && field.touched && field.empty}
                        sx={{ mt: 1 }}
                      >
                        {addOptionalText(field)}
                      </FormLabel>
                      <TextField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        variant={'outlined'}
                        sx={{
                          '& input': {
                            p: '8px 10px',
                            backgroundColor: BrandColors.White,
                          },
                        }}
                        value={
                          fieldValues ? fieldValues[field.name] : field.value
                        }
                        error={field.required && field.touched && field.empty}
                        onChange={(e) => handleChange(e, field)}
                        onBlur={(e) => validateField(field)}
                        required
                      />
                    </FormControl>
                  );
                default:
                  return (
                    <FormControl key={index} fullWidth>
                      <FormLabel
                        component="label"
                        required={field.required}
                        error={field.required && field.touched && field.empty}
                        sx={{ mt: 1 }}
                      >
                        {addOptionalText(field)}
                      </FormLabel>
                      <TextField
                        id={field.id}
                        name={field.name}
                        variant={'outlined'}
                        sx={{
                          '& input': {
                            p: '8px 10px',
                            backgroundColor: BrandColors.White,
                          },
                        }}
                        value={
                          fieldValues && fieldValues[field.name]
                            ? fieldValues[field.name]
                            : ''
                        }
                        error={field.required && field.touched && field.empty}
                        onChange={(e) => handleChange(e, field)}
                        onBlur={(e) => validateField(field)}
                        required
                      />
                    </FormControl>
                  );
              }
            })}
          </Box>
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            sx={{ mt: 3 }}
          >
            <Grid>
              <TealButton
                onClick={handleClickSubmit}
                variant="contained"
                disabled={disableSubmit || loading}
              >
                {'Submit & Approve'}
              </TealButton>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    </Box>
  );
};

export default DynamicForm;
