import { ErrorType, FatalType } from './enums';

export interface MessageAndPosition {
  level: string;
  message: string;
  lineNumber: number | null;
  characterNumber: number | null;
}

export interface Error extends MessageAndPosition {
  errorType: ErrorType;
}

export type Warning = MessageAndPosition;

export type Info = MessageAndPosition;

export interface Fatal extends MessageAndPosition {
  fatalType: FatalType | string;
}

export interface TranslateCompilation {
  compiles: boolean;
  error: string | null;
  supported: boolean;
}

export interface Response {
  sourceStmt: string;
  targetStmt: string;
  statementType: string;
  errors: Error[];
  warnings: Warning[];
  fatals: Fatal[];
  infos: Info[];
  translateCompilation: TranslateCompilation;
}

export interface HighlightContent {
  type: string;
  line: number;
  character: number;
  message: string;
}

export enum ORSFCreateTableVColType {
  physical = 'physical',
  view = 'view',
}

export enum ORSFDatatypeForDate {
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  TIMESTAMP = 'TIMESTAMP',
}

export interface CreateTableSettings {
  orsfCreateTableVColType: ORSFCreateTableVColType;
}

export interface CommonSettings {
  orsfDatatypeForDate: ORSFDatatypeForDate;
}

export interface RequestOptions {
  format: boolean;
  createTableSettings: CreateTableSettings;
  commonSettings: CommonSettings;
}

export interface ConvertRequest {
  statement: string;
  source: string;
  target: string;
  options: RequestOptions;
}

export interface DropdownOption {
  label: string;
  value: string;
}

export interface DemoQuery {
  source: string;
  target: string;
  queries: QueryItem[];
}

export interface QueryItem {
  description?: string;
  sourceStmt: string;
}

export interface ResponseWithStatus {
  response: any;
  status: number;
}

export enum MessageLevel {
  Fatal = 'Fatal',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}

export type MessageLevelType = {
  level: string;
  message: string;
};

export type Messages = { [key: string]: MessageLevelType[] };
