import { Box } from '@mui/material';
import { TableauExtensions } from '@toolkit/router/paths';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import '../synchronized-refresh.scss';
import ProgressBar from './ProgressBar';
import RefreshIntervalPopOver from './RefreshIntervalPopOver';
import RenderTime from './RenderTime';

export interface RefreshProps {
  saveInProgress: boolean;
  setSaveInProgress: (params: boolean) => void;
  getHoursMinutesSeconds: (params: number) => { hours; minutes; seconds };
  extensionPath: string;
}

const Refresh: React.FC<RefreshProps> = (props: RefreshProps) => {
  // refreshValues interval is in seconds
  const [refreshValues, setRefreshValues] = useState<{
    nextRefresh: number | null;
    prevRefresh: number | null;
    refreshInterval: number | null;
  }>({
    nextRefresh: null,
    prevRefresh: null,
    refreshInterval: null,
  });

  const [timeRemaining, setTimeRemaining] = useState<{
    seconds: number | null;
    percentEllapsed: number | null;
  }>({
    seconds: null,
    percentEllapsed: null,
  });

  const [dataSources, setDataSources] = useState([]);
  const [defaultRefreshInterval] = useState<number>(300);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const popOverOpen = Boolean(anchorEl);

  useEffect(() => {
    let unregisterSettingsListener;
    window.tableau?.extensions
      .initializeAsync({
        configure: function configure() {
          const settingsChanged =
            window.tableau?.TableauEventType?.SettingsChanged;
          const settings = window.tableau?.extensions?.settings;

          unregisterSettingsListener = settings.addEventListener(
            settingsChanged,
            (): void => {
              setStateRefreshValues({
                prevRefresh: parseInt(
                  window.tableau?.extensions?.settings.get('prevRefresh')
                ),
                nextRefresh: parseInt(
                  window.tableau?.extensions?.settings.get('nextRefresh')
                ),
                refreshInterval: parseInt(
                  window.tableau?.extensions?.settings.get('refreshInterval')
                ),
              });
            }
          );

          const popupUrl =
            window.location.origin +
            `${TableauExtensions}/${props.extensionPath}/dialog`;

          window.tableau?.extensions?.ui
            .displayDialogAsync(popupUrl, '', { width: 580, height: 435 })
            .then((m) => console.log('closed payload: ', m));
        },
      })
      .then(() => {
        // see if value is already set for the next refresh
        const prev = window.tableau?.extensions?.settings.get('prevRefresh')
          ? parseInt(window.tableau?.extensions?.settings.get('prevRefresh'))
          : null;
        const next = window.tableau?.extensions?.settings.get('nextRefresh');
        const interval = window.tableau?.extensions?.settings.get(
          'refreshInterval'
        )
          ? parseInt(
              window.tableau?.extensions?.settings.get('refreshInterval')
            )
          : defaultRefreshInterval;

        const newSettingsValues = {
          prevRefresh: prev,
          nextRefresh:
            !next || parseInt(next) <= Date.now()
              ? Date.now() + interval * 1000
              : parseInt(next),
          refreshInterval: interval,
        };

        if (!next || !interval) {
          saveSettings(newSettingsValues);
        }

        setStateRefreshValues(newSettingsValues);

        // Promise.all causes us to wait until we've added all datasources
        // to dataSourceFetchPromises until it loops through them
        // then use dashboardDataSources to keep track of the datasources we've already seen
        const dataSourceFetchPromises = [];
        const dashboardDataSources = {};
        const dashboard =
          window.tableau?.extensions?.dashboardContent?.dashboard;

        // Then loop through each worksheet and get its dataSources, save promise for later.
        dashboard.worksheets.forEach(function (worksheet) {
          dataSourceFetchPromises.push(worksheet.getDataSourcesAsync());
        });

        Promise.all(dataSourceFetchPromises).then((fetchResults) => {
          fetchResults.forEach((dataSourcesForWorkSheet) => {
            dataSourcesForWorkSheet.forEach((dataSource) => {
              if (!dashboardDataSources[dataSource.id]) {
                // We've already seen it, skip it.
                dashboardDataSources[dataSource.id] = dataSource;
              }
            });
          });

          Promise.all(Object.values(dashboardDataSources)).then(
            (dataSourceValues) => {
              setDataSources(dataSourceValues);
            }
          );
        });
      });
    if (unregisterSettingsListener) {
      return unregisterSettingsListener();
    }
  }, []);

  useEffect(() => {
    if (refreshValues.nextRefresh && refreshValues.nextRefresh > Date.now()) {
      // every second, compare this moment to the next refresh date time object
      // and reset the time remaining
      const interval = setInterval(() => {
        setTimeRemaining((prevVals) => {
          const vals = JSON.parse(JSON.stringify(prevVals));
          vals.seconds = moment(refreshValues.nextRefresh).diff(
            moment(),
            'seconds'
          );
          vals.percentEllapsed =
            vals.seconds <= refreshValues.refreshInterval
              ? Math.floor((vals.seconds / refreshValues.refreshInterval) * 100)
              : 100;
          return vals;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [refreshValues]);

  useEffect(() => {
    // when time remaining runs out set save in progress
    // when save in progress is true extension settings will be updated
    if (timeRemaining.seconds <= 0 && timeRemaining.seconds !== null) {
      props.setSaveInProgress(true);
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (props.saveInProgress) {
      const prev = refreshValues.nextRefresh;
      const next =
        refreshValues.nextRefresh + refreshValues.refreshInterval * 1000;
      const newSettingsValues = {
        prevRefresh: prev.toString(),
        nextRefresh: next.toString(),
        refreshInterval: null,
      };
      saveSettings(newSettingsValues).then(() => {
        setStateRefreshValues(newSettingsValues);
        dataSources.forEach((dataSource) => {
          dataSource.refreshAsync().then(() => {
            props.setSaveInProgress(false);
          });
        });
      });
    }
  }, [props.saveInProgress]);

  function saveSettings(settings: object): Promise<object> {
    for (const setting in settings) {
      if (settings[setting]) {
        window.tableau?.extensions?.settings.set(
          setting,
          settings[setting].toString()
        );
      }
    }
    return window.tableau?.extensions?.settings.saveAsync();
  }

  function setStateRefreshValues(values: object): void {
    setRefreshValues((prevVals) => {
      const vals = JSON.parse(JSON.stringify(prevVals));
      for (const value in values) {
        if (values[value]) {
          vals[value] = parseInt(values[value]);
        }
      }
      return vals;
    });
  }

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose(): void {
    setAnchorEl(null);
  }

  return (
    <Box
      aria-owns={popOverOpen ? 'mouse-over-popover' : undefined}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{ overflow: 'hidden', paddingX: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ textAlign: 'center' }} className="last-refresh-label">
          Last refresh was:
        </Box>
        <Box sx={{ textAlign: 'center' }} className="last-refresh-text">
          {refreshValues.prevRefresh
            ? moment(refreshValues.prevRefresh).format('MMM Do YY, h:mm:ss a')
            : 'No Previous Refresh'}
        </Box>
        <Box
          sx={{ textAlign: 'center', fontWeight: 'bold' }}
          className="last-refresh-text-short"
        >
          {refreshValues.prevRefresh
            ? moment(refreshValues.prevRefresh).format('MM/DD/YY hh:mm a')
            : 'No Previous Refresh'}
        </Box>
        {window.innerHeight < 45 && window.innerWidth > 200 && (
          <RefreshIntervalPopOver
            handlePopoverClose={handlePopoverClose}
            open={popOverOpen}
            anchorEl={anchorEl}
            interval={refreshValues.refreshInterval}
            getHoursMinutesSeconds={props.getHoursMinutesSeconds}
          />
        )}
        <Box sx={{ width: 1 }}>
          <Box className="progress-phdata">
            <ProgressBar
              sx={{ marginY: 0.5, marginX: 0.5 }}
              className="hidden-custom-progress"
              variant="determinate"
              value={100 - timeRemaining.percentEllapsed}
            />
          </Box>
          <RenderTime
            getHoursMinutesSeconds={props.getHoursMinutesSeconds}
            remainingTime={timeRemaining.seconds}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Refresh;
