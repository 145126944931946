import * as React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useInstantSearch } from 'react-instantsearch';
import { BrandColors } from 'Common/common-ui/common/theme';
import FindInPageIcon from '@mui/icons-material/FindInPage';

interface Props {
  children: React.ReactElement;
  fallback: React.ReactElement;
}

export function NoResultsBoundary({
  children,
  fallback,
}: Props): React.ReactElement {
  const { results } = useInstantSearch();
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <Box display="flex" justifyContent="center">
        {fallback}
        <Box hidden>{children}</Box>
      </Box>
    );
  }
  return children;
}

export function NoResults(): React.ReactElement {
  const { indexUiState } = useInstantSearch();
  return (
    <Box
      display="flex"
      flexDirection="column"
      py={2}
      position="absolute"
      top="35%"
      textAlign="center"
    >
      <Box>
        <FindInPageIcon sx={{ fontSize: 120, color: BrandColors.Secondary }} />
      </Box>
      <Typography variant="subtitle1" color={BrandColors.Primary} px={2}>
        No results for
        <Typography component={'strong'} fontWeight="bold" ml={0.5}>
          "{indexUiState.query}"
        </Typography>
      </Typography>
    </Box>
  );
}
