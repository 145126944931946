import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { PageHeader } from 'Common/components/PageHeader';

export interface AboutToolWrapperProps {
  pageHeaderPath: string;
  leftChild: JSX.Element;
  rightChild: JSX.Element;
  dataTestId: string;
}

export default function AboutToolWrapper(props: AboutToolWrapperProps) {
  const { pageHeaderPath, leftChild, rightChild, dataTestId } = props;
  return (
    <Container disableGutters={true} maxWidth={false}>
      <Grid container data-testid={dataTestId}>
        <Grid size={{ xs: 12 }}>
          <PageHeader type={pageHeaderPath} />
        </Grid>
        <Grid sx={{ px: 5, py: 6 }}>
          <Grid container>
            <Grid size={{ sm: 12, md: 6 }}>{leftChild}</Grid>
            <Grid size={{ sm: 12, md: 6 }} mb={3}>
              {rightChild}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
