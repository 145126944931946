export enum ErrorType {
  RENDER_ERROR = 'RENDER_ERROR',
  VISIT_ERROR = 'VISIT_ERROR',
  REWRITE_ERROR = 'REWRITE_ERROR',
}

export enum FatalType {
  PARSE = 'PARSE',
  REWRITE = 'REWRITE',
  RENDER = 'RENDER',
  VISIT = 'VISIT',
  RUNTIME = 'RUNTIME',
}

export enum ExceptionCategory {
  FATAL = 'FATAL',
  ERROR = 'ERROR',
  WARN = 'WARN',
}
