import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as Paths from '@toolkit/router/paths';
import { CommonContext, closeModal } from 'Common/common-ui';
import { LoadingIndicator } from '@toolkit/common';
import { BrandColors } from 'Common/common-ui/common/theme';
import React from 'react';
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

export const ACTIVATION_MESSAGES: { [key: string]: string } = {
  ACTIVATION_SUCCESS:
    'Your email was verified. You can continue using the application.',
  ACTIVATION_EXPIRED: 'Access expired.',
  ALREADY_ACTIVATED: 'This URL can be used only once',
  ALREADY_VERIFIED: 'This account is already verified.',
  ACTIVATION_FAILED: '',
};

const ActivateUser: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const [searchParams] = useSearchParams();
  const isIncludesParams: boolean = searchParams.has('message');
  const massage: string = isIncludesParams && searchParams.get('message');
  const { dispatch } = React.useContext(CommonContext);

  if (isLoading) {
    return (
      <LoadingIndicator
        fullScreen={true}
        title="Loading user activation page..."
      />
    );
  }

  function handleContinue(): void {
    if (
      !isAuthenticated &&
      (massage === ACTIVATION_MESSAGES.ALREADY_ACTIVATED ||
        massage === ACTIVATION_MESSAGES.ALREADY_VERIFIED ||
        massage === ACTIVATION_MESSAGES.ACTIVATION_SUCCESS)
    ) {
      const options: RedirectLoginOptions = {
        appState: { returnTo: Paths.Home },
      };
      loginWithRedirect(options);
    } else {
      dispatch(closeModal());
      if (isAuthenticated) {
        navigate(Paths.Home);
      } else {
        navigate(Paths.Support);
      }
    }
  }

  function getMessages(): string {
    if (
      massage === ACTIVATION_MESSAGES.ALREADY_ACTIVATED ||
      massage === ACTIVATION_MESSAGES.ALREADY_VERIFIED
    ) {
      return 'User Is Already Activated';
    } else if (massage === ACTIVATION_MESSAGES.ACTIVATION_SUCCESS) {
      return 'User Activation Successful';
    } else if (massage === ACTIVATION_MESSAGES.ACTIVATION_EXPIRED) {
      return 'User Activation Link Expired';
    } else {
      return 'User Activation Failed';
    }
  }

  function getStatusIcon(): React.ReactNode {
    if (
      massage === ACTIVATION_MESSAGES.ALREADY_ACTIVATED ||
      massage === ACTIVATION_MESSAGES.ALREADY_VERIFIED ||
      massage === ACTIVATION_MESSAGES.ACTIVATION_SUCCESS
    ) {
      return (
        <CheckCircleIcon sx={{ fontSize: 100, color: BrandColors.DarkGreen }} />
      );
    } else {
      return <CancelIcon sx={{ fontSize: 100, color: BrandColors.Red }} />;
    }
  }

  return (
    <Box
      display={'flex'}
      justifyContent="center"
      data-testid="activate-user"
      minWidth={420}
      padding={'20px'}
    >
      {isIncludesParams && (
        <Box
          display={'flex'}
          justifyContent="center"
          flexDirection={'column'}
          textAlign={'center'}
          alignItems="center"
        >
          {getStatusIcon()}
          <Typography variant="h6" mt={2}>
            {getMessages()}
          </Typography>
          {massage !== ACTIVATION_MESSAGES.ALREADY_ACTIVATED &&
            massage !== ACTIVATION_MESSAGES.ACTIVATION_SUCCESS &&
            massage !== ACTIVATION_MESSAGES.ALREADY_VERIFIED && (
              <Typography variant="button" mt={3} color={BrandColors.Gray}>
                Contact phData Toolkit Support for assistance.
              </Typography>
            )}
          <Box sx={{ mt: 3 }}>
            <Button
              data-testid="close-btn"
              variant="contained"
              size="small"
              sx={{ minWidth: 100 }}
              color={'primary'}
              onClick={handleContinue}
            >
              {massage !== ACTIVATION_MESSAGES.ALREADY_ACTIVATED &&
              massage !== ACTIVATION_MESSAGES.ALREADY_VERIFIED &&
              massage !== ACTIVATION_MESSAGES.ACTIVATION_SUCCESS
                ? 'Continue'
                : 'Close'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ActivateUser;
