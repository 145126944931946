import React from 'react';
import { Link as MUILink } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BrandColors } from 'Common/common-ui/common/theme';
import { Link } from 'react-router-dom';

const MyLink = (props) => <MUILink component={Link} {...props} />;

const TealLink = styled(MyLink)(() => ({
  color: BrandColors.Teal,
  textDecorationColor: BrandColors.Teal,
  '&:hover': {
    color: BrandColors.TealHover,
    textDecorationColor: BrandColors.TealHover,
  },
}));

export default TealLink;
