import { Typography, Card, CardMedia, CardActionArea } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';
import bgSvg from './assets/training-enablement-bg.svg';
import React from 'react';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import * as paths from '../../../router/paths';
import TealButton from 'Common/components/TealButton';

export default function MoreCoursesCard() {
  return (
    <Card sx={{ height: 380 }}>
      <CardActionArea
        onClick={() => window.open(paths.TrainingEnablement, '_blank')}
        sx={{ height: '100%' }}
      >
        <CardMedia
          sx={{ height: '100%', pl: 2, pt: 4, position: 'relative' }}
          image={bgSvg}
        >
          <TealButton
            sx={{
              borderRadius: '4px 0px 0px 4px !important',
              position: 'absolute',
              top: 20,
              right: 0,
            }}
            variant="contained"
            endIcon={<LaunchOutlinedIcon />}
          >
            Learn More
          </TealButton>
          <Typography
            sx={{ pt: 5, color: BrandColors.White, fontWeight: 700 }}
            gutterBottom
            variant="h5"
            component="div"
          >
            {'Training &'} <br /> {'Enablement'}
            <Typography
              sx={{ pt: 1, color: BrandColors.Mint, width: '60%' }}
              gutterBottom
            >
              Click to learn more about our training and enablement services.
            </Typography>
          </Typography>
        </CardMedia>
      </CardActionArea>
    </Card>
  );
}
