import React, { useContext, useState } from 'react';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  CommonContext,
  InlineLoadingIndicator,
  NotificationStatus,
} from 'Common/common-ui';
import {
  DownloadInfo,
  authorizeDownload,
  listRepos,
} from 'Common/services/api';
import { useMutation, useQuery } from 'react-query';
import PasswordField from './PasswordField';

export default function DownloadAuthorizer() {
  const {
    state: { isInterceptorReady },
    addNotification,
  } = useContext(CommonContext);
  const [downloadInfo, setDownloadInfo] = useState<DownloadInfo | null>(null);
  const [repo, setRepo] = useState<string>('');
  const [curlCommand, setCurlCommand] = useState<string>('');

  const {
    isLoading,
    data: repoData,
    error: repoListError,
    refetch,
  } = useQuery('listRepos', listRepos, { enabled: false });

  React.useEffect(() => {
    if (isInterceptorReady) {
      refetch();
    }
  }, [refetch, isInterceptorReady]);

  const {
    mutateAsync: authorizeDownloadAsync,
    isLoading: isAuthorizingDownload,
  } = useMutation(
    (e: { repo: string; days: number }) => authorizeDownload(e.repo, e.days),
    {
      onSuccess: (data: DownloadInfo) => {
        setDownloadInfo(data);
        setCurlCommand(`curl -fO ${data.downloadUrl}`);
      },
      onError: () => {
        addNotification({
          message: 'Failed to authorize tool download',
          status: NotificationStatus.ERROR,
        });
      },
    }
  );

  function downloadPackage() {
    const element = document.createElement('a');
    element.href = downloadInfo.downloadUrl;
    element.download = downloadInfo.fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  function changeRepo(repoName: string) {
    setRepo(repoName);
    setDownloadInfo(null);
    setCurlCommand('');
    authorizeDownloadAsync({ repo: repoName, days: 365 });
  }

  function handleRepoChange(event: SelectChangeEvent) {
    changeRepo(event.target.value);
  }

  if (isLoading) {
    return (
      <Box sx={{ my: 2 }}>
        <InlineLoadingIndicator title="Fetching tools..." />
      </Box>
    );
  }

  if (repoListError) {
    return (
      <Box sx={{ my: 2 }}>
        <Typography color="red">
          Failed to load tools available for download.
        </Typography>
      </Box>
    );
  }

  if (!repo && repoData?.repos?.includes('toolkit-cli')) {
    changeRepo('toolkit-cli');
  }

  return (
    <Box sx={{ my: 2 }} data-testid="download-authorizer">
      <Grid container spacing={2} alignItems="center">
        <Grid size={{ md: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="tool-select-label">Tool</InputLabel>
            <Select
              labelId="tool-select-label"
              id="tool-select"
              value={repo}
              label="Tool"
              onChange={handleRepoChange}
              disabled={isLoading || isAuthorizingDownload}
            >
              {repoData?.repos?.map((r) => (
                <MenuItem key={r} value={r}>
                  {r}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid size={{ md: 7 }}>
          <Stack spacing={2} direction="row" alignItems="center">
            <PasswordField
              value={downloadInfo?.downloadUrl || ''}
              message={'Copied URL'}
              dataTestId={'download-url-text'}
              label={'Download URL'}
              isLoading={isAuthorizingDownload}
            />
            <Button
              id="download-package-button"
              title="Download package"
              onClick={downloadPackage}
              disabled={!downloadInfo}
              sx={{ minWidth: 45, minHeight: 45, maxHeight: 45, maxWidth: 45 }}
            >
              <DownloadOutlinedIcon />
            </Button>
          </Stack>
        </Grid>

        <Grid size={{ md: 1 }}>
          {downloadInfo?.version && (
            <Stack>
              <Typography variant="body2">Version:</Typography>
              <Typography variant="body2">{downloadInfo.version}</Typography>
            </Stack>
          )}
        </Grid>

        <Grid size={{ md: 2 }}>
          {downloadInfo?.entitlementExpiration && (
            <Stack>
              <Typography variant="body2">Entitlement Expiration:</Typography>
              <Typography variant="body2">
                {new Date(downloadInfo.entitlementExpiration).toLocaleString()}
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid size={{ md: 2 }}></Grid>
        <Grid size={{ md: 7 }}>
          <PasswordField
            value={curlCommand}
            message={'Copied curl command'}
            dataTestId={'curl-command-text'}
            label={'Curl Command'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
