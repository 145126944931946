import { Box } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';
import { Highlight, Language, themes } from 'prism-react-renderer';
import * as React from 'react';

type Token = {
  types: string[];
  content: string;
  empty?: boolean;
};

interface SyntaxHighlighterProp {
  code: string;
  formatCode?: boolean;
  language?: Language;
  searchText?: string;
  sx?: React.CSSProperties;
}

const SyntaxHighlighter: React.FC<SyntaxHighlighterProp> = ({
  code,
  formatCode = true,
  language = 'sql',
  searchText = null,
  sx,
}: SyntaxHighlighterProp) => {
  function highlightSearchText(
    key: number,
    token: Token,
    getTokenProps,
    highlight: string,
    fullText: string
  ): React.ReactNode {
    const content = token.content;
    if (searchText && searchText.length) {
      const indices: number[] = getOccurrenceIndices(fullText);
      const contentIndex: number = highlight.lastIndexOf(content.trim());
      const searchLen: number = searchText.length;

      /* Find index of token content present in search value, if not preset then value will be undefined  */
      const doHighlight: number = indices.find(
        (ind) => ind <= contentIndex && ind + searchLen > contentIndex
      );
      const lastWord: string = getLastWordFromString(searchText);

      /* Check whether token content available in search value Or lastWord present in token content */
      const proceedToHighlight: boolean =
        searchText.includes(content.trim()) || content.includes(lastWord);

      if (
        proceedToHighlight &&
        fullText.includes(searchText) &&
        doHighlight !== undefined
      ) {
        /* Check token content and lastWord is not empty, lastWord is available in content and both are not equal */
        if (
          content.length &&
          lastWord.length &&
          content.includes(lastWord) &&
          content.trim() !== lastWord
        ) {
          /* Find lastWord position in token content, to split in part into highlight and skip highlight */
          const lastWordPos: number =
            content?.indexOf(lastWord) + lastWord.length;
          return (
            <Box component="span">
              <Box
                component="span"
                sx={{
                  backgroundColor: BrandColors.Yellow,
                  color: BrandColors.Primary,
                }}
              >
                {content.substr(0, lastWordPos)}
              </Box>
              <Box component="span">{content.substr(lastWordPos)}</Box>
            </Box>
          );
        } else {
          return (
            <Box
              key={key}
              component="span"
              sx={{
                backgroundColor: BrandColors.Yellow,
                color: BrandColors.Primary,
              }}
            >
              {token.content}
            </Box>
          );
        }
      } else {
        return (
          <Box key={key} component="span" {...getTokenProps({ token, key })} />
        );
      }
    } else {
      return (
        <Box key={key} component="span" {...getTokenProps({ token, key })} />
      );
    }
  }

  /* Return number of search value indices as number array */
  function getOccurrenceIndices(str: string): number[] {
    const regex = new RegExp(escapeRegExp(searchText), 'gi');
    const indices = [];
    let result;
    while ((result = regex.exec(str))) {
      indices.push(result.index);
    }
    return indices;
  }

  /* Escape all special characters by forward slash(\) in string */
  function escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  /* Take last word from larger string */
  function getLastWordFromString(str: string): string {
    const word = str.substring(str.lastIndexOf(' ') + 1);
    const arr = word.split(/[\.\{\}\(\)]/);
    return arr[arr.length - 1];
  }

  /* Join all token content into string */
  function findFullCodeText(tokens: Token[][]): string {
    const arr = [];
    tokens.forEach((line: Token[]) => {
      line.forEach((token: Token) => arr.push(token.content));
    });
    return arrToString(arr);
  }

  /* Convert array values into string by replacing extra spaces */
  function arrToString(hArr: string[]): string {
    return hArr.join('').replace(/\s\s+/g, ' ');
  }

  return (
    <Highlight theme={themes.github} code={code} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        const hArr = [];
        const fullText = findFullCodeText(tokens);
        return (
          <pre
            className={className}
            style={{
              ...{
                textAlign: 'left',
                margin: '1em 0',
                padding: '0.5em',
                overflow: 'scroll',
                '& .token-line': {
                  lineHeight: '1.3em',
                  height: '1.3em',
                },
              },
              ...style,
              ...sx,
            }}
          >
            {tokens.map((line: Token[], index: number) => {
              return (
                <Box key={index} {...getLineProps({ line, key: index })}>
                  {line.map((token: Token, key: number) => {
                    hArr.push(token.content);
                    return highlightSearchText(
                      key,
                      token,
                      getTokenProps,
                      arrToString(hArr),
                      fullText
                    );
                  })}
                </Box>
              );
            })}
          </pre>
        );
      }}
    </Highlight>
  );
};

export default SyntaxHighlighter;
