import { axiosInstance as axios } from 'Common/common-ui';
import { SQLMORPH_API_URL } from 'Common/config';
import { DemoQuery, ResponseWithStatus } from '../constants/interfaces';

export async function sqlConverter(data: any): Promise<ResponseWithStatus> {
  const response = await axios.post(`${SQLMORPH_API_URL}/v2/convert`, data);
  return { status: response.status, response: response.data };
}

export async function getSourcesTargets(): Promise<string> {
  const response = await axios.get(
    `${SQLMORPH_API_URL}/v1/sourcetargetmapping`
  );
  return response.data;
}

export async function getDemoQueries(): Promise<{ demoQueries: DemoQuery[] }> {
  const response = await axios.get(`${SQLMORPH_API_URL}/v1/demo`);
  return response.data;
}

export async function demoQueryConverter(
  data: any
): Promise<ResponseWithStatus> {
  const response = await axios.post(
    `${SQLMORPH_API_URL}/v1/demo/convert`,
    data
  );
  return { status: response.status, response: response.data };
}
