export enum FeedbackType {
  BugOrIssue = 'bug/issue',
  Question = 'question',
  Feedback = 'feedback',
}

export enum UpdateTagType {
  ANNOUNCEMENTS = 'Announcements',
  FEATURES = 'Features',
  DEPRECATIONS = 'Deprecations',
  CHANGES = 'Changes',
  FIXES = 'Fixes',
  ISSUES = 'Issues',
  SECURITY = 'Security',
}

export enum AppNameList {
  SQL_TRANSLATION = 'SQL Translation',
  STREAMLINER = 'Streamliner',
  DATA_SOURCE = 'Data Source Tool',
  TRAM = 'Project Administration - Tram',
  ANALYTICS_LIBRARY = 'Analytics Library',
  TOOLKIT_CLI = 'Toolkit CLI',
}

export enum AnalyticsTechnology {
  TABLEAU = 'Tableau',
  POWER_BI = 'Power BI',
  SIGMA = 'Sigma',
}

export enum LicenseType {
  ALL = 'ALL',
  PRO = 'PRO',
  FREE = 'FREE',
}

export enum CopyText {
  CopyToClipBoard = 'Copy to clipboard',
  Copied = 'Copied!',
}
