import { Container } from '@mui/material';
import { AnalyticsLibraryProps } from 'Common/interfaces';
import React from 'react';

interface SigmaIProps {
  dashboard: AnalyticsLibraryProps;
}

const Sigma: React.FC<SigmaIProps> = (props: SigmaIProps) => {
  const sigmaURL = 'https://app.sigmacomputing.com/embed' as string;
  return (
    <Container data-testid="sigma-dashboard">
      <iframe
        title="power_bi_vizualization"
        src={`${sigmaURL}/${props.dashboard.vizUrl}`}
        height="1000"
        width="85%"
      />
    </Container>
  );
};

export default Sigma;
