import { AxiosRequestConfig } from 'axios';
import { axiosInstance as axios } from 'Common/common-ui';
import { TOOLKIT_API_URL } from 'Common/config';
import {
  AnalyticsLibraryProps,
  FeatureFlagsResponse,
  SupportFeedbackForm,
  UserProps,
} from 'Common/interfaces';

const getRequest = async (
  url: string,
  config?: AxiosRequestConfig | undefined
) => {
  try {
    const response = await axios.get(`${TOOLKIT_API_URL}${url}`, config);
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const postRequest = async (url: string, payload: any) => {
  try {
    const response = await axios.post(`${TOOLKIT_API_URL}${url}`, payload);
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const putRequest = async (url: string, payload: any = null) => {
  try {
    const response = await axios.put(`${TOOLKIT_API_URL}${url}`, payload);
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const basePath = '/v1';

export const supportFeedback = (formContents: SupportFeedbackForm) =>
  postRequest(`${basePath}/support`, formContents);

export const getUserInfo = (): Promise<UserProps> =>
  getRequest(`${basePath}/user`);

export const getFeatureFlags = (): Promise<FeatureFlagsResponse> =>
  getRequest(`${basePath}/features`);

export const getDashboards = (): Promise<{
  dashboards: AnalyticsLibraryProps[];
}> => getRequest(`${basePath}/dashboards`);

export const getAsciidoc = async (path: string): Promise<any> => {
  try {
    const response = await axios.get(`/toolkit${path}`);
    return response.data;
  } catch (err) {
    return { status: err.response?.status, data: err.response?.data };
  }
};

export const getLicense = (): Promise<{
  licenseType: string;
}> => {
  return getRequest(`${basePath}/auth/license`);
};

export const listRepos = (): Promise<{ repos: Array<string> }> => {
  return getRequest(`${basePath}/repo`);
};

export const generateAuthToken = (
  days: number
): Promise<{ jws: string; expiration: string }> =>
  postRequest(`${basePath}/auth/token`, { days: days });

export interface DownloadInfo {
  name: string;
  downloadUrl: string;
  fileName: string;
  version: string;
  entitlementToken: string;
  entitlementExpiration: string;
}

export const authorizeDownload = (
  repo: string,
  days: number
): Promise<DownloadInfo> =>
  putRequest(`${basePath}/repo/grant`, { repo: repo, days: days });

export const logFrontendError = (e: Error): Promise<void> =>
  postRequest(`${basePath}/v1/log-frontend-error`, {
    level: 'error',
    message: e.message,
    trace: e.stack,
  });

export const getProvisionDemoSQLStatements = async (
  group
): Promise<{ statements: string; group: string; template: string }> => {
  const response = await postRequest(`${basePath}/provision/demo/sql`, group);
  if (response.statements) {
    return response;
  } else {
    throw new Error('404');
  }
};
