const prefix = 'sqlmorph.';
const lastTargetPath = `${prefix}lastTarget`;
const lastSourcePath = `${prefix}lastSource`;
const sourceTargetPath = `${prefix}sourceTargetList`;

export const localStorageService = {
  set sourceTargetList(list) {
    localStorage.setItem(sourceTargetPath, JSON.stringify(list));
  },
  get sourceTargetList() {
    return this.safeJsonParse(localStorage.getItem(sourceTargetPath));
  },
  set target(target) {
    localStorage.setItem(lastTargetPath, JSON.stringify(target));
  },
  get target() {
    return this.safeJsonParse(localStorage.getItem(lastTargetPath));
  },
  set source(source) {
    localStorage.setItem(lastSourcePath, JSON.stringify(source));
  },
  get source() {
    return this.safeJsonParse(localStorage.getItem(lastSourcePath));
  },

  safeJsonParse(string: string | null) {
    try {
      if (string) {
        return JSON.parse(string);
      }
    } catch {
      return null;
    }
  },
};
