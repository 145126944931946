import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import React, { lazy } from 'react';
import { Location, useLocation } from 'react-router-dom';
import SQLMorphRouting from './scripts/SQLMorphRouting';
import { SqlMorphContext, useSqlMorphReducer } from './scripts/app/reducer';
import * as Paths from './scripts/constants/paths';

const About = lazy(() => import('./scripts/components/about/About'));

const SQLMorph: React.FC = () => {
  const location = useLocation() as Location;
  const { isAuthenticated } = useAuth0();
  const [state, dispatch] = useSqlMorphReducer();
  const isDemoMode = location.pathname.includes(`/${Paths.demo}/`);

  if (!isAuthenticated && !isDemoMode) {
    return <About />;
  }

  return (
    <SqlMorphContext.Provider value={{ state, dispatch }}>
      <Container
        id="sql-morph-container"
        maxWidth={false}
        sx={{
          height: 'calc(100vh - 70px)',
          px: '0px !important',
        }}
      >
        <SQLMorphRouting isDemoMode={isDemoMode} />
      </Container>
    </SqlMorphContext.Provider>
  );
};

export default SQLMorph;
