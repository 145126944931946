import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

interface Props {
  content: React.ReactNode;
  tooltipProps: any;
  iconSx?: SxProps<Theme>;
}

export default function HelpIconPopover(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { content, tooltipProps } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Tooltip {...tooltipProps}>
        <IconButton
          sx={{ ...props.iconSx }}
          aria-describedby={id}
          onClick={handleClick}
        >
          <HelpOutlineIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>{content}</Box>
      </Popover>
    </>
  );
}
