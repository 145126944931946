import { User } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';

declare global {
  interface Window {
    analytics?: any;
  }
}

let analyticsLoaded = false;

// Load the Segment with the correct write key based on the environment.
// Only runs the first time it is called.
export const loadAnalytics = () => {
  // skip loading analytics if they have already been loaded or if this is a datadog synthetic test
  if (analyticsLoaded || window['_DATADOG_SYNTHETICS_BROWSER'] !== undefined) {
    return;
  }

  if (window.location.hostname === 'toolkit.phdata.io') {
    window?.analytics?.load('1RGeJF20jFS9ovvSA6Qo5pPuR7HDO1Zw');
  } else {
    window?.analytics?.load('cAFeCxUpx92f3bXiKvPfey9IeD1d3kEY');
  }
  analyticsLoaded = true;
};

export const getAnonymousId = (): string => {
  let anonymousId = '';
  loadAnalytics();
  // Make sure we only call user().anonymousId() if it exists (is loaded).
  if (typeof window?.analytics?.user === 'function') {
    anonymousId = window?.analytics?.user().anonymousId();
  }
  return anonymousId;
};

// Listen and notify Segment of client-side page updates.
export const handlePageAnalytics = () => {
  loadAnalytics();
  const location: Location = useLocation();
  useEffect(() => {
    window?.analytics?.page(location.pathname);
  }, [location]);
};

export const identifyUser = (userDetails: User) => {
  loadAnalytics();
  window?.analytics?.identify(userDetails.email, {
    name: userDetails.name,
    email: userDetails.email,
  });
};

export const track = (name: string, properties: any) => {
  loadAnalytics();
  window?.analytics?.track(name, properties);
};
