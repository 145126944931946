import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Component, ErrorInfo } from 'react';

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (e: Error) => void;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  public render() {
    if (this.state.errorInfo) {
      return (
        <Box
          id="error-boundary-box"
          display={'flex'}
          align-items={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          m={2}
        >
          <Box display={'flex'} alignItems="center">
            <InfoIcon />
            <Typography
              ml={1}
              mt={1}
              textAlign="center"
              variant="h5"
              gutterBottom
            >
              An error occurred
            </Typography>
          </Box>
          <Box p={2}>
            <details>
              <summary>Details</summary>
              {this.state.error && this.state.error.message}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </Box>
        </Box>
      );
    }
    return this.props.children;
  }
}
