import { RedirectLoginOptions, useAuth0, User } from '@auth0/auth0-react';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import { BrandColors } from 'Common/common-ui/common/theme';
import { getRgba } from 'Common/util';
import * as React from 'react';
import { Location, useLocation } from 'react-router-dom';

const LightBlueButton = styled(Button)({
  backgroundColor: BrandColors.Secondary,
  color: BrandColors.White,
  boxShadow: 'none',
  border: 'none',
  '&:hover': {
    backgroundColor: getRgba(BrandColors.Secondary, 0.6),
    border: 'none',
  },
});

export default function UserMenu(props: {
  userInfo: User;
}): React.ReactElement {
  const { logout, loginWithRedirect } = useAuth0();
  const location: Location = useLocation();

  const signOut = (e: React.MouseEvent): void => {
    e.preventDefault();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const signIn = async (e: React.MouseEvent) => {
    e.preventDefault();
    const options: RedirectLoginOptions = {
      appState: { returnTo: location.pathname },
    };
    await loginWithRedirect(options);
  };

  if (!props.userInfo) {
    return (
      <LightBlueButton
        data-testid="sign-in-btn"
        sx={{ '&.MuiButton-root': { height: 36, m: 'auto', ml: 2 } }}
        onClick={signIn}
      >
        Sign In
      </LightBlueButton>
    );
  }

  return (
    <LightBlueButton
      data-testid="sign-out-btn"
      size="small"
      sx={{ '&.MuiButton-root': { height: 36, m: 'auto', ml: 2 } }}
      onClick={signOut}
    >
      Sign Out
    </LightBlueButton>
  );
}
