import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BrandColors } from 'Common/common-ui/common/theme';
import { domToReact } from 'html-react-parser';

export default function OverrideToolStyles(domNode, options, ref): JSX.Element {
  const nodeName = domNode.name;

  if (nodeName === 'div' && domNode?.attribs?.id === 'toc') {
    return <></>;
  }
  if (nodeName === 'body') {
    return (
      <Box
        sx={{
          '& #content': { ml: 0, mt: 0, pl: 0 },
        }}
        mb={5}
      >
        {domToReact(domNode.children, options)}
      </Box>
    );
  }
  if (
    nodeName === 'div' &&
    (domNode?.attribs?.id === 'header' || domNode?.attribs?.id === 'preamble')
  ) {
    return <></>;
  }
  if (nodeName === 'div' && domNode?.attribs?.class === 'ulist') {
    if (
      domNode.childNodes[1].childNodes[1].childNodes[1].childNodes[0].name ===
      'strong'
    ) {
      return (
        <Box
          sx={{
            pl: 4,
            my: 2,
            borderRadius: '4px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              backgroundColor: BrandColors.LightGray,
              width: '10px !important',
              borderRadius: '20px',
              display: 'inline-block',
            }}
          >
            {' '}
          </Box>
          <Box
            sx={{
              pt: 5,
              pb: 3,
              pl: 3,
              width: '95%',
              display: 'inline-block',
              'li::before': {
                content: '" "',
                width: '25px',
                height: '25px',
                backgroundColor: BrandColors.Teal,
                borderRadius: '100%',
                display: 'inline-block !important',
                position: 'absolute',
                left: '23.5px',
              },
              '& li': {
                listStyleType: 'none',
              },
            }}
          >
            {domToReact(domNode.children[1].children, options)}
          </Box>
        </Box>
      );
    }
  }
  if (
    nodeName === 'h1' ||
    nodeName === 'h2' ||
    nodeName === 'h3' ||
    nodeName === 'h4' ||
    nodeName === 'h5' ||
    nodeName === 'h6'
  ) {
    return (
      <Typography
        id={domNode.attribs.id}
        variant={nodeName}
        color={BrandColors.Primary}
        fontWeight="500"
        ref={(htmlRef: HTMLHeadingElement) => {
          ref[domNode.attribs.id] = htmlRef;
        }}
      >
        {domToReact(domNode.children)}
      </Typography>
    );
  }
}
