import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import { TealTab, TealTabs } from 'Common/components/TealTabs';
import type { Location } from 'history';
import { capitalize } from 'lodash';
import React from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { HeaderNavItem, HeaderProps } from '../common/interfaces';
import './header.scss';

export const Header = (props: HeaderProps) => {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const [value, setValue] = React.useState(0);
  const [navOptions, setNavOptions] = React.useState<HeaderNavItem[]>([]);
  React.useEffect(() => {
    const pathName: string = location?.pathname || '';
    const pathList: string[] = pathName.split('/');
    setNavOptions([...props.navOptions]);
    if (pathList.length > 3) {
      const tabName: string = pathList.pop();
      let indNum = [...props.navOptions].findIndex((v: HeaderNavItem) =>
        pathName.includes(v.to)
      );

      if (indNum === -1) {
        setNavOptions([
          ...props.navOptions,
          { icon: '', title: capitalize(tabName), to: pathName },
        ]);
        indNum = navOptions.length;
      } else {
        if (indNum !== props.navOptions.length) {
          setNavOptions(props.navOptions);
        }
      }

      setValue(indNum);
    }
  }, [value, location?.pathname, props.navOptions]);

  const renderNavItems = (options: HeaderNavItem[]) => {
    const menuItems: HeaderNavItem[] = [...options];

    return menuItems.map((x: HeaderNavItem) => {
      if (!x.to) {
        console.log('`to` must be defined to render nav item');
        return null;
      }
      const path = x.to || '/';

      return (
        <TealTab key={x.title} label={x.title} onClick={() => navigate(path)} />
      );
    });
  };

  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <AppBar position="relative" elevation={0}>
      <Paper className="header-paper">
        <TealTabs
          value={value}
          onChange={handleChange}
          sx={{ position: 'relative' }}
        >
          {renderNavItems(navOptions)}
        </TealTabs>
      </Paper>
    </AppBar>
  );
};

export default Header;
