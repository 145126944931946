import { Radio, styled } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';

const TealRadio = styled(Radio)({
  color: BrandColors.Teal,
  '&.Mui-checked': {
    color: BrandColors.Teal,
  },
});

export default TealRadio;
