import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Footer } from '../footer';
import { Header } from '../header';

export interface AuthErrorProps {
  reason: string;
  message: string;
  logout: (e: React.MouseEvent) => void;
}

export const AuthError = ({
  reason,
  message,
  logout,
}: AuthErrorProps): JSX.Element => {
  return (
    <>
      <Header logoutOnClick={logout} navOptions={[]} />
      <Box display={'flex'} justifyContent={'center'} textAlign={'center'}>
        <Typography variant="h5">Failed to authenticate: {reason}</Typography>
        <Box>{message}</Box>
      </Box>
      <Footer />
    </>
  );
};
