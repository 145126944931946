import * as React from 'react';
import Box from '@mui/material/Box';
import ReleaseNoteWrapper from './ReleaseNoteWrapper';

const Updates: React.FC = () => {
  return (
    <Box
      height={'calc(100vh - 55px)'}
      overflow="auto"
      position={'relative'}
      sx={{
        width: '100% !important',
        '& #header': {
          m: 0,
          p: 0,
          pb: 1,
          maxWidth: '100%',
          zIndex: 1,
        },
        '& #content': {
          ml: '1px !important',
          p: 0,
          zIndex: 1,
        },
      }}
    >
      <ReleaseNoteWrapper />
    </Box>
  );
};

export default Updates;
